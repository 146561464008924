import { useCallback, useMemo, useState } from 'react'

import { Chip } from '@mui/material'
import {
  IconCircleFilled,
  IconCylinder,
  IconStack2,
  IconPlus,
} from '@tabler/icons-react'
import type { CRMObject } from 'types/graphql'

import ObjectChipRow from 'src/components/Chips/ObjectChipRow/ObjectChipRow'
import PageContextDebug from 'src/components/Pages/PageContextDebug/PageContextDebug'
import Row from 'src/components/Row/Row'
import SearchModal from 'src/components/Search/SearchModal/SearchModal'
import { actionButtonStyle } from 'src/components/Sidebar/styles'
import { logger } from 'src/lib/logger'
import { ObjectTypeMetadata, type NativeObjectType } from 'src/lib/objects'
import type { SearchableObject } from 'src/lib/searchService'

import ThreadContextDebug from '../ThreadContextDebug/ThreadContextDebug'
import { contextStatusMetadata } from '../threads'

interface ObjectContextManagerProps {
  variant?: 'new' | 'existing'
  contextObjects: Partial<CRMObject>[]
  onUpdateContextObjects: (objects: Partial<CRMObject>[]) => void
  showStatus?: boolean
  buttonLabel?: string
  buttonFullWidth?: boolean
  contextStatus?: keyof typeof contextStatusMetadata
  showDebug?: boolean
  workspaceId?: string
  contextForObjectType?: NativeObjectType
  debugComponent?: 'thread' | 'page'
}

const ObjectContextManager = ({
  variant = 'existing',
  contextObjects,
  onUpdateContextObjects,
  showStatus = true,
  buttonLabel = 'CRM records',
  buttonFullWidth = false,
  contextStatus,
  showDebug = false,
  workspaceId,
  contextForObjectType = null,
  debugComponent = 'thread',
}: ObjectContextManagerProps) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [isDebugOpen, setIsDebugOpen] = useState(false)

  const handleAddContext = useCallback(
    (result: SearchableObject) => {
      logger.dev('Adding context:', {
        result,
        existingContextObjects: contextObjects,
        isContact: result.objectType === 'native_contact',
      })
      // For contacts, double check we're using email as objectId
      const objectId =
        result.objectType === 'native_contact'
          ? result.properties?.email || result.objectId
          : result.objectId

      onUpdateContextObjects([
        ...contextObjects.filter((o) => o.objectId !== objectId),
        { objectId, objectType: result.objectType },
      ])
      setIsSearchOpen(false)
    },
    [contextObjects, onUpdateContextObjects]
  )

  const handleRemoveContext = useCallback(
    (object: CRMObject) => {
      onUpdateContextObjects(
        contextObjects.filter((o) => o.objectId !== object.objectId)
      )
    },
    [contextObjects, onUpdateContextObjects]
  )

  const status = contextStatus ? contextStatusMetadata[contextStatus] : null

  const buttonSx = useMemo(() => {
    return !buttonFullWidth
      ? { ...actionButtonStyle, height: '24px', py: 0 }
      : {
          ...actionButtonStyle,
          height: '48px',
          py: 0,
          fontSize: '16px',
          letterSpacing: '-0.4px',
          width: '100%',
        }
  }, [buttonFullWidth])

  const containerSx = useMemo(() => {
    return {
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      width: '100%',
    }
  }, [])

  return (
    <>
      <Row
        sx={containerSx}
        gap={1}
      >
        <ObjectChipRow
          fullWidth={variant === 'new' || buttonFullWidth ? true : false}
          workspaceId={workspaceId}
          objects={contextObjects}
          onRemove={handleRemoveContext}
          showSidebar={true}
          action={
            <Chip
              variant="outlined"
              size="small"
              onClick={() => setIsSearchOpen(true)}
              sx={{ ...actionButtonStyle, ...buttonSx }}
              icon={<IconCylinder size={16} />}
              label={buttonLabel}
            />
          }
        />
        {showStatus && status && (
          <Row
            sx={{ justifyContent: 'flex-end' }}
            gap={1}
          >
            <Chip
              label={status.label}
              size="small"
              variant="outlined"
              icon={<IconCircleFilled size={8} />}
              sx={{
                border: 'none',
                '& .MuiChip-icon': {
                  color: (theme) => theme.palette[status.color].main,
                  flexShrink: 0,
                  height: '8px',
                  width: '8px',
                  mr: '-2px',
                },
              }}
              onClick={() => showDebug && setIsDebugOpen(true)}
            />
          </Row>
        )}

        {showDebug && debugComponent === 'thread' && (
          <ThreadContextDebug
            open={isDebugOpen}
            setOpen={setIsDebugOpen}
          />
        )}

        {showDebug && debugComponent === 'page' && (
          <PageContextDebug
            open={isDebugOpen}
            setOpen={setIsDebugOpen}
          />
        )}
      </Row>

      <SearchModal
        open={isSearchOpen}
        onClose={() => setIsSearchOpen(false)}
        onSelect={handleAddContext}
        contextualActions={[
          {
            label: contextForObjectType
              ? `Add to ${ObjectTypeMetadata[contextForObjectType].label}`
              : 'Add to Context',
            icon: contextForObjectType ? (
              React.createElement(
                ObjectTypeMetadata[contextForObjectType].icon,
                {
                  size: 16,
                }
              )
            ) : variant === 'new' ? (
              <IconStack2 size={16} />
            ) : (
              <IconPlus size={16} />
            ),
            onClick: (result) => handleAddContext(result),
          },
        ]}
      />
    </>
  )
}

export default ObjectContextManager
