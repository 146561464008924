import { useRef, useState } from 'react'

import { useMutation } from '@apollo/client'
import type { SxProps } from '@mui/material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Menu,
  Typography,
  useTheme,
} from '@mui/material'
import {
  RiArrowDownSLine,
  RiBardFill,
  RiBardLine,
  RiCalendarLine,
  RiMailLine,
  RiPoliceBadgeLine,
  RiTimeLine,
} from '@remixicon/react'
import toast from 'react-hot-toast'

import { navigate, routes } from '@redwoodjs/router'

import { dayjs } from 'src/lib/dayjs'

import MeetingRecordingCalendarSettings from '../MeetingRecordingCalendarSettings/MeetingRecordingCalendarSettings'
import Row from '../Row/Row'

const MODEL_OPPORTUNITY_FROM_CARD = gql`
  mutation ModelOpportunityFromCard(
    $opportunityId: String!
    $workspaceId: String!
  ) {
    modelOpportunityAsync(
      opportunityId: $opportunityId
      workspaceId: $workspaceId
    )
  }
`

const MODEL_ORGANIZATION_FROM_CARD = gql`
  mutation ModelOrganizationFromCard($workspaceId: String!, $domain: String!) {
    organizationModel(workspaceId: $workspaceId, domain: $domain)
  }
`

interface AiFeatureProps {
  featureKey: string
  objectId: string
  workspaceId: string
  size?: number
  variant?: 'filled' | 'outlined'
  lastUpdated?: Date
  sx?: SxProps
}

// const environmentExplanations = {
//   'aws-vpc': 'Virtual private cloud (VPC)',
//   openai: 'Private API',
// }

const standardNoTrainingDisclaimerInternal =
  'Day.ai does not use any of this information for training or fine-tuning proprietary models.'

const standardNoTrainingDisclaimerExternal =
  'No third-party model providers are allowed to use your data for training.'

const AiFeatureDetails = ({ header, text, action = null }) => {
  return (
    <>
      <Typography
        sx={{
          fontWeight: '600',
          fontSize: '14px',
          letterSpacing: '-0.3px',
          mb: 1,
        }}
      >
        {header}
      </Typography>
      <Typography
        variant="body1"
        sx={{ mb: 3 }}
      >
        {text}
      </Typography>
      {action && <>{action}</>}
    </>
  )
}

const AiFeature = ({
  featureKey,
  objectId,
  workspaceId,
  size = 24,
  variant = 'filled',
  lastUpdated,
  sx,
}: AiFeatureProps) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null)
  const buttonRef = useRef(null)

  const [modelOpportunity] = useMutation(MODEL_OPPORTUNITY_FROM_CARD)
  const [organizationModel] = useMutation(MODEL_ORGANIZATION_FROM_CARD)

  const handleRunModel = () => {
    toast.promise(features[featureKey].run(), {
      loading: 'Running feature...',
      success: 'Feature run successfully',
      error: 'Error running feature',
    })
  }

  const features = {
    opportunities: {
      name: 'Opportunities',
      context: `Day.ai's Opportunities feature draws from Workspace-shared communications with the people identified through the Roles on the Opportunity object, as well as the Organizations involved in the Opportunity. Email history, meeting transcripts & notes, Slack conversations and related metadata give this feature what it needs to help automate the Pipeline process.`,
      output:
        'Opportunities creates much of the content that you see on the Opportunity itself, as well as serving its secondary purpose of identifying and updated Suggested Opportunities. This feature will identify the Roles on the Opportunity only in the Suggested state, leaving you full control on role management once the Opportunity is on a Pipeline. The feature serves to update all of the summary information (business and relationship context).',
      usedForTraining: {
        internal: false,
        external: false,
      },
      outputVisibility: 'WORKSPACE',
      env: 'aws-vpc',
      run: () =>
        modelOpportunity({
          variables: { opportunityId: objectId, workspaceId: workspaceId },
        }),
    },
    organizations: {
      name: 'Organizations',
      context: `Day.ai's Organizations feature draws all Gmail, Slack, and Meeting data that is shared with this Day.ai Workspace.`,
      output:
        'Organizations creates much of the content that you see on the Organization itself, including identifying the relationship between your Day.ai Workspace and the Organization. Organizations also looks for Sales and other business Opportunities and creates them, adding them to existing pipelines or leaving them in Suggested Pipelines for when you are ready to start working them.',
      usedForTraining: {
        internal: false,
        external: false,
      },
      outputVisibility: 'WORKSPACE',
      env: 'aws-vpc',
      run: () =>
        organizationModel({
          variables: { workspaceId: workspaceId, domain: objectId },
        }),
    },
  }

  return (
    <>
      <IconButton
        onClick={() => setAnchorEl(buttonRef.current)}
        ref={buttonRef}
        sx={{
          p: '4px',
          borderRadius: '3px',
          ...sx,
        }}
      >
        {variant === 'filled' ? (
          <RiBardFill size={size} />
        ) : (
          <RiBardLine size={size} />
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        sx={{ p: 0, '& .MuiList-root': { p: 0 }, background: 'transparent' }}
        slotProps={{
          paper: {
            style: {
              width: 500,
              background: theme.palette.background.default,
            },
          },
          root: {
            style: {
              background: 'transparent',
            },
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            mt: '0px',
            '&::before': {
              backgroundColor: 'white',
              border: '1px solid red',
              content: '""',
              display: 'none', // show arrow, needs work
              position: 'absolute',
              width: 24,
              height: 24,
              top: -24,
              transform: 'rotate(45deg)',
              left: 'calc(50% - 6px)',
            },
          }}
        />
        <Row
          sx={{
            justifyContent: 'space-between',
            width: '100%',
            height: '48px',
            background: (theme) => theme.palette.background.default,
            px: 2,
            py: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '400%',
              background: (theme) => theme.palette.background.default,
            }}
          >
            Feature information
          </Typography>
          <Typography
            sx={{
              fontWeight: '500',
              fontSize: '14px',
              fontStyle: 'italic',
              background: (theme) => theme.palette.background.default,
              lineHeight: '400%',
              color: (theme) => theme.palette.text.secondary,
            }}
          >
            {features[featureKey].name}
          </Typography>
        </Row>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<RiArrowDownSLine size={24} />}>
            <Row sx={{ justifyContent: 'space-between', width: '100%' }}>
              <Row gap={2}>
                <Box>
                  <RiBardFill size={18} />
                </Box>
                <Typography sx={{ fontWeight: '600', fontSize: '14px' }}>
                  About
                </Typography>
              </Row>
            </Row>
          </AccordionSummary>
          <AccordionDetails>
            <AiFeatureDetails
              header="Context"
              text={features[featureKey].context}
            />
            <AiFeatureDetails
              header="Output"
              text={features[featureKey].output}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<RiArrowDownSLine size={18} />}>
            <Row gap={2}>
              <Box>
                <RiPoliceBadgeLine size={16} />
              </Box>
              <Typography sx={{ fontWeight: '500', fontSize: '13px' }}>
                Privacy
              </Typography>
            </Row>
          </AccordionSummary>
          <AccordionDetails>
            <AiFeatureDetails
              header={`Data is ${
                !features[featureKey].usedForTraining.internal ? 'not ' : ''
              }used for training by Day.ai.`}
              text={standardNoTrainingDisclaimerInternal}
            />
            <AiFeatureDetails
              header={`Data is ${
                !features[featureKey].usedForTraining.external ? 'not ' : ''
              }used for training by third-party model providers.`}
              text={standardNoTrainingDisclaimerExternal}
            />
            <AiFeatureDetails
              header="Manage Data Sharing with Day.ai"
              text={
                'You can manage what data is shared with Day.ai via the Meeting Recording settings and Email Sharing settings.'
              }
              action={
                <Row
                  gap={1}
                  sx={{ width: '100%' }}
                >
                  <Box sx={{ width: '50%' }}>
                    <Button
                      size="small"
                      fullWidth={true}
                      onClick={() => {
                        navigate(routes.workspaceSharing())
                      }}
                      variant="outlined"
                      startIcon={<RiMailLine size={16} />}
                    >
                      Email sharing rules
                    </Button>
                  </Box>
                  <Box sx={{ width: '50%' }}>
                    <MeetingRecordingCalendarSettings
                      refetch={() => {}}
                      component={
                        <Button
                          size="small"
                          fullWidth={true}
                          variant="outlined"
                          startIcon={<RiCalendarLine size={16} />}
                          sx={{ flexShrink: 0, width: '100%' }}
                        >
                          Recording defaults
                        </Button>
                      }
                    />
                  </Box>
                </Row>
              }
            />
          </AccordionDetails>
        </Accordion>
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<RiArrowDownSLine size={18} />}>
            <Row gap={2}>
              <Box>
                <RiTimeLine size={16} />
              </Box>
              <Typography sx={{ fontWeight: '500', fontSize: '13px' }}>
                {lastUpdated
                  ? `Last automated execution ${dayjs(lastUpdated).fromNow()}`
                  : 'The feature has not run yet on this.'}
              </Typography>
            </Row>
          </AccordionSummary>
          <AccordionDetails>
            <Button
              fullWidth={true}
              variant="outlined"
              onClick={handleRunModel}
            >
              Run AI feature now
            </Button>
          </AccordionDetails>
        </Accordion>
      </Menu>
    </>
  )
}

export default AiFeature
