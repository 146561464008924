import {
  ObjectTypeMetadata,
  type NativeObjectType,
  NativeObjectTypes,
} from './objects'

export const LABEL_PROPERTIES = [
  'label',
  'name',
  'fullName',
  'displayName',
  'title',
] as const
export const AVATAR_PROPERTIES = [
  'avatarUrl',
  'photoUrl',
  'photos.square',
] as const
export const DESCRIPTION_PROPERTIES = [
  'description',
  'about.aiDescription',
] as const

export const getFirstDefinedValue = (
  obj: Record<string, any> | null,
  properties: readonly string[]
): string | null => {
  if (!obj) return null
  for (const prop of properties) {
    if (prop.includes('.')) {
      const [parent, child] = prop.split('.')
      if (obj[parent]?.[child]) return obj[parent][child]
    } else if (obj[prop]) return obj[prop]
  }
  return null
}

export interface ObjectMetadata {
  label?: string | null
  name?: string | null
  title?: string | null
  fullName?: string | null
  avatarUrl?: string | null
  photoUrl?: string | null
  photos?: { square?: string } | null
  description?: string | null
  icon?: string | null
  domain?: string | null
}

export const getBestLabel = ({
  passedProperties,
  localMetadata,
  serverMetadata,
  objectType,
  objectId,
}: {
  passedProperties?: Record<string, any> | null
  localMetadata?: ObjectMetadata | null
  serverMetadata?: ObjectMetadata | null
  objectType: NativeObjectType
  objectId: string
}): string => {
  // For pages, check title directly first
  if (objectType === NativeObjectTypes.Page) {
    const pageTitle =
      passedProperties?.title || localMetadata?.title || serverMetadata?.title
    if (pageTitle) return pageTitle
  }

  // Check each data source in priority order
  const label =
    getFirstDefinedValue(passedProperties, LABEL_PROPERTIES) ||
    getFirstDefinedValue(localMetadata, LABEL_PROPERTIES) ||
    getFirstDefinedValue(serverMetadata, LABEL_PROPERTIES)

  if (label) return label

  // Fallback to object type label or ID
  const defaultObjectLabel = ObjectTypeMetadata[objectType]?.label
  const isPersonOrOrg = (
    type: NativeObjectType
  ): type is 'native_contact' | 'native_organization' =>
    type === 'native_contact' || type === 'native_organization'

  if (defaultObjectLabel && !isPersonOrOrg(objectType)) {
    return defaultObjectLabel
  }

  return objectId
}
