import { useContext, useState, useEffect, useMemo, useCallback } from 'react'

import { Avatar } from '@mui/material'
import { IconCircleFilled } from '@tabler/icons-react'
import type { CRMObject } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { getObjectMetadata } from 'src/lib/indexedDb'
import { getBestLabel } from 'src/lib/objectLabels'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'

import ContactAvatar from '../../ContactAvatar/ContactAvatar'
import DomainAvatar from '../../DomainAvatar/DomainAvatar'
import BaseChip from '../BaseChip'

const GET_CHIP_METADATA = gql`
  query ChipMetadata(
    $objectId: String!
    $objectType: String!
    $workspaceId: String!
  ) {
    chipMetadata(
      objectId: $objectId
      objectType: $objectType
      workspaceId: $workspaceId
    ) {
      label
      icon
      domain
      avatarUrl
    }
  }
`

const avatarSize = 16

interface ObjectChipProps {
  workspaceId: string
  crmObject: Partial<CRMObject>
  fullWidth?: boolean
  showSidebar?: boolean
  onRemove?: (object: Partial<CRMObject>) => void
}

const ObjectChip = ({
  workspaceId,
  crmObject,
  fullWidth = false,
  showSidebar = false,
  onRemove,
}: ObjectChipProps) => {
  const { setSidebarObject } = useContext(DayContext)
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null)
  const [localMetadata, setLocalMetadata] = useState<{
    label: string | null
    avatarUrl: string | null
  }>({ label: null, avatarUrl: null })

  const {
    objectType,
    objectId,
    properties: objectProperties,
  } = useMemo(() => crmObject || {}, [crmObject])

  const { data } = useQuery(GET_CHIP_METADATA, {
    variables: {
      objectId,
      objectType,
      workspaceId,
    },
    skip:
      !objectId ||
      !objectType ||
      !workspaceId ||
      [NativeObjectTypes.MeetingRecording].includes(objectType as any),
  })

  const chipMetadata = data?.chipMetadata

  useEffect(() => {
    if (chipMetadata) {
      setAvatarUrl(chipMetadata.avatarUrl)
      setLocalMetadata({
        label: chipMetadata.label,
        avatarUrl: chipMetadata.avatarUrl,
      })
    } else {
      getObjectMetadata({
        workspaceId,
        objectType: objectType as NativeObjectType,
        objectId,
      }).then((metadata) => {
        setAvatarUrl(metadata.avatarUrl)
        setLocalMetadata({
          label: metadata.label,
          avatarUrl: metadata.avatarUrl,
        })
      })
    }
  }, [workspaceId, objectType, objectId, chipMetadata])

  const handleSetSidebarObject = useCallback(() => {
    if (showSidebar) {
      setSidebarObject({
        objectType,
        objectId,
        properties: objectProperties,
      })
    }
  }, [showSidebar, objectType, objectId, objectProperties, setSidebarObject])

  const bestLabel = useMemo(() => {
    const bestLabel = getBestLabel({
      passedProperties: objectProperties as Record<string, any>,
      localMetadata,
      serverMetadata: chipMetadata,
      objectType: objectType as NativeObjectType,
      objectId,
    })
    return bestLabel
  }, [objectProperties, localMetadata, chipMetadata, objectType, objectId])

  const fallbackAvatar = useMemo(() => {
    if (objectType === NativeObjectTypes.Organization) {
      return (
        <DomainAvatar
          domain={objectId}
          size={avatarSize}
        />
      )
    } else if (objectType === NativeObjectTypes.Person) {
      return (
        <ContactAvatar
          email={objectId}
          size={avatarSize}
        />
      )
    } else {
      const objectIcon =
        ObjectTypeMetadata[objectType]?.icon || IconCircleFilled
      return (
        <Avatar
          src={null}
          sx={{
            background: (theme) => theme.palette.background.default,
          }}
        >
          {React.createElement(objectIcon, {
            size: '12px',
            stroke: 2.45,
            style: {
              marginTop: '-1px',
            },
          })}
        </Avatar>
      )
    }
  }, [objectType, objectId])

  return (
    <BaseChip
      label={bestLabel}
      avatar={avatarUrl ? <Avatar src={avatarUrl} /> : fallbackAvatar}
      icon={null}
      onRemove={onRemove ? () => onRemove(crmObject) : null}
      fullWidth={fullWidth}
      onClick={
        showSidebar
          ? () => {
              handleSetSidebarObject()
            }
          : null
      }
    />
  )
}

export default ObjectChip
