import { logger } from 'src/lib/logger'

function isJustNodes(contentJson: any) {
  return (
    Array.isArray(contentJson) ||
    contentJson?.length ||
    contentJson?.type !== 'doc'
  )
}

// Helper functions for title handling
export function extractTitleAndContent(contentJson: any) {
  const content = isJustNodes(contentJson)
    ? { type: 'doc', content: contentJson }
    : contentJson

  if (!content?.content || !Array.isArray(content.content)) {
    return { title: null, content: content }
  }

  const title = content.content.find((node) => node.type === 'title')
  const otherContent = {
    type: 'doc',
    content: content.content.filter((node) => node.type !== 'title'),
  }
  logger.dev('extractTitleAndContent', { content, contentJson, otherContent })

  return { title, content: otherContent }
}

export function combineTitleAndContent(title: string, content: any) {
  const { title: existingTitleNode, content: existingContentWithoutTitleNode } =
    extractTitleAndContent(content)

  const existingTitleText = existingTitleNode?.content[0]?.text

  const newTitleNode = {
    type: 'title',
    content: [{ type: 'text', text: title || existingTitleText }],
  }

  const newContent = [newTitleNode, ...existingContentWithoutTitleNode.content]

  return {
    type: 'doc',
    content: newContent,
  }
}

export function ensureValidPageJson(pageJson: any) {
  logger.dev('ensureValidPageJson', {
    pageJson,
    isArray: Array.isArray(pageJson?.content),
  })
  if (isJustNodes(pageJson)) {
    pageJson = { type: 'doc', content: pageJson }
  }
  logger.dev('ensureValidPageJson', { pageJson })
  return pageJson
}
