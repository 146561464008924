import { useContext } from 'react'

import { Tab, Tabs } from '@mui/material'
import {
  RiBarChart2Fill,
  RiBarChart2Line,
  RiBuilding2Fill,
  RiBuilding2Line,
  RiGroupFill,
  RiGroupLine,
  RiStackFill,
  RiStackLine,
  RiToolsFill,
  RiToolsLine,
  RiVideoFill,
  RiVideoLine,
} from '@remixicon/react'

import { routes } from '@redwoodjs/router'
import { navigate } from '@redwoodjs/router'

import { DayContext } from 'src/lib/dayContext'

const LibraryNav = ({ tab }) => {
  const { selectedWorkspace, workAccountWorkspaceConnections } =
    useContext(DayContext)
  return (
    <Tabs
      value={tab}
      centered={true}
      sx={{
        height: '64px',
        justifyContent: 'center',
        width: '100%',
        '& .MuiButtonBase-root': {
          px: 4,
          py: 0,
          minHeight: '64px',
          fontSize: '0.8rem',
          height: '64px',
          '&.Mui-selected': {
            color: (theme) => theme.palette.primary.main,
          },
        },
        '& .MuiTab-iconWrapper.remixicon': {},
      }}
      TabIndicatorProps={{
        sx: {
          height: '3px',
          backgroundColor: (theme) => theme.palette.primary.main,
        },
      }}
    >
      <Tab
        value="meetings"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          if (
            workAccountWorkspaceConnections?.some(
              ({ connectedWorkspaces }) => connectedWorkspaces.length > 0
            )
          ) {
            navigate(
              routes.workspaceLibrary({ workspaceId: selectedWorkspace })
            )
          } else {
            navigate(routes.library())
          }
        }}
        label="Recordings"
        icon={
          tab === 'meetings' ? (
            <RiVideoFill size={14} />
          ) : (
            <RiVideoLine size={14} />
          )
        }
        iconPosition="start"
      />
      <Tab
        value="people"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          navigate(routes.people())
        }}
        label="People"
        icon={
          tab === 'people' ? (
            <RiGroupFill size={14} />
          ) : (
            <RiGroupLine size={14} />
          )
        }
        iconPosition="start"
      />
      <Tab
        value="organizations"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          navigate(routes.organizations())
        }}
        label="Organizations"
        icon={
          tab === 'organizations' ? (
            <RiBuilding2Fill size={14} />
          ) : (
            <RiBuilding2Line size={14} />
          )
        }
        iconPosition="start"
      />
      <Tab
        value="pipelines"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          navigate(routes.pipelines())
        }}
        label="Pipelines"
        icon={
          tab === 'pipelines' ? (
            <RiBarChart2Fill size={14} />
          ) : (
            <RiBarChart2Line size={14} />
          )
        }
        iconPosition="start"
      />
      <Tab
        value="actions"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          navigate(routes.actions())
        }}
        label="Actions"
        icon={
          tab === 'actions' ? (
            <RiToolsFill size={14} />
          ) : (
            <RiToolsLine size={14} />
          )
        }
        iconPosition="start"
      />
      <Tab
        value="pages"
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          navigate(routes.pages())
        }}
        label="Pages"
        icon={
          tab === 'pages' ? (
            <RiStackFill size={14} />
          ) : (
            <RiStackLine size={14} />
          )
        }
        iconPosition="start"
      />
    </Tabs>
  )
}

export default LibraryNav
