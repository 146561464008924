import { useRef, useState } from 'react'

import { FileCopy } from '@mui/icons-material'
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  InputAdornment,
  Tooltip,
} from '@mui/material'
import { RiShareForwardLine } from '@remixicon/react'
import { IconLinkMinus, IconLinkPlus } from '@tabler/icons-react'
import toast from 'react-hot-toast'

import { routes } from '@redwoodjs/router'

import Row from 'src/components/Row/Row'
import { logger } from 'src/lib/logger'

const URLInputBox = ({ url }) => {
  const inputRef = useRef(null)
  const [openTooltip, setOpenTooltip] = useState(false)

  const handleFocus = (event) => {
    event.target.select()
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(() => {
      setOpenTooltip(true)
      setTimeout(() => setOpenTooltip(false), 1500) // Automatically hide tooltip after delay
    })
  }

  return (
    <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
      <TextField
        fullWidth
        value={url}
        onFocus={handleFocus}
        autoFocus={true}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip
                title="Copy to clipboard"
                open={openTooltip}
              >
                <IconButton
                  onClick={copyToClipboard}
                  edge="end"
                >
                  <FileCopy />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
        variant="outlined"
        inputRef={inputRef}
      />
    </Box>
  )
}

const PageShare = ({ page, updatePage }) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleShareToggle = async () => {
    const newStatus = page.madeExternalAt ? null : new Date().toISOString()
    try {
      await toast.promise(updatePage({ ...page, madeExternalAt: newStatus }), {
        loading: 'Updating...',
        success: () => {
          return page.madeExternalAt
            ? 'Page made private'
            : 'Page shared publicly'
        },
        error: 'Error updating page',
      })
      if (page.madeExternalAt) handleClose()
    } catch (error) {
      logger.error("Failed to update the page's visibility", error)
    }
  }

  return (
    <Row>
      {page.madeExternalAt && (
        <IconButton
          onClick={() => {
            navigator.clipboard.writeText(
              `${process.env.HOST}${
                page.shortLinkHash
                  ? routes.pageShortLink({ shortLinkHash: page.shortLinkHash })
                  : routes.pagePublic({ id: page.id })
              }`
            )
            toast.success('Copied page link to clipboard')
          }}
          sx={{
            p: '4px',
            borderRadius: '2px',
          }}
        >
          <RiShareForwardLine size={16} />
        </IconButton>
      )}
      {!page.madeExternalAt && (
        <IconButton
          onClick={() => setOpen(true)}
          color="inherit"
          sx={{
            m: 1,
            p: '4px',
            borderRadius: '2px',
            '& .tabler-icon': {
              height: '16px',
              width: '16px',
              opacity: 0.7,
            },
          }}
        >
          {page.madeExternalAt ? <IconLinkMinus /> : <IconLinkPlus />}
        </IconButton>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          {page.madeExternalAt ? 'Make Page Private' : 'Share Page Publicly'}
        </DialogTitle>
        <DialogContent>
          {page.madeExternalAt
            ? 'This page is currently shared publicly. Would you like to make it private?'
            : 'This page is currently private. Would you like to share it publicly?'}
        </DialogContent>
        {page.madeExternalAt && (
          <Box sx={{}}>
            <URLInputBox
              url={`${process.env.HOST}${
                page.shortLinkHash
                  ? routes.pageShortLink({ shortLinkHash: page.shortLinkHash })
                  : routes.pagePublic({ id: page.id })
              }`}
            />
          </Box>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleShareToggle}
            color="primary"
          >
            {page.madeExternalAt ? 'Make Private' : 'Share Publicly'}
          </Button>
        </DialogActions>
      </Dialog>
    </Row>
  )
}

export default PageShare
