import type { ReactNode } from 'react'
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react'

import {
  IconClock,
  IconExclamationCircle,
  IconFlame,
  IconInfoCircle,
} from '@tabler/icons-react'
import type {
  Action,
  ActionType,
  ActionStatus,
  CreateWorkspaceUserContextInput,
  UpdateActionInput,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

const GET_ACTIONS_FOR_INBOX = gql`
  query GetActionsForInbox(
    $workspaceId: String!
    $type: ActionType
    $status: ActionStatus
  ) {
    actionsForInbox(workspaceId: $workspaceId, type: $type, status: $status) {
      id
      workspaceId
      createdAt
      updatedAt
      title
      description
      reasoning
      type
      priority
      people
      organizations
      opportunityIds
      pinnedFor

      channel {
        id
        label
        type
        accountId
      }
      status {
        id
        label
        updatedAt
      }
      assignedAt
      owner {
        id
        email
      }
      draft {
        title
        body
      }
      source {
        label
        type
        id
      }
      pipelineType
      timeframe {
        dueDate
        reminderDate
        updatedAt
      }
      opportunity
    }
  }
`

const UPDATE_ACTION_FROM_CONTEXT = gql`
  mutation UpdateActionFromContext($input: UpdateActionInput!) {
    updateActionByUser(input: $input)
  }
`

const ADD_CONTEXT_TO_ACTION = gql`
  mutation AddContextToAction($input: CreateWorkspaceUserContextInput!) {
    createWorkspaceUserContext(input: $input) {
      id
    }
  }
`

type InboxSort = 'newest' | 'oldest' | 'priority' | 'revenue'

type InboxContextType = {
  actions: Action[]
  workspaceId: string
  type: ActionType
  setType: (type: ActionType) => void
  refetch: () => void
  selectedAction: Action | null
  setSelectedAction: (action: Action | null) => void
  loading: boolean
  sort: InboxSort
  setSort: (sort: InboxSort) => void
  markAsRead: (params: { actionId: string }) => void
  markAsUnread: (params: { actionId: string }) => void
  addContext: (params: {
    input: CreateWorkspaceUserContextInput
  }) => Promise<void>
  selectNextAction: () => void
  status: ActionStatus | null
  setStatus: (status: ActionStatus | null) => void
  updateAction: (params: { input: UpdateActionInput }) => Promise<void>
  updateActionLoading: boolean
}

export const actionPriorityMetadata = {
  LOW: {
    label: 'Low',
    color: 'info',
    icon: <IconInfoCircle size={12} />,
    order: 1,
  },
  MEDIUM: {
    label: 'Medium',
    color: 'warning',
    icon: <IconClock size={12} />,
    order: 2,
  },
  HIGH: {
    label: 'High',
    color: 'error',
    icon: <IconExclamationCircle size={12} />,
    order: 3,
  },
  URGENT: {
    label: 'Urgent',
    color: 'error',
    icon: <IconFlame size={12} />,
    order: 4,
  },
}

const sortFunctions: Record<InboxSort, (actions: Action[]) => Action[]> = {
  newest: (actions) =>
    actions.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    ),
  oldest: (actions) =>
    actions.sort(
      (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    ),
  priority: (actions) =>
    actions.sort(
      (a, b) =>
        actionPriorityMetadata[b.priority].order -
        actionPriorityMetadata[a.priority].order
    ),
  revenue: (actions) =>
    actions.sort(
      (a, b) => b.opportunity?.expectedRevenue - a.opportunity?.expectedRevenue
    ),
}

/*

- mark as read
- mark as unread
- star
- unstar

*/

const InboxContext = createContext<InboxContextType | undefined>(undefined)

const getStoredInboxSettings = (workspaceId: string) => {
  try {
    const stored = localStorage.getItem(`inbox-settings-${workspaceId}`)
    return stored ? JSON.parse(stored) : null
  } catch (error) {
    logger.error('Error reading inbox settings from localStorage:', error)
    return null
  }
}

export const InboxProvider = ({
  children,
  workspaceId,
}: {
  children: ReactNode
  workspaceId: string
}) => {
  const { peopleByEmail, orgsByDomain } = useContext(DayContext)
  const [type, setType] = useState<ActionType>(() => {
    const stored = getStoredInboxSettings(workspaceId)
    return stored?.type || 'SUPPORT'
  })
  const [selectedAction, setSelectedAction] = useState<Action | null>(null)
  const [sort, setSort] = useState<InboxSort>(() => {
    const stored = getStoredInboxSettings(workspaceId)
    return stored?.sort || 'newest'
  })
  const [status, setStatus] = useState<ActionStatus | null>(() => {
    const stored = getStoredInboxSettings(workspaceId)
    return stored?.status || 'UNREAD'
  })
  const { data, refetch, loading } = useQuery(GET_ACTIONS_FOR_INBOX, {
    variables: { workspaceId, type, status },
  })

  const [addContextToAction, { loading: addContextLoading }] = useMutation(
    ADD_CONTEXT_TO_ACTION
  )
  const [updateActionFromContext, { loading: updateActionLoading }] =
    useMutation(UPDATE_ACTION_FROM_CONTEXT)

  useEffect(() => {
    try {
      localStorage.setItem(
        `inbox-settings-${workspaceId}`,
        JSON.stringify({ type, sort, status })
      )
    } catch (error) {
      logger.error('Error saving inbox settings to localStorage:', error)
    }
  }, [type, sort, status, workspaceId])

  const actions = useMemo(() => {
    return sortFunctions[sort]([
      ...(data?.actionsForInbox.map((action) => ({
        ...action,
        people: action.people.map(
          (person) => peopleByEmail[person.email] || person
        ),
        organizations: action.organizations.map(
          (org) => orgsByDomain[org.domain]
        ),
      })) || []),
    ])
  }, [data, peopleByEmail, orgsByDomain, sort])

  const handleMarkAsRead = useCallback(({ actionId }: { actionId: string }) => {
    logger.dev({ actionId })
  }, [])

  const handleMarkAsUnread = useCallback(
    ({ actionId }: { actionId: string }) => {
      logger.dev({ actionId })
    },
    []
  )

  const handleUpdateActionFromContext = useCallback(
    async ({ input }: { input: UpdateActionInput }): Promise<void> => {
      try {
        await updateActionFromContext({
          variables: { input },
          refetchQueries: [
            {
              query: GET_ACTIONS_FOR_INBOX,
              variables: { workspaceId, type, status },
            },
          ],
        })
      } catch (error) {
        logger.error('Failed to update action:', error)
        throw error
      }
    },
    [updateActionFromContext, workspaceId, type, status]
  )

  const handleAddContext = useCallback(
    ({ input }: { input: CreateWorkspaceUserContextInput }) => {
      logger.dev({ input })
      return addContextToAction({ variables: { input } })
    },
    []
  )

  const handleSelectNextAction = useCallback(() => {
    if (!selectedAction || !actions.length) return
    const currentIndex = actions.findIndex((a) => a.id === selectedAction.id)
    if (currentIndex === -1 || currentIndex === actions.length - 1) return
    setSelectedAction(actions[currentIndex + 1])
  }, [actions, selectedAction])

  const value = useMemo(
    () => ({
      actions,
      workspaceId,
      refetch: refetch,
      selectedAction,
      setSelectedAction,
      updateAction: handleUpdateActionFromContext,
      loading,
      setSort,
      sort,
      markAsRead: handleMarkAsRead,
      markAsUnread: handleMarkAsUnread,
      addContext: handleAddContext,
      selectNextAction: handleSelectNextAction,
      type,
      setType,
      status,
      setStatus,
      addContextLoading,
      updateActionLoading,
    }),
    [
      data,
      workspaceId,
      refetch,
      selectedAction,
      loading,
      sort,
      handleMarkAsRead,
      handleMarkAsUnread,
      handleAddContext,
      handleSelectNextAction,
      type,
      setType,
      status,
      setStatus,
      addContextLoading,
      updateActionLoading,
      handleUpdateActionFromContext,
    ]
  )

  return <InboxContext.Provider value={value}>{children}</InboxContext.Provider>
}

export const useInbox = () => {
  const context = useContext(InboxContext)
  if (context === undefined) {
    throw new Error('useInbox must be used within a InboxProvider')
  }
  return context
}
