import { useContext, useEffect, useMemo, useState } from 'react'

import { gql, useMutation } from '@apollo/client'
import ReactJson from '@microlink/react-json-view'
import { Box, Button, Chip, Stack, TextField, Typography } from '@mui/material'
import { IconInbox, IconSparkles } from '@tabler/icons-react'
import { toast } from 'react-hot-toast'

import { useAuth } from 'src/auth'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import ActionActions from '../Actions/ActionActions/ActionActions'
import ActionPriorityChip from '../Actions/ActionPriorityChip/ActionPriorityChip'
import ObjectChipRow from '../Chips/ObjectChipRow/ObjectChipRow'
import PersonChip from '../People/PersonChip/PersonChip'
import Row from '../Row/Row'
import SidebarButton from '../Sidebar/SidebarButton/SidebarButton'

import { typeLabels } from './inbox'

const CREATE_PAGE = gql`
  mutation CreatePage($input: CreatePageInput!) {
    createPage(input: $input) {
      id
      title
      contentJson
      contentHtml
      ownerEmail
      createdAt
      updatedAt
      shortLinkHash
      madeExternalAt
      workspaceId
      emoji
      headerImage
      publishedForUserAt
      parentObject {
        objectId
        objectType
      }
      crmObjects {
        objectId
        objectType
        properties
        workspaceId
      }
      aiInitialPrompt
      aiPopulationCompletedAt
    }
  }
`

const MARK_ACTION_READ = gql`
  mutation MarkActionRead($input: UpdateActionInput!) {
    updateActionByUser(input: $input)
  }
`

const InboxActionDetail = ({
  selectedAction,
  refetch,
  type,
  updateAction,
  showCrmObjects = false,
  onHide = (_actionId: string) => {},
}) => {
  const { currentUser } = useAuth()
  const { setSidebarObject } = useContext(DayContext)
  const [actionPrompt, setActionPrompt] = useState('')
  const [pageCreationUnderway, setPageCreationUnderway] = useState(false)
  const [editingTitle, setEditingTitle] = useState(false)
  const [title, setTitle] = useState(selectedAction?.title || '')

  const isActionOwner = useMemo(() => {
    return selectedAction?.owner?.email === currentUser?.email
  }, [selectedAction, currentUser])

  const [markRead] = useMutation(MARK_ACTION_READ, {
    refetchQueries: ['GetActionsLibrary'],
    awaitRefetchQueries: true,
  })

  useEffect(() => {
    setTitle(selectedAction?.title || '')
    setEditingTitle(false)

    // Add debug logging
    logger.dev('Action detail mounted:', {
      actionId: selectedAction?.id,
      isOwner: isActionOwner,
      status: selectedAction?.status?.id,
      ownerEmail: selectedAction?.owner?.email,
      currentUserEmail: currentUser?.email,
    })

    // Mark action as READ when mounted if user is the owner and status is UNREAD
    if (
      selectedAction?.id &&
      isActionOwner &&
      selectedAction?.status?.id === 'UNREAD'
    ) {
      logger.dev('Marking action as read:', {
        actionId: selectedAction.id,
        workspaceId: selectedAction.workspaceId,
      })

      markRead({
        variables: {
          input: {
            id: selectedAction.id,
            workspaceId: selectedAction.workspaceId,
            status: 'READ',
          },
        },
      }).catch((error) => {
        logger.error('Failed to mark action as read:', error)
      })
    }
  }, [selectedAction, isActionOwner, markRead, currentUser?.email])

  const handleUpdateAction = async () => {
    if (!selectedAction) return
    setEditingTitle(false)

    await updateAction({
      id: selectedAction.id,
      title,
      workspaceId: selectedAction.workspaceId,
    })
  }

  const [createPage] = useMutation(CREATE_PAGE)

  const handleCreatePage = async () => {
    try {
      setPageCreationUnderway(true)
      const result = await createPage({
        variables: {
          input: {
            workspaceId: selectedAction.workspaceId,
            title: `Action: ${selectedAction.title}`,
            aiInitialPrompt: actionPrompt,
            contentJson: {
              type: 'doc',
              content: [],
            },
            contentHtml: '',
            objectType: NativeObjectTypes.Action,
            objectId: selectedAction.id,
            crmObjects: [
              {
                objectType: NativeObjectTypes.Action,
                objectId: selectedAction.id,
              },
              ...(selectedAction?.people || []).map((person) => ({
                objectType: NativeObjectTypes.Person,
                objectId: person.email,
              })),
              ...(selectedAction?.organizations || []).map((org) => ({
                objectType: NativeObjectTypes.Organization,
                objectId: org.domain,
              })),
            ],
          },
        },
      })
      if (result.data?.createPage) {
        const newSidebarPageObject = {
          objectId: result.data.createPage.id,
          objectType: NativeObjectTypes.Page,
          workspaceId: selectedAction.workspaceId,
        }

        setSidebarObject(newSidebarPageObject)
        setPageCreationUnderway(false)
      }
    } catch (error) {
      logger.error('Failed to create page:', error)
      setPageCreationUnderway(false)
      toast.error('Failed to create page')
    }
  }

  const crmObjects = useMemo(() => {
    return [
      ...(selectedAction?.people || []).map((person) => ({
        objectType: NativeObjectTypes.Person,
        objectId: person.email,
        properties: {
          email: person.email,
        },
      })),
      ...(selectedAction?.organizations || []).map((organization) => ({
        objectType: NativeObjectTypes.Organization,
        objectId: organization.domain,
        properties: {
          domain: organization.domain,
        },
      })),
      ...(selectedAction?.opportunityIds || []).map((opportunityId) => ({
        objectType: NativeObjectTypes.Opportunity,
        objectId: opportunityId,
        properties: {
          id: opportunityId,
        },
      })),
    ]
  }, [selectedAction])

  return selectedAction?.id ? (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        maxHeight: 'calc(100vh - 64px)',
        overflowY: 'hidden',
      }}
    >
      <Row
        sx={{
          height: '56px',
          justifyContent: 'space-between',
        }}
      >
        <Row gap={1}>
          <ActionPriorityChip action={selectedAction} />
          <ActionActions
            action={selectedAction}
            showButtons={true}
            onUpdate={() => {
              refetch()
            }}
            onHide={(actionId) => {
              onHide(actionId)
            }}
          />
        </Row>
        <Row gap={1}>
          {selectedAction.owner?.email && (
            <PersonChip
              email={selectedAction.owner.email}
              sx={{
                border: 'none',
                px: 0,
                '& .MuiChip-label': {
                  pr: 0,
                },
              }}
            />
          )}
        </Row>
      </Row>

      {editingTitle ? (
        <Box>
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth={true}
            variant="standard"
            multiline={true}
            rows={title?.length > 64 ? 2 : 1}
            inputProps={{
              style: {
                fontSize: '24px',
                fontWeight: 600,
                letterSpacing: '-1.2px',
                lineHeight: '30px',
              },
            }}
          />
          <Row sx={{ justifyContent: 'flex-end' }}>
            <Button onClick={handleUpdateAction}>Save</Button>
          </Row>
        </Box>
      ) : (
        <Typography
          variant="h2"
          sx={{ mt: 1, mb: showCrmObjects ? 2 : 4 }}
          onClick={() => setEditingTitle(true)}
        >
          {title}
        </Typography>
      )}
      {showCrmObjects && (
        <ObjectChipRow
          objects={crmObjects}
          workspaceId={selectedAction.workspaceId}
          showSidebar={true}
        />
      )}

      <Box>
        <TextField
          fullWidth={true}
          variant="standard"
          multiline={true}
          rows={3}
          placeholder="Let's take action. What should we make?"
          value={actionPrompt}
          onChange={(e) => setActionPrompt(e.target.value)}
          sx={{
            mt: 1,
            mb: 1,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: '4px',
            background: (theme) => theme.palette.background.paper,
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              display: 'flex',
              flexDirection: 'column',
              p: '4px',
              pt: '12px',
              pl: '12px',
            },
            endAdornment: (
              <Box sx={{ width: '100%' }}>
                <Stack
                  direction="row"
                  sx={{
                    flexWrap: 'wrap-reverse',
                    gap: 1,
                    mt: 1,
                    width: 'calc(100% - 120px)',
                    mb: 0.5,
                  }}
                >
                  {[...(selectedAction?.draft?.prompts || [])]
                    .reverse()
                    .map((prompt, index) => (
                      <Chip
                        key={index}
                        label={prompt.shortPrompt}
                        size="small"
                        variant="outlined"
                        onClick={() => setActionPrompt(prompt.shortPrompt)}
                        sx={{
                          cursor: 'pointer',
                          '&:hover': {
                            backgroundColor: 'action.hover',
                          },
                          maxWidth: 250,
                          fontSize: '0.75rem',
                        }}
                      />
                    ))}
                </Stack>
                <Row
                  sx={{
                    position: 'absolute',
                    right: 8,
                    bottom: 8,
                  }}
                >
                  <SidebarButton
                    label="Create Page"
                    icon={<IconSparkles size={16} />}
                    onClick={handleCreatePage}
                    disabled={pageCreationUnderway || !actionPrompt}
                  />
                </Row>
              </Box>
            ),
          }}
        />
      </Box>

      {false && process.env.HOST.includes('localhost') && (
        <ReactJson
          src={selectedAction}
          collapsed={0}
        />
      )}
    </Box>
  ) : type ? (
    <Row sx={{ height: '90%', justifyContent: 'center', opacity: 0.5 }}>
      <Row
        sx={{ flexDirection: 'column', alignItems: 'center' }}
        gap={1}
      >
        <IconInbox size={72} />
        <Typography variant="h3">{typeLabels[type].label} Inbox</Typography>
      </Row>
    </Row>
  ) : null
}

export default InboxActionDetail
