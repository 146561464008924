import { memo } from 'react'

import { Box, IconButton, LinearProgress, Typography } from '@mui/material'
import { Dialog, DialogContent } from '@mui/material'
import { IconCopy, IconRefresh } from '@tabler/icons-react'
import toast from 'react-hot-toast'

import Row from '../Row/Row'
interface ContextDebugProps {
  label: string
  contextString: string | null
  tokenCount: number | null
  onRefetch: () => void
  tokenLimit: number
  open: boolean
  setOpen: (open: boolean) => void
}

const ContextDebug = memo(function ContextDebug({
  label,
  contextString,
  tokenCount,
  onRefetch,
  tokenLimit,
  open,
  setOpen,
}: ContextDebugProps) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="md"
    >
      <DialogContent
        sx={{
          height: '80vh',
          overflow: 'auto',
          p: 0,
        }}
      >
        <Row
          gap={3}
          sx={{
            px: 2,
            height: '48px',
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography
            variant="h3"
            sx={{ flexShrink: 0 }}
          >
            {label}
          </Typography>
          <Row
            sx={{ width: '100%' }}
            gap={1}
          >
            <Typography
              variant="h4"
              sx={{
                flexShrink: 0,
                fontSize: '11px',
                letterSpacing: '-0.4px',
                color: (theme) => theme.palette.secondary.main,
              }}
            >
              {`${tokenCount} / ${tokenLimit}`}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={Math.round((tokenCount / tokenLimit) * 100)}
              color="secondary"
              sx={{ width: '100%', height: '8px', borderRadius: '4px' }}
            />
          </Row>
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(contextString)
              toast.success('Copied to clipboard')
            }}
          >
            <IconCopy size={16} />
          </IconButton>
          <IconButton
            onClick={onRefetch}
            size="small"
          >
            <IconRefresh size={16} />
          </IconButton>
        </Row>
        <Box sx={{ p: '12px' }}>
          <Typography className="word-break-break-word whitespace-pre-wrap font-mono text-sm">
            {contextString || 'No context available'}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
})

export default ContextDebug
