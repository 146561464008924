import { useCallback, useContext, useEffect, useMemo, useReducer } from 'react'

import { gql, useQuery } from '@apollo/client'
import {
  Box,
  Chip,
  CircularProgress,
  darken,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItemButton,
  TextField,
  Typography,
} from '@mui/material'
import {
  IconCircleFilled,
  IconRefresh,
  IconBuildingPlus,
  IconUserPlus,
  IconX,
  IconSearch,
} from '@tabler/icons-react'
import { debounce } from 'lodash'
import { getDomain } from 'tldts'

import { useAuth } from 'src/auth'
import MetadataChip from 'src/components/Chips/MetadataChip/MetadataChip'
import MultiObjectChip from 'src/components/Chips/MultiObjectChip/MultiObjectChip'
import ObjectTile from 'src/components/ObjectTile/ObjectTile'
import { actionButtonStyle } from 'src/components/Sidebar/styles'
import ThreadCreateButton from 'src/components/Threads/ThreadCreateButton/ThreadCreateButton'
import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'
import { getBestLabel } from 'src/lib/objectLabels'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'
import { getRecentObjects, addToRecentObjects } from 'src/lib/recentObjects'
import {
  debouncedSearch,
  loadCachedIndex,
  populateSearchIndex,
  getIndexMetadata,
} from 'src/lib/searchService'
import type { SearchableObject } from 'src/lib/searchService'

// Extend SearchableObject type to include displayName
type SearchableObjectWithDisplay = SearchableObject & {
  displayName?: string
}

const GET_PAGES_FOR_SEARCH_MODAL = gql`
  query GetPagesForSearchModal($workspaceId: String!) {
    pages(workspaceId: $workspaceId) {
      id
      title
      emoji
      domains
      createdAt
      people
    }
  }
`

const SECTION_LABELS = {
  [NativeObjectTypes.Organization]: {
    label: ObjectTypeMetadata[NativeObjectTypes.Organization].pluralLabel,
    icon: ObjectTypeMetadata[NativeObjectTypes.Organization].icon,
  },
  [NativeObjectTypes.Contact]: {
    label: ObjectTypeMetadata[NativeObjectTypes.Contact].pluralLabel,
    icon: ObjectTypeMetadata[NativeObjectTypes.Contact].icon,
  },
  [NativeObjectTypes.MeetingRecording]: {
    label: 'Meetings',
    icon: ObjectTypeMetadata[NativeObjectTypes.MeetingRecording].icon,
  },
  [NativeObjectTypes.Pipeline]: {
    label: ObjectTypeMetadata[NativeObjectTypes.Pipeline].pluralLabel,
    icon: ObjectTypeMetadata[NativeObjectTypes.Pipeline].icon,
  },
  [NativeObjectTypes.Opportunity]: {
    label: ObjectTypeMetadata[NativeObjectTypes.Opportunity].pluralLabel,
    icon: ObjectTypeMetadata[NativeObjectTypes.Opportunity].icon,
  },
  [NativeObjectTypes.Page]: {
    label: ObjectTypeMetadata[NativeObjectTypes.Page].pluralLabel,
    icon: ObjectTypeMetadata[NativeObjectTypes.Page].icon,
  },
}

const MAX_RESULTS_PER_TYPE = 5

const isValidEmail = (value: string): boolean => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
}

const isValidDomain = (value: string): boolean => {
  // Remove any protocol and www prefix
  const cleanValue = value
    .trim()
    .toLowerCase()
    .replace(/^(https?:\/\/)?(www\.)?/, '')

  // Get the domain using tldts
  const domain = getDomain(cleanValue)
  return !!domain
}

const cleanDomain = (value: string): string => {
  const cleanValue = value
    .trim()
    .toLowerCase()
    .replace(/^(https?:\/\/)?(www\.)?/, '')
  return getDomain(cleanValue) || cleanValue
}

interface SearchModalProps {
  open?: boolean
  onClose?: () => void
  onSelect?: (result: SearchableObjectWithDisplay) => void
  contextualActions?: {
    label: string
    icon?: React.ReactNode
    onClick: (result: SearchableObject) => void
  }[]
  forcedObjectTypes?: NativeObjectType[]
  allowCreation?: boolean
  targetObjectType?: NativeObjectType
}

const DEBOUNCE_MS = 300 // Balance between responsiveness and UI stability

interface SearchState {
  query: string
  results: SearchableObject[]
  loading: boolean
  selectedTypes: Set<NativeObjectType>
  isValidFreeSoloInput: boolean
  recentObjects: SearchableObject[]
  isTyping: boolean
}

type SearchAction =
  | { type: 'SET_QUERY'; payload: string }
  | { type: 'SET_RESULTS'; payload: SearchableObject[] }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'TOGGLE_TYPE'; payload: NativeObjectType }
  | { type: 'SET_TYPES'; payload: Set<NativeObjectType> }
  | { type: 'SET_RECENT_OBJECTS'; payload: SearchableObject[] }
  | {
      type: 'VALIDATE_INPUT'
      payload: { query: string; results: SearchableObject[] }
    }
  | { type: 'RESET' }
  | { type: 'BATCH_UPDATE'; payload: Partial<SearchState> }
  | { type: 'SET_TYPING'; payload: boolean }

const initialState = (forcedObjectTypes?: NativeObjectType[]): SearchState => ({
  query: '',
  results: [],
  loading: false,
  selectedTypes: forcedObjectTypes
    ? new Set<NativeObjectType>(forcedObjectTypes)
    : new Set<NativeObjectType>(),
  isValidFreeSoloInput: false,
  recentObjects: [],
  isTyping: false,
})

function searchReducer(state: SearchState, action: SearchAction): SearchState {
  switch (action.type) {
    case 'SET_QUERY':
      return {
        ...state,
        query: action.payload,
        isTyping: true,
        // Clear results and validation if query is empty
        ...(action.payload === '' && {
          results: [],
          isValidFreeSoloInput: false,
          isTyping: false,
        }),
      }
    case 'SET_TYPING':
      return {
        ...state,
        isTyping: action.payload,
      }
    case 'SET_RESULTS':
      return {
        ...state,
        results: action.payload,
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      }
    case 'TOGGLE_TYPE': {
      const newTypes = new Set(state.selectedTypes)
      if (newTypes.has(action.payload)) {
        newTypes.delete(action.payload)
      } else {
        newTypes.add(action.payload)
      }
      // If no types selected, show everything
      return {
        ...state,
        selectedTypes: newTypes.size === 0 ? new Set() : newTypes,
      }
    }
    case 'SET_TYPES':
      return {
        ...state,
        selectedTypes: action.payload,
      }
    case 'SET_RECENT_OBJECTS':
      return {
        ...state,
        recentObjects: action.payload,
      }
    case 'VALIDATE_INPUT':
      return {
        ...state,
        isValidFreeSoloInput:
          action.payload.results.length === 0 &&
          (isValidEmail(action.payload.query) ||
            isValidDomain(action.payload.query)),
      }
    case 'RESET':
      return {
        ...initialState(
          state.selectedTypes.size > 0 ? Array.from(state.selectedTypes) : []
        ),
      }
    case 'BATCH_UPDATE':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

const SearchModal = ({
  open = false,
  onClose,
  onSelect,
  contextualActions = [],
  forcedObjectTypes = [],
  allowCreation = false,
  targetObjectType,
}: SearchModalProps) => {
  const {
    pipelines,
    orgsByDomain,
    workspacePeople,
    selectedWorkspace,
    meetingRecordings,
  } = useContext(DayContext)
  const { currentUser: _currentUser } = useAuth()

  const [state, dispatch] = useReducer(
    searchReducer,
    forcedObjectTypes,
    initialState
  )

  const {
    query,
    results,
    loading,
    selectedTypes,
    isValidFreeSoloInput,
    recentObjects,
    isTyping,
  } = state

  const { data: pagesData } = useQuery(GET_PAGES_FOR_SEARCH_MODAL, {
    variables: {
      workspaceId: selectedWorkspace,
    },
    skip: !selectedWorkspace,
  })

  const pages = useMemo(() => pagesData?.pages || [], [pagesData?.pages])

  // Load cached index immediately when modal opens
  useEffect(() => {
    if (!open) return

    const recent = getRecentObjects()
    dispatch({ type: 'SET_RECENT_OBJECTS', payload: recent })

    // Try to load cached index immediately
    loadCachedIndex().then(() => {
      logger.dev('Loaded cached index')
    })

    // Build fresh index in the background if we have all the data
    if (selectedWorkspace && pipelines && orgsByDomain && workspacePeople) {
      logger.dev('Building fresh index...')
      populateSearchIndex(
        pipelines,
        Object.values(orgsByDomain),
        workspacePeople,
        meetingRecordings || [],
        pages
      ).then(() => {
        logger.dev('Fresh index built')
      })
    }
  }, [
    open,
    selectedWorkspace,
    pipelines,
    orgsByDomain,
    workspacePeople,
    meetingRecordings,
    pages,
  ])

  // Memoize the search handler to prevent recreation
  const debouncedSearchHandler = useMemo(
    () =>
      debounce(
        async (searchQuery: string, dispatch: React.Dispatch<SearchAction>) => {
          // Set typing to false since debounce period has passed
          dispatch({ type: 'SET_TYPING', payload: false })

          // Handle empty query
          if (!searchQuery) {
            dispatch({ type: 'SET_RESULTS', payload: [] })
            dispatch({ type: 'SET_LOADING', payload: false })
            return
          }

          // Start loading
          dispatch({ type: 'SET_LOADING', payload: true })

          try {
            const searchResults = await debouncedSearch(searchQuery)
            dispatch({ type: 'SET_RESULTS', payload: searchResults ?? [] })
            dispatch({
              type: 'VALIDATE_INPUT',
              payload: { query: searchQuery, results: searchResults ?? [] },
            })
          } catch (error) {
            logger.error('Search error:', error)
            dispatch({ type: 'SET_RESULTS', payload: [] })
          } finally {
            dispatch({ type: 'SET_LOADING', payload: false })
          }
        },
        DEBOUNCE_MS
      ),
    []
  )

  // Handle query changes immediately for UI responsiveness
  const handleQueryChange = useCallback(
    (newQuery: string) => {
      // Cancel any pending searches
      debouncedSearchHandler.cancel()

      // Only update the query text immediately
      dispatch({ type: 'SET_QUERY', payload: newQuery })

      // Trigger the debounced search
      debouncedSearchHandler(newQuery, dispatch)
    },
    [debouncedSearchHandler]
  )

  // Reset state when modal closes
  useEffect(() => {
    if (!open) {
      dispatch({ type: 'RESET' })
    }
  }, [open])

  // Clean up the debounced handler
  useEffect(() => {
    return () => {
      debouncedSearchHandler.cancel()
    }
  }, [debouncedSearchHandler])

  // Separate effect for validation state
  useEffect(() => {
    if (!query) {
      dispatch({ type: 'VALIDATE_INPUT', payload: { query: '', results: [] } })
      return
    }
    dispatch({
      type: 'VALIDATE_INPUT',
      payload: { query: query, results: results },
    })
  }, [query, results])

  const handleClose = useCallback(() => {
    dispatch({ type: 'RESET' })
    onClose?.()
  }, [onClose])

  const supportedTypes = useMemo(() => {
    return Object.keys(SECTION_LABELS)
  }, [])

  const handleSelect = useCallback(
    (result: SearchableObject) => {
      const resultWithDisplay: SearchableObjectWithDisplay = {
        ...result,
        displayName:
          result.label ||
          getBestLabel({
            passedProperties: result.properties,
            objectType: result.objectType,
            objectId: result.objectId,
          }),
      }

      if (onSelect) {
        onSelect(resultWithDisplay)
      }
      onClose?.()
      addToRecentObjects(result)
    },
    [onSelect, onClose]
  )

  const handleTypeToggle = useCallback((type: NativeObjectType) => {
    dispatch({ type: 'TOGGLE_TYPE', payload: type })
  }, [])

  const handleViewAll = useCallback((type: NativeObjectType) => {
    dispatch({ type: 'SET_TYPES', payload: new Set([type]) })
  }, [])

  const groupedResults = useMemo(() => {
    const groups: Record<string, SearchableObject[]> = {}
    const itemsToGroup = query ? results : recentObjects
    const filteredResults =
      forcedObjectTypes.length > 0
        ? itemsToGroup.filter((r) => forcedObjectTypes.includes(r.objectType))
        : selectedTypes.size > 0
          ? itemsToGroup.filter((r) => selectedTypes.has(r.objectType))
          : itemsToGroup

    // Group results and track best composite scores
    const groupScores: Record<string, number> = {}
    filteredResults?.forEach((result: any) => {
      if (!groups[result.objectType]) {
        groups[result.objectType] = []
        groupScores[result.objectType] = result.compositeScore ?? 0
      }
      groups[result.objectType].push(result)
      // Track best (highest) composite score for the group
      if ((result.compositeScore ?? 0) > groupScores[result.objectType]) {
        groupScores[result.objectType] = result.compositeScore ?? 0
      }
    })

    // Sort groups by best composite score first
    const sortedGroups: Record<string, SearchableObject[]> = {}
    Object.entries(groups)
      .sort(([, resultsA], [, resultsB]) => {
        // Get the best composite score from each group
        const bestScoreA = Math.max(
          ...resultsA.map((r) => (r as any).compositeScore ?? 0)
        )
        const bestScoreB = Math.max(
          ...resultsB.map((r) => (r as any).compositeScore ?? 0)
        )

        // Sort by composite score (higher is better)
        return bestScoreB - bestScoreA
      })
      .forEach(([type, typeResults]) => {
        sortedGroups[type] = typeResults
      })

    return sortedGroups
  }, [query, results, recentObjects, selectedTypes, forcedObjectTypes])

  return (
    selectedWorkspace && (
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            height: '480px',
            width: '672px',
            maxWidth: '800px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexShrink: 0,
            m: 0,
            maxHeight: 'none',
            borderRadius: '12px',
          },
        }}
      >
        <DialogContent
          sx={{
            p: 0,
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
          }}
        >
          <Box sx={{ m: '4px', mb: 0, width: 'calc(100% - 8px)' }}>
            <TextField
              autoFocus
              fullWidth
              placeholder="Search"
              value={query}
              onChange={(e) => handleQueryChange(e.target.value)}
              variant="standard"
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <IconSearch
                    style={{
                      color: 'rgba(0, 0, 0, 0.3)',
                      marginRight: '8px',
                      fontSize: '20px',
                      marginLeft: '-8px',
                    }}
                  />
                ),
                sx: {
                  display: 'flex',
                  height: '41px',
                  padding: '8px 16px',
                  alignItems: 'center',
                  gap: '4px',
                  alignSelf: 'stretch',
                  borderRadius: '8px',
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  background: '#FFF',
                  boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.05)',
                  fontSize: '0.9rem',
                  letterSpacing: '-0.3px',
                  fontWeight: 500,
                  '& input': {
                    p: 0,
                    height: '100%',
                  },
                  '&::placeholder': {
                    color: 'text.secondary',
                    opacity: 0.8,
                    ml: '-4px',
                  },
                },
                endAdornment: (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '4px',
                      alignItems: 'center',
                      mr: -1,
                      transform: 'translateY(2px)',
                    }}
                  >
                    {loading && (
                      <CircularProgress
                        size={16}
                        sx={{ opacity: 0.5 }}
                      />
                    )}
                    {query && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          dispatch({ type: 'SET_QUERY', payload: '' })
                          dispatch({ type: 'SET_LOADING', payload: false })
                        }}
                      >
                        <IconX size={16} />
                      </IconButton>
                    )}
                    {!query && (
                      <Box
                        sx={{
                          display: 'flex',
                          height: '24px',
                          padding: '4px',
                          pt: 0,
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '4px',
                          color: 'text.secondary',
                          opacity: 0.5,
                        }}
                      >
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: '12px',
                            fontWeight: 500,
                            letterSpacing: '-0.3px',
                            border: (theme) =>
                              `1px solid ${darken(theme.palette.divider, 0.2)}`,
                            borderRadius: '4px',
                            background: (theme) =>
                              theme.palette.background.default,
                            px: 0.5,
                            py: 0,
                          }}
                        >
                          ⌘K
                        </Typography>
                      </Box>
                    )}
                  </Box>
                ),
              }}
            />
          </Box>

          {/* Type filter chips - only show if no forcedObjectTypes */}
          {forcedObjectTypes.length === 0 && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                m: 1,
                mt: 0,
                height: '42px',
                flexShrink: 0,
                overflowY: 'hidden',
                overflowX: 'auto',
                flexWrap: 'nowrap',
              }}
            >
              <Box
                onClick={() =>
                  dispatch({ type: 'SET_TYPES', payload: new Set() })
                }
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  height: '24px',
                  minHeight: '24px',
                  padding: '0px 8px',
                  cursor: 'pointer',
                  borderRadius: '24px',
                  background: (theme) =>
                    selectedTypes.size === 0
                      ? theme.palette.secondary.main
                      : 'transparent',
                  color: (theme) =>
                    selectedTypes.size === 0
                      ? theme.palette.secondary.contrastText
                      : theme.palette.text.secondary,
                  opacity: selectedTypes.size === 0 ? 1 : 0.7,
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    opacity: 1,
                  },
                  flexShrink: 0,
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: selectedTypes.size === 0 ? 500 : 500,
                    lineHeight: '130%',
                    letterSpacing: '-0.4px',
                  }}
                >
                  Everything
                </Typography>
              </Box>
              {Object.entries(SECTION_LABELS).map(([type, info]) => {
                const isSelected = selectedTypes.has(type)
                return (
                  <Box
                    key={type}
                    onClick={() => handleTypeToggle(type)}
                    sx={{
                      display: 'flex',
                      padding: '0px 8px',
                      alignItems: 'center',
                      gap: '4px',
                      height: '24px',
                      cursor: 'pointer',
                      borderRadius: '24px',
                      background: (theme) =>
                        isSelected
                          ? theme.palette.secondary.main
                          : 'transparent',
                      color: (theme) =>
                        isSelected
                          ? theme.palette.secondary.contrastText
                          : theme.palette.text.secondary,
                      opacity: isSelected ? 1 : 0.7,
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        opacity: 1,
                      },
                      flexShrink: 0,
                    }}
                  >
                    {React.createElement(info.icon, {
                      size: 12,
                      style: { opacity: isSelected ? 1 : 0.7 },
                    })}
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '130%',
                        letterSpacing: '-0.4px',
                      }}
                    >
                      {info.label}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          )}

          <List
            sx={{
              p: 0,
              flex: 1,
              overflowY: 'auto',
              '&::-webkit-scrollbar': { width: '0px' },
            }}
          >
            {!query && recentObjects.length > 0 && (
              <Box sx={{ pt: forcedObjectTypes.length > 0 ? 1 : 0 }} />
            )}
            {Object.entries(groupedResults ?? {}).map(([type, typeResults]) => {
              if (!supportedTypes.includes(type)) return null
              const displayResults =
                selectedTypes.size > 0
                  ? typeResults
                  : typeResults.slice(0, MAX_RESULTS_PER_TYPE)
              const hasMore =
                selectedTypes.size === 0 &&
                typeResults.length > MAX_RESULTS_PER_TYPE

              const sectionInfo = SECTION_LABELS[type] || {
                label: type,
                icon: ObjectTypeMetadata[type]?.icon || IconCircleFilled,
              }

              return (
                <Box
                  key={type}
                  sx={{
                    '& .result-action-button': {
                      display: 'none',
                      flexShrink: 0,
                    },
                  }}
                >
                  {selectedTypes.size !== 1 && (
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{
                          px: 1,
                          pt: '12px',
                          pb: '4px',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '4px',
                          color: 'text.secondary',
                          position: 'sticky',
                          top: 0,
                          zIndex: 1,
                          fontSize: '12px',
                          fontWeight: 500,
                          lineHeight: '140%',
                          letterSpacing: '-0.24px',
                          '& .count': {
                            opacity: 1,
                            fontWeight: 600,
                          },
                        }}
                      >
                        {React.createElement(sectionInfo.icon, {
                          size: 14,
                          style: {
                            opacity: 0.5,
                            marginTop: '-1px',
                            display: 'none',
                          },
                        })}
                        <span style={{ opacity: 0.5 }}>
                          {sectionInfo.label}
                        </span>{' '}
                        <span className="count">
                          {typeResults?.length ?? 0}
                        </span>
                      </Typography>
                      <Divider />
                    </Box>
                  )}
                  {displayResults?.map((result) => (
                    <ListItemButton
                      key={`${result.objectType}-${result.objectId}`}
                      onClick={() => handleSelect(result)}
                      sx={{
                        px: '12px',
                        py: 1,
                        width: '100%',
                        height: '50px',
                        '& > *': {
                          width: '100%',
                          minWidth: 0,
                          height: '100%',
                        },
                        '&:hover .result-action-button': {
                          display: 'flex',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: '100%',
                          overflow: 'hidden',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          gap: 0.5,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            height: '30px',
                            overflow: 'hidden',
                          }}
                        >
                          <Box
                            sx={{
                              width: 'calc(100% - 128px)',
                              flexShrink: 1,
                              overflow: 'hidden',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <ObjectTile
                              objectType={result.objectType}
                              objectId={result.objectId}
                              workspaceId={selectedWorkspace}
                              properties={result.properties}
                              size={30}
                            />
                          </Box>
                          <Box sx={{ flexShrink: 0, width: '128px' }}>
                            {contextualActions.length > 0 ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 1,
                                  ml: 2,
                                  flexShrink: 0,
                                }}
                              >
                                {contextualActions.map((action, index) => (
                                  <Chip
                                    key={index}
                                    size="small"
                                    className="result-action-button"
                                    icon={
                                      action.icon ||
                                      (targetObjectType &&
                                        React.createElement(
                                          ObjectTypeMetadata[targetObjectType]
                                            .icon,
                                          { size: 16 }
                                        ))
                                    }
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      action.onClick(result)
                                    }}
                                    sx={actionButtonStyle}
                                    label={action.label}
                                  />
                                ))}
                              </Box>
                            ) : targetObjectType ? (
                              <Chip
                                size="small"
                                variant="outlined"
                                icon={
                                  targetObjectType &&
                                  React.createElement(
                                    ObjectTypeMetadata[targetObjectType].icon,
                                    { size: 16 }
                                  )
                                }
                                sx={actionButtonStyle}
                                label={`Add to ${ObjectTypeMetadata[targetObjectType].label}`}
                                className="result-action-button"
                              />
                            ) : (
                              <Box className="result-action-button">
                                <ThreadCreateButton
                                  workspaceId={selectedWorkspace}
                                  sourceObject={result}
                                  otherObjects={[]}
                                  title="Create thread"
                                  onThreadCreated={() => {
                                    handleClose()
                                  }}
                                  welcomeMessage={null}
                                />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </ListItemButton>
                  ))}
                  {hasMore && (
                    <Box sx={{ p: 1 }}>
                      <MultiObjectChip
                        crmObjects={typeResults}
                        workspaceId={selectedWorkspace}
                        onClick={() => handleViewAll(type)}
                        label={`View all ${typeResults.length} ${SECTION_LABELS[type]?.label}`}
                      />
                    </Box>
                  )}
                </Box>
              )
            })}

            {query &&
              Object.keys(groupedResults ?? {}).length === 0 &&
              !loading &&
              !isTyping &&
              allowCreation &&
              (isValidFreeSoloInput ? (
                <ListItemButton
                  onClick={() => {
                    const cleanedEmail = query.includes('@')
                      ? query.trim().toLowerCase()
                      : null
                    const cleanedDomain = !query.includes('@')
                      ? cleanDomain(query)
                      : null
                    handleSelect({
                      objectType: query.includes('@')
                        ? NativeObjectTypes.Contact
                        : NativeObjectTypes.Organization,
                      objectId: cleanedEmail || cleanedDomain,
                      label: cleanedEmail || cleanedDomain,
                      lastUpdated: Date.now(),
                      properties: cleanedEmail
                        ? { email: cleanedEmail }
                        : { domain: cleanedDomain },
                    })
                  }}
                  sx={{
                    px: '12px',
                    py: 1,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      width: '100%',
                    }}
                  >
                    {query.includes('@') ? (
                      <IconUserPlus size={30} />
                    ) : (
                      <IconBuildingPlus size={30} />
                    )}
                    <Box>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: '0.9rem',
                        }}
                      >
                        Add {query.includes('@') ? 'Person' : 'Organization'}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: '0.7rem',
                          color: 'text.secondary',
                        }}
                      >
                        {query.includes('@') ? query : cleanDomain(query)}
                      </Typography>
                    </Box>
                  </Box>
                </ListItemButton>
              ) : (
                <Box component="div">
                  <ListItemButton
                    disabled
                    sx={{
                      px: '12px',
                      py: 1,
                      width: '100%',
                      opacity: 0.8,
                      '&.Mui-disabled': {
                        opacity: 0.8,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        width: '100%',
                      }}
                    >
                      <IconUserPlus size={30} />
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '0.9rem',
                          }}
                        >
                          Add Person
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: '0.7rem',
                            color: (theme) => theme.palette.secondary.main,
                          }}
                        >
                          Type a full email address to create
                        </Typography>
                      </Box>
                    </Box>
                  </ListItemButton>
                  <ListItemButton
                    disabled
                    sx={{
                      px: '12px',
                      py: 1,
                      width: '100%',
                      opacity: 0.8,
                      '&.Mui-disabled': {
                        opacity: 0.8,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        width: '100%',
                      }}
                    >
                      <IconBuildingPlus size={30} />
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '0.9rem',
                          }}
                        >
                          Add Organization
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: '0.7rem',
                            color: (theme) => theme.palette.secondary.main,
                          }}
                        >
                          Type a valid web domain to create
                        </Typography>
                      </Box>
                    </Box>
                  </ListItemButton>
                </Box>
              ))}
          </List>

          <Box
            sx={{
              height: '30px',
              bgcolor: 'background.default',
              borderTop: '1px solid',
              borderColor: 'divider',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: 1,
              '& .MuiChip-label': {
                opacity: 0.6,
              },
            }}
          >
            <MetadataChip
              state={{
                label: loading
                  ? 'Loading'
                  : getIndexMetadata()?.lastBuilt
                    ? `Updated ${dayjs(getIndexMetadata()?.lastBuilt).format('h:mm:ss a')}`
                    : 'Index ready',
                value: 'index-status',
                color: loading ? 'info' : 'success',
              }}
              icon={loading ? <IconRefresh size={12} /> : <IconCircleFilled />}
            />
          </Box>
        </DialogContent>
      </Dialog>
    )
  )
}

export default SearchModal
