import { useContext, useEffect, useMemo, useState } from 'react'

import { GitHub } from '@mui/icons-material'
import { Alert, Box, Chip, Typography } from '@mui/material'
import {
  RiAddLine,
  RiAdminLine,
  RiFacebookFill,
  RiLinkedinFill,
  RiPencilLine,
  RiTwitterXFill,
} from '@remixicon/react'
import { IconBubbleText, IconCopy } from '@tabler/icons-react'
import toast from 'react-hot-toast'

import { navigate, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ActionCreateDialog from 'src/components/Actions/ActionCreateDialog/ActionCreateDialog'
import BaseChip from 'src/components/Chips/BaseChip'
import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import ContextEditSidebar from 'src/components/ContextEditSidebar/ContextEditSidebar'
import OrganizationChip from 'src/components/Organizations/OrganizationChip/OrganizationChip'
import PersonAbout from 'src/components/People/PersonAbout/PersonAbout'
import PersonEnrichmentReview from 'src/components/People/PersonEnrichmentReview/PersonEnrichmentReview'
import PersonSlack from 'src/components/People/PersonSlack/PersonSlack'
import OpportunityCreateDialog from 'src/components/Pipeline/OpportunityCreateDialog/OpportunityCreateDialog'
import PrivateToYou from 'src/components/PrivateToYou/PrivateToYou'
import Row from 'src/components/Row/Row'
import SidebarActions from 'src/components/Sidebar/SidebarActions/SidebarActions'
import SlackLogo from 'src/components/Slack/SlackLogo/SlackLogo'
import ThreadCreateButton from 'src/components/Threads/ThreadCreateButton/ThreadCreateButton'
import {
  extractEmailDomain,
  NativeObjectFormatters,
} from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { isInternalUser } from 'src/lib/gates'
import { deepMerge, NativeObjectTypes } from 'src/lib/objects'

import SidebarContactAbout from '../SidebarContactAbout/SidebarContactAbout'
import SidebarEmailHistory from '../SidebarEmailHistory/SidebarEmailHistory'
import SidebarMeetingHistory from '../SidebarMeetingHistory/SidebarMeetingHistory'
import SidebarNotes from '../SidebarNotes/SidebarNotes'
import SidebarOrganization from '../SidebarOrganization/SidebarOrganization'
import SidebarPages from '../SidebarPages/SidebarPages'
import SidebarSectionExpand from '../SidebarSectionExpand/SidebarSectionExpand'
import {
  actionButtonStyle,
  sidebarBodyTextStyle,
  socialIconStyle,
} from '../styles'

const GET_PERSON_BY_EMAIL_FOR_SIDEBAR = gql`
  query GetPersonByEmailForSidebar($email: String!, $workspaceId: String) {
    getPerson(email: $email, workspaceId: $workspaceId) {
      id
      email
      firstName
      lastName
      fullName
      photoUrl
      canonicalEmail
      currentCompanyName
      currentJobTitle
      currentJobStartDate
      linkedInUrl
      socialTwitter
      socialFacebook
      pastEmails
      photoUrl
      skills
      languages
      interests
      careerSummary
      location
      city
      state
      country
      postalCode
      latitude
      longitude
      workExperience {
        companyName
        jobTitle
        startDate
        endDate
        description
      }
      education {
        schoolName
        schoolPhotoUrl
        degree
        fieldOfStudy
        startDate
        endDate
      }
      certifications {
        name
        issuer
        date
        url
      }
      verificationStatus {
        verified
      }
      relationship {
        oneSentence
        short
        long
      }
      actions {
        id
        title
        updatedAt
        owner {
          email
          id
        }
        channel {
          id
          label
          type
          accountId
        }
        source {
          id
          label
          type
        }
        priority
      }
    }
  }
`

const GET_PAGES_FOR_PERSON = gql`
  query GetPagesForPerson($email: String!) {
    pagesWithPerson(email: $email) {
      id
      title
      emoji
      madeExternalAt
      updatedAt
    }
  }
`

const GET_SLACK_USER_SIDEBAR = gql`
  query GetSlackUserSidebar($email: String!, $workspaceId: String) {
    getSlackUser(email: $email, workspaceId: $workspaceId) {
      id
      email
      slackUserId
      slackIntegration {
        id
        slackTeamId
        slackChannels {
          id
          slackChannelId
          name
          summary
        }
      }
    }
  }
`

// const GET_ORGANIZATION_FOR_PERSON_SIDEBAR = gql`
//   query GetOrganizationForPersonSidebar(
//     $domain: String!
//     $workspaceId: String!
//   ) {
//     workspaceOrganization(domain: $domain, workspaceId: $workspaceId) {
//       id
//       name
//       photos {
//         square
//       }
//       colors {
//         colorVibrant
//       }
//       about {
//         aiDescription
//         description
//         employeesFrom
//         employeesTo
//       }
//       relationship {
//         highLevelSummary
//         oneSentenceSummary
//         proofOfPayment
//       }
//     }
//   }
// `

const SidebarLayoutContact = ({ crmObject, setOpen, showAdmin = true }) => {
  const { currentUser } = useAuth()
  const { selectedWorkspace, internalDomains } = useContext(DayContext)
  const [mergedCrmObject, setMergedCrmObject] = useState(crmObject)
  const [noteCreate, setNoteCreate] = useState(null)
  const [opportunityCreatePerson, setOpportunityCreatePerson] = useState(null)
  const [expandedSummary, setExpandedSummary] = useState(false)
  const [verified, setVerified] = useState(false)
  const [enrichmentReviewOpen, setEnrichmentReviewOpen] = useState(false)

  const { data: personData, refetch: refetchPerson } = useQuery(
    GET_PERSON_BY_EMAIL_FOR_SIDEBAR,
    {
      variables: {
        email: crmObject?.objectId,
        workspaceId: selectedWorkspace,
      },

      skip: !crmObject?.objectId || !selectedWorkspace,
      pollInterval: verified ? 10000 : 5000,
      onCompleted: ({ getPerson }) => {
        setMergedCrmObject((prev) => ({
          ...prev,
          properties: deepMerge(prev.properties, getPerson),
        }))
      },
    }
  )

  // const domain = extractEmailDomain(crmObject?.objectId)
  // const { data: organizationData } = useQuery(
  //   GET_ORGANIZATION_FOR_PERSON_SIDEBAR,
  //   {
  //     variables: {
  //       domain,
  //       workspaceId: selectedWorkspace,
  //     },
  //     skip: !crmObject?.objectId || !selectedWorkspace || !domain,
  //   }
  // )

  const { data: slackUser } = useQuery(GET_SLACK_USER_SIDEBAR, {
    variables: {
      email: crmObject?.objectId,
      workspaceId: selectedWorkspace,
      skip: !crmObject?.objectId || !selectedWorkspace,
    },
  })

  const person = personData?.getPerson || crmObject?.properties

  if (!verified && person?.verificationStatus?.verified === 'verified') {
    setVerified(true)
  }

  const { data: pagesData } = useQuery(GET_PAGES_FOR_PERSON, {
    variables: {
      email: crmObject?.objectId,
    },
  })

  const refreshPerson = () => {
    refetchPerson()
  }

  useEffect(() => {
    if (crmObject && !mergedCrmObject) {
      setMergedCrmObject(crmObject)
    }
  }, [crmObject])

  const xUrl =
    person?.socialTwitter ||
    crmObject?.properties?.socialTwitter ||
    crmObject?.properties?.twitterUrl
  const linkedInUrl = person?.linkedInUrl || crmObject?.properties?.linkedInUrl
  const facebookUrl =
    person?.socialFacebook ||
    crmObject?.properties?.socialFacebook ||
    crmObject?.properties?.facebookUrl
  const githubUrl =
    person?.socialGithub ||
    crmObject?.properties?.socialGithub ||
    crmObject?.properties?.githubUrl
  const slack = slackUser?.getSlackUser?.slackUserId
    ? {
        channels: slackUser?.getSlackUser?.slackIntegration?.slackChannels,
        userId: slackUser?.getSlackUser?.slackUserId,
        teamId: slackUser?.getSlackUser?.slackIntegration?.slackTeamId,
      }
    : null

  const focus = crmObject?.properties?.sidebarFocus

  const isUnenriched = useMemo(() => {
    return (
      person &&
      !person.currentCompanyName &&
      !person.currentJobTitle &&
      !person.fullName
    )
  }, [person])

  return (
    <Box
      key={`${crmObject?.objectId || 'personSidebar'}_sidebar`}
      sx={{
        height: '100%',
        overflowY: 'auto',
      }}
    >
      {showAdmin && (
        <Row
          sx={{
            justifyContent: 'space-between',
            alignItems: 'top',
            py: 1,
            px: 2,
          }}
        >
          <Row
            gap={2}
            sx={{ flexShrink: 1, pt: 2 }}
          >
            <ContactAvatar
              email={crmObject?.objectId}
              size={64}
              borderRadius={100}
              showVerification={true}
              showCompany={true}
            />
            <Box
              sx={{
                width: '100%',
                flexShrink: 1,
              }}
            >
              <Row>
                <Typography
                  variant="h1"
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    flexShrink: 1,
                    fontSize: '1.5rem',
                  }}
                >
                  {person?.fullName ||
                    NativeObjectFormatters[NativeObjectTypes.Contact].label(
                      crmObject
                    )}
                </Typography>
                {(xUrl || linkedInUrl || facebookUrl || githubUrl || slack) && (
                  <Row
                    gap={'4px'}
                    sx={{ ml: 1 }}
                  >
                    {linkedInUrl && (
                      <Chip
                        variant="filled"
                        label=""
                        sx={{ ...socialIconStyle, background: '#0077B5' }}
                        icon={<RiLinkedinFill size={18} />}
                        clickable={true}
                        onClick={() => {
                          const url = linkedInUrl.startsWith('http')
                            ? linkedInUrl
                            : `https://${linkedInUrl}`
                          window.open(url, '_blank')
                        }}
                      />
                    )}
                    {xUrl && (
                      <Chip
                        variant="filled"
                        label=""
                        icon={
                          <RiTwitterXFill
                            style={{ color: 'white' }}
                            size={13}
                          />
                        }
                        clickable={true}
                        onClick={() => {
                          window.open(xUrl, '_blank')
                        }}
                        sx={{ ...socialIconStyle, background: '#000000' }}
                      />
                    )}
                    {facebookUrl && (
                      <Chip
                        variant="filled"
                        label=""
                        icon={
                          <RiFacebookFill
                            style={{ color: 'white' }}
                            size={15}
                          />
                        }
                        clickable={true}
                        onClick={() => {
                          window.open(facebookUrl, '_blank')
                        }}
                        sx={{ ...socialIconStyle, background: '#4267B2' }}
                      />
                    )}
                    {githubUrl && (
                      <Chip
                        variant="filled"
                        label=""
                        icon={<GitHub sx={{ color: 'white' }} />}
                        clickable={true}
                        onClick={() => {
                          window.open(githubUrl, '_blank')
                        }}
                        sx={{ ...socialIconStyle, background: '#333333' }}
                      />
                    )}
                    {slack && (
                      <Chip
                        variant="filled"
                        label=""
                        icon={
                          <Row sx={{ justifyContent: 'center' }}>
                            <SlackLogo size={18} />
                          </Row>
                        }
                        clickable={true}
                        onClick={() => {
                          const url = `slack://user?team=${slack.teamId}&id=${slack.userId}`
                          window.open(url, '_blank')
                        }}
                        sx={{ ...socialIconStyle, background: 'white' }}
                      />
                    )}
                  </Row>
                )}
              </Row>
              {enrichmentReviewOpen && (
                <PersonEnrichmentReview
                  email={crmObject?.objectId}
                  onClose={() => {
                    refetchPerson()
                    setEnrichmentReviewOpen(false)
                  }}
                />
              )}
              <Row>
                {isUnenriched ? (
                  <Chip
                    icon={<RiPencilLine />}
                    label="Edit & Enrich"
                    variant="outlined"
                    size="small"
                    sx={actionButtonStyle}
                    onClick={() => {
                      setEnrichmentReviewOpen(true)
                    }}
                  />
                ) : (
                  <Typography
                    sx={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      flexShrink: 1,
                      fontWeight: 500,
                      fontSize: '1.0rem',
                      letterSpacing: '-0.6px',
                    }}
                  >
                    {person?.currentJobTitle ||
                      NativeObjectFormatters[
                        NativeObjectTypes.Contact
                      ].subtitle(crmObject)}
                  </Typography>
                )}
              </Row>
            </Box>
          </Row>
        </Row>
      )}
      <Row
        gap={1}
        sx={{ pt: 1, pb: 2, pl: 2, flexWrap: 'wrap' }}
      >
        <Chip
          icon={<IconBubbleText size={16} />}
          label="Add context"
          variant="outlined"
          size="small"
          sx={actionButtonStyle}
          onClick={() => {
            setNoteCreate({
              parentReferenceKey: `${NativeObjectTypes.Person} : ${crmObject?.objectId} : root`,
              new: true,
            })
          }}
        />
        <ActionCreateDialog
          variant="button"
          orgs={[extractEmailDomain(crmObject?.objectId)].filter(Boolean)}
          people={[mergedCrmObject?.objectId]}
          oppIds={[]}
          workspaceId={selectedWorkspace}
          onUpdate={refreshPerson}
        />
        <Chip
          clickable={true}
          size="small"
          icon={<RiAddLine size={14} />}
          label="Opportunity"
          variant="outlined"
          sx={actionButtonStyle}
          onClick={() => {
            setOpportunityCreatePerson(crmObject)
          }}
        />
        {opportunityCreatePerson?.objectId && (
          <OpportunityCreateDialog
            person={opportunityCreatePerson}
            onClose={() => {
              setOpportunityCreatePerson(null)
              refetchPerson()
            }}
          />
        )}
        {internalDomains.includes(
          extractEmailDomain(crmObject?.objectId)
        ) ? null : (
          <OrganizationChip
            domain={extractEmailDomain(crmObject?.objectId)}
            size="small"
            sx={actionButtonStyle}
          />
        )}
        {noteCreate && (
          <ContextEditSidebar
            title={crmObject?.properties?.fullName || crmObject?.objectId}
            context={noteCreate}
            onClose={() => {
              setNoteCreate(null)
              refetchPerson()
            }}
            onInit={() => {}}
            onUpdate={() => {
              refetchPerson()
            }}
          />
        )}
        {slack?.channels?.length > 0 &&
          slack?.channels?.length < 3 &&
          slack?.channels?.map((channel) => (
            <Chip
              key={channel.slackChannelId}
              icon={<SlackLogo size={12} />}
              label={`#${channel.name}`}
              variant="outlined"
              size="small"
              sx={actionButtonStyle}
              onClick={() => {
                const url = `slack://channel?id=${channel.slackChannelId}&team=${slack.teamId}`
                window.open(url, '_blank')
              }}
            />
          ))}

        {isInternalUser(currentUser) && (
          <Chip
            label="Admin"
            icon={<RiAdminLine size={12} />}
            clickable={true}
            onClick={() => {
              navigate(routes.adminPerson({ email: person?.email }))
            }}
            variant="outlined"
            size="small"
            sx={actionButtonStyle}
          />
        )}
        <ThreadCreateButton
          sourceObject={crmObject}
          otherObjects={[]}
          workspaceId={selectedWorkspace}
          title={`Questions about ${person?.fullName || crmObject?.objectId}`}
          welcomeMessage={`👋 What do you want to know about ${person?.fullName || crmObject?.objectId}?`}
          onThreadCreated={() => {
            setOpen(false)
          }}
        />
        {!isUnenriched && (
          <Chip
            label="Edit & Enrich"
            icon={<RiPencilLine size={12} />}
            clickable={true}
            onClick={() => {
              setEnrichmentReviewOpen(true)
            }}
            variant="outlined"
            size="small"
            sx={actionButtonStyle}
          />
        )}
      </Row>

      <Box sx={{ height: 'calc(100vh - 152px)', overflowY: 'auto' }}>
        {person?.relationship?.oneSentence && (
          <SidebarSectionExpand
            title="Status"
            isOpen={!focus && person?.relationship?.oneSentence}
          >
            <>
              <Box sx={{}}>
                <Typography
                  sx={{
                    ...sidebarBodyTextStyle,
                    display: 'inline',
                  }}
                >
                  {expandedSummary
                    ? person?.relationship?.long || person?.relationship?.short
                    : person?.relationship?.oneSentence}
                </Typography>
                <Box
                  sx={{
                    display: 'inline',
                    fontWeight: 600,
                    ml: 1,
                    color: (theme) => theme.palette.secondary.main,
                    cursor: 'pointer',
                  }}
                  onClick={() => setExpandedSummary((prev) => !prev)}
                >
                  {expandedSummary ? 'less' : 'more'}
                </Box>
              </Box>

              {expandedSummary && (
                <Alert
                  severity="success"
                  sx={{
                    mt: 2,
                    fontSize: '0.7rem',
                    fontWeight: 500,
                  }}
                  icon={
                    <PrivateToYou
                      filled={true}
                      iconSize={20}
                    />
                  }
                >
                  Personal summary is private to you and not visible to others
                  in the workspace.
                </Alert>
              )}
            </>
          </SidebarSectionExpand>
        )}
        {person?.actions?.length > 0 && (
          <SidebarSectionExpand
            title="Actions"
            isOpen={focus === 'ACTIONS' || true}
          >
            <SidebarActions
              actions={person?.actions}
              onUpdate={refreshPerson}
            />
          </SidebarSectionExpand>
        )}
        <SidebarSectionExpand
          title="About"
          isOpen={
            !focus && person?.fullName && !person?.relationship?.oneSentence
          }
        >
          {person?.fullName ? (
            <PersonAbout person={person} />
          ) : (
            <SidebarContactAbout contact={mergedCrmObject} />
          )}
        </SidebarSectionExpand>
        {slack && (
          <SidebarSectionExpand
            title="Slack"
            isOpen={focus === 'SLACK'}
          >
            <PersonSlack slack={slack} />
          </SidebarSectionExpand>
        )}
        <SidebarSectionExpand
          title="Emails"
          isOpen={focus === 'EMAILS'}
          objectChip={
            <BaseChip
              label={mergedCrmObject?.objectId}
              icon={<IconCopy />}
              onClick={(e) => {
                e.stopPropagation()
                // copy to clipboard and toast:
                navigator.clipboard.writeText(mergedCrmObject?.objectId)
                toast(`Email copied to clipboard`, { icon: '📨' })
              }}
              sx={{
                border: 'none',
              }}
            />
          }
        >
          <SidebarEmailHistory
            email={mergedCrmObject?.objectId}
            workspaceId={selectedWorkspace}
          />
        </SidebarSectionExpand>

        <SidebarSectionExpand
          title="Meetings"
          isOpen={focus === 'MEETINGS'}
        >
          <SidebarMeetingHistory email={mergedCrmObject?.objectId} />
        </SidebarSectionExpand>
        <SidebarSectionExpand
          title="Context"
          isOpen={focus === 'NOTES'}
          sx={{
            '& .sidebar-section-expand-container': {
              px: 0,
              '& .sidebar-section-expand-title-row': {
                px: 2,
              },
            },
          }}
        >
          <SidebarNotes email={mergedCrmObject?.objectId} />
        </SidebarSectionExpand>

        <SidebarSectionExpand
          title="Pages"
          isOpen={focus === 'PAGES'}
          count={pagesData?.pagesWithPerson?.length}
        >
          <SidebarPages
            pages={pagesData?.pagesWithPerson}
            setOpen={setOpen}
          />
        </SidebarSectionExpand>
        {extractEmailDomain(mergedCrmObject?.objectId) && (
          <SidebarSectionExpand
            title="Organization"
            isOpen={focus === 'ORGANIZATION'}
          >
            <SidebarOrganization
              domain={
                mergedCrmObject?.properties?.domain ||
                extractEmailDomain(mergedCrmObject?.objectId)
              }
            />
          </SidebarSectionExpand>
        )}
      </Box>
    </Box>
  )
}

export default SidebarLayoutContact
