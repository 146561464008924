import { useContext, useEffect, useMemo, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { RiCloseLine, RiCurrencyLine } from '@remixicon/react'
import dayjs from 'dayjs'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'
import type { CRMObject, OpportunityCreateInput } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ContactTile from 'src/components/ContactTile/ContactTile'
import OpportunityFinder from 'src/components/Pipeline/OpportunityFinder/OpportunityFinder'
import PipelineChooser from 'src/components/Pipeline/PipelineChooser/PipelineChooser'
import Row from 'src/components/Row/Row'
import WorkspaceMemberSelect from 'src/components/WorkspaceMemberSelect/WorkspaceMemberSelect'
import {
  NativeObjectFormatters,
  extractEmailDomain,
} from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { getStoredPipelineId, setStoredPipelineId } from 'src/lib/localStorage'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

const GET_PIPELINE_TITLES_FOR_CHOOSER = gql`
  query GetPipelineTitlesForChooser($workspaceId: String!) {
    pipelineTitles(workspaceId: $workspaceId) {
      id
      title
      createdAt
    }
  }
`

const GET_PIPELINES_FOR_CHOOSER = gql`
  query GetPipelinesForChooser($workspaceId: String!) {
    pipelines(workspaceId: $workspaceId) {
      id
      workspaceId
      title
      hasRevenue
      type
      opportunityTypes
      stages {
        id
        title
        entranceCriteria
        likelihoodToClose
        type
        opportunities {
          id
          title
          type
          ownerId
          ownerEmail
          expectedCloseDate
          currentStatus
          expectedRevenue
          domain
          position
          organization {
            photoSquare
            name
          }
          primaryPerson {
            objectId
            objectType
            properties
          }
          createdAt
          updatedAt
        }
        position
      }
      ownerCoreContacts {
        firstName
        lastName
        email
        photo
      }
    }
  }
`

const CREATE_OPPORTUNITY_FOR_DIALOG = gql`
  mutation createOpportunityForDialog($input: OpportunityCreateInput!) {
    createOpportunity(input: $input) {
      id
      title
      type
      ownerEmail
      expectedCloseDate
      createdAt
      updatedAt
      currentStatus
      expectedRevenue
      domain
      position
    }
  }
`

const GET_ORGANIZATION_BY_DOMAIN = gql`
  query GetOrganizationByDomain($workspaceId: String!, $domain: String!) {
    workspaceOrganization(workspaceId: $workspaceId, domain: $domain) {
      id
      name
      domain
      photos {
        id
        square
      }
      roles {
        id
        email
        role
      }
    }
  }
`

const OpportunityCreateDialog = ({
  organization = null,
  person = null,
  onClose,
  pipelineId = null,
  stageId = null,
  domain = null,
  pipelines = [],
  refetch = () => {},
}) => {
  const { currentUser: user } = useAuth()
  const { selectedWorkspace, workspaces, workspaceOrganizations } =
    useContext(DayContext)
  const confirm = useConfirm()
  const [selectedPerson, setSelectedPerson] = useState<CRMObject | null>(null)
  const [selectedDomain, setSelectedDomain] = useState<string | null>(null)
  const [selectedOrganization, setSelectedOrganization] = useState(null)
  const [selectedPipeline, setSelectedPipeline] = useState(null)
  const [selectedStage, setSelectedStage] = useState(null)
  const [selectedOwner, setSelectedOwner] = useState(null)
  const [selectedExpectedRevenue, setSelectedExpectedRevenue] = useState(0)
  const [selectedTitle, setSelectedTitle] = useState('')
  const [selectedHasRevenue, setSelectedHasRevenue] = useState(null)
  const [selectedOppType, setSelectedOppType] = useState('')

  const [selectedExpectedCloseDate, setSelectedExpectedCloseDate] =
    useState<Date>()

  const { data: pipelineTitlesData } = useQuery(
    GET_PIPELINE_TITLES_FOR_CHOOSER,
    {
      variables: { workspaceId: selectedWorkspace },
      skip: pipelines.length > 0 || !selectedWorkspace,
    }
  )

  const { data: pipelinesData, loading: pipelinesLoading } = useQuery(
    GET_PIPELINES_FOR_CHOOSER,
    {
      variables: { workspaceId: selectedWorkspace },
      skip: !selectedWorkspace,
    }
  )

  const [getOrganizationForOppCreate] = useLazyQuery(GET_ORGANIZATION_BY_DOMAIN)

  const [createOpportunityForDialog] = useMutation(
    CREATE_OPPORTUNITY_FOR_DIALOG
  )

  const resetOppToCreate = () => {
    setSelectedOrganization(null)
    setSelectedPerson(null)
    setSelectedPipeline(null)
    setSelectedStage(null)
    setSelectedTitle('')
    setSelectedOppType('')
    setSelectedExpectedCloseDate(null)
    setSelectedExpectedRevenue(0)
    setSelectedHasRevenue(false)

    onClose()
  }

  const storedCurrentPipelineId = getStoredPipelineId(selectedWorkspace)

  const hasRevenue = useMemo(() => {
    if (selectedHasRevenue !== null) return selectedHasRevenue

    if (!selectedPipeline?.id) return null

    return pipelinesData?.pipelines?.find(
      (pipeline) => pipeline.id === selectedPipeline.id
    )?.hasRevenue
  }, [selectedPipeline, pipelinesData, selectedHasRevenue])

  useEffect(() => {
    if (!selectedOrganization) {
      if (organization) {
        setSelectedOrganization(organization)
      } else if (domain || selectedDomain) {
        const domainToUse = domain || selectedDomain
        getOrganizationForOppCreate({
          variables: {
            workspaceId: selectedWorkspace,
            domain: domainToUse,
          },
        }).then((data) => {
          setSelectedOrganization(data?.data?.workspaceOrganization)
        })
      }
    }

    if (!selectedPerson && person) {
      setSelectedPerson(person)
      const personDomain = extractEmailDomain(person?.objectId)
      if (personDomain) {
        setSelectedDomain(personDomain)
      }
      const fullName = NativeObjectFormatters[person.objectType].label(person)
      if (fullName) {
        setSelectedTitle(fullName)
      } else {
        setSelectedTitle(person.objectId)
      }

      if (!selectedExpectedCloseDate) {
        const offset = 60
        setSelectedExpectedCloseDate(dayjs().add(offset, 'days').toDate())
      }
    }

    if (selectedOrganization && selectedOrganization?.domain) {
      const name =
        selectedOrganization?.name ||
        selectedOrganization?.properties?.name ||
        selectedOrganization?.domain
      if (!selectedTitle) {
        setSelectedTitle(name)
      }
      if (!selectedDomain) {
        setSelectedDomain(selectedOrganization.domain)
      }

      if (!selectedExpectedCloseDate) {
        const offset =
          60 -
          (organization?.overview?.['status/recommendedStage']?.stageNumber ||
            0) *
            7
        setSelectedExpectedCloseDate(dayjs().add(offset, 'days').toDate())
      }

      if (!selectedOwner) {
        let suggestedOwnerMember
        const suggestedOwner = selectedOrganization?.overview?.[
          'objective/roles'
        ]?.find((role) => role.role.includes('OWNER_EMAIL'))

        if (suggestedOwner) {
          const workspace = workspaces?.find(
            (workspace) => workspace.id === selectedWorkspace
          )
          const workspaceMember = workspace?.members?.find(
            (member) => member.email === suggestedOwner.email
          )
          if (workspaceMember) {
            suggestedOwnerMember = workspaceMember
          }
        }
        if (!suggestedOwnerMember) {
          suggestedOwnerMember = user
        }

        setSelectedOwner(suggestedOwnerMember)
      }
    }

    if (!selectedDomain) {
      if (domain) setSelectedDomain(domain)
    }

    if (selectedDomain && !selectedOrganization) {
      const domainToUse = domain || selectedDomain
      getOrganizationForOppCreate({
        variables: {
          workspaceId: selectedWorkspace,
          domain: domainToUse,
        },
      }).then((data) => {
        setSelectedOrganization(data?.data?.workspaceOrganization)
      })
    }

    if (
      !selectedOwner &&
      selectedPerson &&
      !extractEmailDomain(selectedPerson?.objectId)
    ) {
      setSelectedOwner(user)
    }
  }, [
    organization,
    person,
    selectedPerson,
    selectedOrganization,
    domain,
    selectedDomain,
    selectedOwner,
    selectedTitle,
    selectedExpectedCloseDate,
    workspaces,
    workspaceOrganizations,
    user,
    selectedWorkspace,
    getOrganizationForOppCreate,
  ])

  useEffect(() => {
    if (!selectedPipeline) return

    const types = pipelinesData?.pipelines?.find(
      (pipeline) => pipeline.id === selectedPipeline.id
    )?.opportunityTypes

    if (types) {
      setSelectedOppType(types[0] || 'New Business')
    }
  }, [selectedPipeline, pipelinesData])

  const loadedPipelineTitles = useMemo(
    () => pipelineTitlesData?.pipelineTitles || [],
    [pipelineTitlesData]
  )
  const loadedPipelines = useMemo(
    () => pipelinesData?.pipelines || [],
    [pipelinesData]
  )

  const handleOwnerSelect = (selected) => {
    if (selected.length > 0) {
      const owner = selected[0]
      setSelectedOwner(owner)
    }
  }

  const handleOpportunityCreate = async () => {
    const currentStatus = ''

    const orgRoles = selectedOrganization?.roles || null
    const personRoles =
      orgRoles?.length > 0 && Array.isArray(orgRoles)
        ? orgRoles
            .filter((personRole) => !personRole.role.includes('OWNER_EMAIL'))
            .map((personRole) => ({
              personEmail: personRole.email,
              roles: personRole.role,
              reasoning: personRole.reasoningForRole,
            }))
        : selectedPerson
          ? [
              {
                personEmail: selectedPerson.objectId,
                roles: ['PRIMARY_CONTACT'],
                reasoning:
                  'Identified as primary contact in Opportunity creation process.',
              },
            ]
          : null

    const input: OpportunityCreateInput = {
      title: selectedTitle,
      type: selectedOppType,
      ownerEmail: selectedOwner.email,
      expectedCloseDate: selectedExpectedCloseDate.toISOString(),
      expectedRevenue: selectedExpectedRevenue,
      hasRevenue: !!selectedExpectedRevenue,
      domain: selectedOrganization
        ? selectedOrganization?.domain
        : extractEmailDomain(person?.objectId),
      position: 0,
      stageId: selectedStage.id,
      pipelineId: selectedPipeline.id,
      workspaceId: selectedWorkspace,
      primaryPerson: selectedPerson ? selectedPerson?.objectId : null,
      roles: personRoles,
      currentStatus,
    }

    await toast.promise(
      createOpportunityForDialog({
        variables: {
          input,
        },
      }),
      {
        loading: 'Creating opportunity...',
        success: () => {
          refetch()
          resetOppToCreate()
          onClose()
          if (pipelineId && pipelineId !== selectedPipeline.id) {
            setStoredPipelineId(selectedWorkspace, selectedPipeline.id)
            navigate(routes.opportunities())
          }
          return 'Opportunity created!'
        },
        error: (error) => {
          logger.dev({ error })
          return 'Error creating opportunity'
        },
      }
    )
  }

  const handleObjectSelect = (result) => {
    logger.dev({ result })
    const { domain, email } = result

    if (domain) {
      const org = workspaceOrganizations?.find((org) => org.domain === domain)

      if (org) {
        setSelectedOrganization(org)
      } else {
        getOrganizationForOppCreate({
          variables: {
            workspaceId: selectedWorkspace,
            domain,
          },
        }).then((data) => {
          setSelectedOrganization(data?.data?.workspaceOrganization)
        })
      }
    } else if (email) {
      logger.dev({ email })
      handlePersonSelect({
        objectId: email,
        objectType: NativeObjectTypes.Contact,
        properties: {
          email,
        },
      })
    }
  }

  useEffect(() => {
    if (!selectedPipeline || !selectedStage) {
      let pipeline
      if (
        pipelineId &&
        loadedPipelines?.find((pipeline) => pipeline.id === pipelineId)
      ) {
        pipeline = loadedPipelines?.find(
          (pipeline) => pipeline.id === pipelineId
        )
      } else if (storedCurrentPipelineId) {
        pipeline = loadedPipelines?.find(
          (pipeline) => pipeline.id === storedCurrentPipelineId
        )
      } else {
        pipeline = loadedPipelines?.[0]
      }

      if (pipeline) {
        setSelectedPipeline(pipeline)
        if (stageId) {
          const stage = pipeline?.stages?.find((stage) =>
            stage.id.includes(stageId)
          )
          if (stage) {
            setSelectedStage(stage)
          } else {
            if (selectedOrganization?.lifecycle) {
              const lifecycleOrgStageType =
                selectedOrganization?.lifecycle?.stageType
              const pipelineStage = pipeline?.stages?.find(
                (stage) => stage.type === lifecycleOrgStageType
              )
              setSelectedStage(pipelineStage || pipeline?.stages?.[0])
            }
          }
        }
      }
    }
  }, [
    pipelineId,
    loadedPipelineTitles,
    loadedPipelines,
    selectedPipeline,
    stageId,
    pipelinesData,
    selectedStage,
    selectedWorkspace,
    storedCurrentPipelineId,
  ])

  const handlePersonSelect = (selected) => {
    if (selected) {
      const person = selected
      if (person && !selectedPerson) {
        const personDomain = extractEmailDomain(person.objectId)
        if (personDomain) {
          setSelectedDomain(personDomain)
        } else {
          setSelectedPerson(person)
          if (!selectedTitle) {
            setSelectedTitle(
              NativeObjectFormatters[person.objectType].label(person)
            )
          }

          if (!selectedExpectedCloseDate) {
            setSelectedExpectedCloseDate(dayjs().add(60, 'days').toDate())
          }
        }
      }
    } else {
      setSelectedPerson(null)
    }
  }

  const handleStageSelect = (stage) => {
    setSelectedStage(stage)
  }

  logger.dev({ selectedStage })

  return selectedOrganization ||
    selectedPerson ||
    pipelineId ||
    domain ||
    hasRevenue ? (
    <Dialog
      open={
        !!selectedOrganization || !!selectedPerson || !!pipelineId || !!domain
      }
      onClose={resetOppToCreate}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>
        <Row sx={{ justifyContent: 'space-between' }}>
          <Typography
            variant="h6"
            className="MuiDialogTitle-root"
            sx={{ flexShrink: 1 }}
          >
            {'Create Opportunity'}
          </Typography>
        </Row>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 1, mt: 1 }}>
          <OpportunityFinder
            selectedOrg={selectedOrganization}
            selectedPerson={selectedPerson}
            onSelect={handleObjectSelect}
            pipelineType={selectedPipeline?.type}
          />
        </Box>
        {(selectedPerson || selectedOrganization) && (
          <Box sx={{ mt: 4 }}>
            <PipelineChooser
              selectedPipeline={selectedPipeline}
              onChangePipeline={setSelectedPipeline}
              selectedStage={selectedStage}
              organization={selectedOrganization}
              onChangeStage={handleStageSelect}
              pipelineTitles={loadedPipelineTitles}
              pipelines={loadedPipelines}
              loading={
                pipelinesLoading || !selectedPipeline?.id || !selectedStage?.id
              }
            />
            <Box sx={{ mt: 3 }}>
              {selectedOwner ? (
                <FormControl fullWidth={true}>
                  <InputLabel
                    sx={{ display: 'none' }}
                    id="owner"
                  >
                    {'Owner'}
                  </InputLabel>
                  <TextField
                    id="owner"
                    label="Owner"
                    fullWidth={true}
                    InputProps={{
                      sx: {
                        height: '52px',
                        width: '100%',
                        '& .MuiInputBase-input': {
                          display: 'none !important',
                        },
                      },
                      endAdornment: (
                        <IconButton
                          sx={{ borderRadius: '2px', p: '2px' }}
                          onClick={() => setSelectedOwner(null)}
                        >
                          <RiCloseLine />
                        </IconButton>
                      ),
                      startAdornment: (
                        <Box
                          sx={{
                            width: '504px',
                            flexShrink: 0,
                          }}
                        >
                          <ContactTile
                            showSidebar={false}
                            email={selectedOwner.email}
                          />
                        </Box>
                      ),
                    }}
                  />
                </FormControl>
              ) : (
                <WorkspaceMemberSelect
                  onSelect={(selected) => {
                    handleOwnerSelect(selected)
                  }}
                  label={'Owner'}
                  value={[]}
                />
              )}
            </Box>
            <TextField
              sx={{ mt: 3 }}
              label="Opportunity Name"
              fullWidth={true}
              value={selectedTitle}
              onChange={(e) => setSelectedTitle(e.target.value)}
            />

            <Autocomplete
              options={selectedPipeline?.opportunityTypes || []}
              value={selectedOppType}
              onChange={(e, newValue) => setSelectedOppType(newValue)}
              freeSolo={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ mt: 3 }}
                  label="Type"
                  fullWidth={true}
                  onChange={(e) => setSelectedOppType(e.target.value)}
                />
              )}
            />
            <Row sx={{ mt: 3 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Expected Close Date"
                  sx={{ width: '50%' }}
                  value={dayjs(selectedExpectedCloseDate)}
                  onChange={(e) => {
                    setSelectedExpectedCloseDate(dayjs(e).toDate())
                  }}
                />
              </LocalizationProvider>
              {hasRevenue ? (
                <Row>
                  <TextField
                    id="expectedRevenue"
                    label="Annual Revenue"
                    variant="outlined"
                    type="number"
                    fullWidth={true}
                    sx={{ mx: 1 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    value={selectedExpectedRevenue}
                    onChange={(e) => {
                      setSelectedExpectedRevenue(
                        e.target.value ? parseFloat(e.target.value) : ''
                      )
                    }}
                  />
                  <Tooltip
                    title="Remove revenue from pipeline"
                    placement="top"
                    arrow={true}
                  >
                    <IconButton
                      onClick={() => {
                        confirm({
                          title: 'Remove revenue from pipeline?',
                          confirmationText: 'Remove revenue',
                        }).then(() => {
                          setSelectedExpectedRevenue(0)
                          setSelectedHasRevenue(false)
                        })
                      }}
                    >
                      <RiCloseLine />
                    </IconButton>
                  </Tooltip>
                </Row>
              ) : (
                <Tooltip
                  title="Add revenue to pipeline"
                  placement="top"
                  arrow={true}
                >
                  <Button
                    onClick={() => {
                      confirm({
                        title: 'Add revenue to pipeline?',
                        confirmationText: 'Add revenue',
                      }).then(() => {
                        setSelectedHasRevenue(true)
                      })
                    }}
                    sx={{ ml: 1, width: '50%' }}
                  >
                    <RiCurrencyLine style={{ marginRight: '8px' }} />
                    Add revenue
                  </Button>
                </Tooltip>
              )}
            </Row>
          </Box>
        )}
      </DialogContent>
      {(selectedPerson || selectedOrganization) && (
        <DialogActions sx={{ p: 3, pt: 0 }}>
          <Button
            variant="outlined"
            color="secondary"
            fullWidth={true}
            onClick={handleOpportunityCreate}
            disabled={
              !selectedPipeline?.id ||
              !selectedStage?.id ||
              (!selectedOrganization && !selectedPerson)
            }
          >
            {'Create'}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  ) : (
    <></>
  )
}

export default OpportunityCreateDialog
