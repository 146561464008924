const possibleRoles = [
  {
    key: 'DECISION_MAKER',
    label: 'Decision Maker',
    showInList: false,
    showChip: false,
    color: 'success',
  },
  {
    key: 'PRIMARY_CONTACT',
    label: 'Primary Contact',
    showInList: true,
    showChip: true,
    color: 'success',
  },
  {
    key: 'CHAMPION',
    label: 'Champion',
    showInList: true,
    showChip: true,
    color: 'success',
  },
  {
    key: 'SUPPORTER',
    label: 'Supporter',
    showInList: true,
    showChip: true,
    color: 'success',
  },
  {
    key: 'DETRACTOR',
    label: 'Skeptic',
    showInList: true,
    showChip: true,
    color: 'error',
  },
  {
    key: 'OWNER_EMAIL',
    label: 'Owner',
    showInList: false,
    showChip: false,
  },
  {
    key: 'HURDLE',
    label: 'Hurdle',
    showInList: true,
    showChip: true,
    color: 'warning',
  },
  {
    key: 'ECONOMIC_BUYER',
    label: 'Economic Buyer',
    showInList: true,
    showChip: true,
    color: 'info',
  },
  {
    key: 'OTHER',
    label: 'Other',
    showInList: true,
    showChip: false,
    color: 'secondary',
  },
  {
    key: 'IGNORE',
    label: 'Ignore',
    showInList: false,
    showChip: false,
  },
  {
    key: 'DIRECT_BENEFIT',
    label: 'Benefits',
    showInList: true,
    showChip: true,
    color: 'success',
  },
]

export default possibleRoles
