import { useState } from 'react'

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { IconBolt, IconPlus } from '@tabler/icons-react'
import { toast } from 'react-hot-toast'
import type { CreateActionInput, CRMObject } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { actionButtonStyle } from 'src/components/Sidebar/styles'
import ObjectContextManager from 'src/components/Threads/ObjectContextManager/ObjectContextManager'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

const CREATE_ACTION_FROM_DIALOG = gql`
  mutation CreateActionFromDialog($input: CreateActionInput!) {
    createActionByUser(input: $input)
  }
`

const ActionTypes = {
  SUPPORT: { key: 'SUPPORT', label: 'Support' },
  FOLLOWUP: { key: 'FOLLOWUP', label: 'Follow Up' },
  MEETINGPREP: { key: 'MEETINGPREP', label: 'Meeting Prep' },
  FEATURE_REQUEST: { key: 'FEATURE_REQUEST', label: 'Feature Request' },
  OTHER: { key: 'OTHER', label: 'Other' },
} as const

const ActionPriorities = {
  LOW: { key: 'LOW', label: 'Low' },
  MEDIUM: { key: 'MEDIUM', label: 'Medium' },
  HIGH: { key: 'HIGH', label: 'High' },
  URGENT: { key: 'URGENT', label: 'Urgent' },
} as const

const ActionCreateDialog = ({
  workspaceId,
  variant,
  orgs,
  people,
  oppIds,
  onUpdate,
}: {
  workspaceId: string
  variant: 'icon' | 'button' | 'chip' | 'button-table'
  orgs?: string[]
  people?: string[]
  oppIds?: string[]
  onUpdate?: () => void
}) => {
  const [open, setOpen] = useState(false)
  const [input, setInput] = useState<Partial<CreateActionInput>>({
    workspaceId,
    people: people || [],
    domains: orgs || [],
    opportunityIds: oppIds || [],
    type: ActionTypes.FOLLOWUP.key,
    priority: ActionPriorities.MEDIUM.key,
  })

  const [createActionFromDialog] = useMutation(CREATE_ACTION_FROM_DIALOG, {
    refetchQueries: [
      'GetActionsForOrganizationSidebar',
      'GetActionsForOpportunitySidebar',
    ],
  })

  const handleInputChange = (field: keyof CreateActionInput, value: any) => {
    setInput((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const isValidAction = () => {
    return input?.title && input?.type
  }

  const createAction = () => {
    logger.dev(input)
    toast.promise(
      createActionFromDialog({
        variables: { input: input },
      }),
      {
        loading: 'Creating action...',
        success: () => {
          setOpen(false)
          setInput({
            workspaceId,
            type: ActionTypes.FOLLOWUP.key,
            priority: ActionPriorities.MEDIUM.key,
            people: [],
            domains: [],
            opportunityIds: [],
          })
          onUpdate?.()
          return 'Action created'
        },
        error: 'Failed to create action',
      }
    )
  }

  const handleUpdateContextObjects = (objects: Partial<CRMObject>[]) => {
    const people: string[] = []
    const domains: string[] = []
    const opportunityIds: string[] = []

    objects.forEach((obj) => {
      if (obj.objectType === NativeObjectTypes.Contact) {
        people.push(obj.objectId)
      } else if (obj.objectType === NativeObjectTypes.Organization) {
        domains.push(obj.objectId)
      } else if (obj.objectType === NativeObjectTypes.Opportunity) {
        opportunityIds.push(obj.objectId)
      }
    })

    setInput((prev) => ({
      ...prev,
      people,
      domains,
      opportunityIds,
    }))
  }

  const contextObjects = [
    ...(input.people || []).map((id) => ({
      objectType: NativeObjectTypes.Contact,
      objectId: id,
    })),
    ...(input.domains || []).map((id) => ({
      objectType: NativeObjectTypes.Organization,
      objectId: id,
    })),
    ...(input.opportunityIds || []).map((id) => ({
      objectType: NativeObjectTypes.Opportunity,
      objectId: id,
    })),
  ]

  return (
    <>
      {variant === 'icon' && (
        <Button
          onClick={() => setOpen(true)}
          startIcon={<IconBolt size={20} />}
          sx={actionButtonStyle}
        >
          Create Action
        </Button>
      )}
      {variant === 'button' && (
        <Chip
          clickable={true}
          size="small"
          variant="outlined"
          onClick={() => setOpen(true)}
          icon={<IconBolt size={14} />}
          sx={actionButtonStyle}
          label="Create Action"
        />
      )}
      {variant === 'chip' && (
        <Chip
          label="Create Action"
          onClick={() => setOpen(true)}
          icon={<IconBolt size={16} />}
          sx={{ cursor: 'pointer' }}
        />
      )}
      {variant === 'button-table' && (
        <Button
          onClick={() => setOpen(true)}
          startIcon={<IconPlus size={16} />}
          sx={{
            height: '40px !important',
            borderRadius: '3px !important',
            flexShrink: 0,
            fontSize: '12px !important',
            px: '12px !important',
            background: (theme) => theme.palette.background.paper,
            border: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          Create Action
        </Button>
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Create Action</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            required
            label="Title"
            value={input?.title ?? ''}
            onChange={(e) => handleInputChange('title', e.target.value)}
            sx={{ mb: 2, mt: 2 }}
          />

          <TextField
            fullWidth
            multiline
            rows={3}
            label="Description"
            value={input?.description ?? ''}
            onChange={(e) => handleInputChange('description', e.target.value)}
            sx={{ mb: 2 }}
          />

          <FormControl
            fullWidth
            sx={{ mb: 2 }}
          >
            <InputLabel>Type</InputLabel>
            <Select
              value={input?.type ?? ActionTypes.SUPPORT.key}
              label="Type"
              onChange={(e) => handleInputChange('type', e.target.value)}
            >
              {Object.values(ActionTypes).map((type) => (
                <MenuItem
                  key={type.key}
                  value={type.key}
                >
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Priority</InputLabel>
            <Select
              value={input?.priority ?? ActionPriorities.MEDIUM.key}
              label="Priority"
              onChange={(e) => handleInputChange('priority', e.target.value)}
            >
              {Object.values(ActionPriorities).map((priority) => (
                <MenuItem
                  key={priority.key}
                  value={priority.key}
                >
                  {priority.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ mt: 2 }}>
            <ObjectContextManager
              variant="new"
              contextObjects={contextObjects}
              onUpdateContextObjects={handleUpdateContextObjects}
              showStatus={false}
              buttonLabel="Add people, organizations, or opportunities"
              workspaceId={workspaceId}
              contextForObjectType={NativeObjectTypes.Action}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={createAction}
            variant="contained"
            disabled={!isValidAction()}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ActionCreateDialog
