import { useMemo, useState } from 'react'

import { Box, IconButton, Typography } from '@mui/material'
import { IconPencil } from '@tabler/icons-react'
import type { Action, WorkspaceUserContext } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'

import ContextEditSidebar from '../ContextEditSidebar/ContextEditSidebar'
import Row from '../Row/Row'
import WorkspaceMemberChip from '../WorkspaceMemberChip/WorkspaceMemberChip'

const GET_CONTEXT_FOR_ACTION = gql`
  query GetContextForAction($actionId: String!, $workspaceId: String!) {
    getContextForAction(actionId: $actionId, workspaceId: $workspaceId) {
      id
      plainTextValue
      createdAt
      userId
      parentReferenceKey
      updatedAt
      workspaceId
      referencedObjectIds
    }
  }
`

const InboxActionContext = ({ action }: { action: Action }) => {
  const { currentUser } = useAuth()
  const [selectedContext, setSelectedContext] =
    useState<WorkspaceUserContext | null>(null)
  const { data, refetch } = useQuery(GET_CONTEXT_FOR_ACTION, {
    variables: {
      actionId: action.id,
      workspaceId: action.workspaceId,
    },
    skip: !action.id || !action.workspaceId,
    onCompleted(data) {
      logger.dev({ data })
    },
  })

  const contextItems = useMemo(() => {
    return Array.isArray(data?.getContextForAction)
      ? [...(data?.getContextForAction || [])].sort((a, b) => {
          return dayjs(b.createdAt).diff(dayjs(a.createdAt))
        })
      : []
  }, [data])

  return (
    contextItems?.length > 0 && (
      <Box>
        {contextItems.map((item) => (
          <Row
            key={item.id}
            gap={3}
            sx={{
              width: '100%',
              alignItems: 'flex-start',
              mb: 2,
              '&:last-child': {
                mb: 0,
              },
            }}
          >
            <Typography
              sx={{ fontSize: '12px', letterSpacing: '-0.3px', opacity: 0.8 }}
            >
              <Box
                component="span"
                sx={{ mr: 1 }}
              >
                <WorkspaceMemberChip
                  userId={item.userId}
                  label={dayjs(item.createdAt).fromNow()}
                />
              </Box>
              {item.plainTextValue}

              <Box component="span">
                {item.userId === currentUser?.id && (
                  <IconButton
                    onClick={() => {
                      setSelectedContext(item)
                    }}
                    sx={{
                      p: '2px',
                      mt: '-2px',
                      display: 'inline-block',
                      borderRadius: '2px',
                    }}
                  >
                    <IconPencil
                      size={14}
                      stroke={1.5}
                    />
                  </IconButton>
                )}
              </Box>
            </Typography>
          </Row>
        ))}

        {selectedContext && (
          <ContextEditSidebar
            onClose={() => {
              setSelectedContext(null)
            }}
            onUpdate={() => {
              refetch()
            }}
            onInit={() => {}}
            context={selectedContext}
            title={action.title}
          />
        )}
      </Box>
    )
  )
}

export default InboxActionContext
