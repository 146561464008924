import { useEffect, useMemo, useRef, useState } from 'react'

import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  lighten,
} from '@mui/material'
import {
  RiAddLine,
  RiDraggable,
  RiPencilLine,
  RiSettings3Line,
} from '@remixicon/react'

import { logger } from 'src/lib/logger'

import Row from '../../Row/Row'
import OpportunityCreateDialog from '../OpportunityCreateDialog/OpportunityCreateDialog'
import { getStageColor } from '../Pipeline'

const PipelineBoardColumnHeader = ({
  column,
  // updateColumn,
  // deleteColumn,
  renameColumn,
  pipeline,
  refetch,
  onEdit,
}) => {
  const [editingTitle, setEditingTitle] = useState(false)
  const [title, setTitle] = useState(column.title)

  const [editingMetadata, _setEditingMetadata] = useState(false)
  // const [columnChanged, setColumnChanged] = useState(false)
  const [stageForCreate, setStageForCreate] = useState<string | null>(null)

  const inputRef = useRef(null)

  const handleTitleChange = (e) => {
    setTitle(e.target.value)
  }

  useEffect(() => {
    if (title && column.title && title !== column.title) {
      logger.info(
        `Renaming column ${column.id} from ${column.title} to ${title}`
      )
      renameColumn(column.id, title)
    }
  }, [title])

  useEffect(() => {
    if (!editingTitle) {
      setTitle(column.title)
    }
  }, [column.title])

  const oppsData = useMemo(() => {
    let total = 0
    let forecast = 0
    let count = 0
    const stage = pipeline.stages.find((stage) => stage.id === column.id)
    if (stage) {
      for (const opp of stage.opportunities) {
        total += opp.expectedRevenue
        forecast += opp.expectedRevenue * stage.likelihoodToClose
        count += 1
      }
    }
    return { total, forecast, count }
  }, [column.cards])

  const handleKeydown = (e) => {
    if (e.code === 'Enter') {
      setEditingTitle(false)
    }
  }

  // const roundStringToIntegerSafe = (str) => {
  //   const num = parseFloat(str)
  //   if (isNaN(num)) {
  //     console.error('Invalid input')
  //     return null // or throw an error, or return 0, depending on your needs
  //   }
  //   return Math.round(num)
  // }

  // const handleLikelihoodChange = (event) => {
  //   const value = roundStringToIntegerSafe(event.target.value) / 100
  //   setColumnDetails({
  //     ...columnDetails,
  //     likelihoodToClose: Number.isNaN(value) ? 0 : value,
  //   })
  //   setColumnChanged(true)
  // }

  // const handleSave = () => {
  //   updateColumn(column.id, columnDetails)
  //   setEditingMetadata(false)
  // }

  // const handleDeleteColumn = () => {
  //   if (column.cards.length > 0) {
  //     toast.error(
  //       "You can't delete a stage that contains opportunities. Please move or delete the opportunities first."
  //     )
  //     return
  //   }

  //   confirm({
  //     description:
  //       'Are you sure you want to delete this column? Type DELETE to confirm.',
  //     confirmationKeyword: 'DELETE',
  //   })
  //     .then(() => {
  //       deleteColumn(column.id)
  //     })
  //     .catch(() => {
  //       // Handle rejection here
  //     })
  // }

  useEffect(() => {
    if (inputRef.current && editingTitle) {
      inputRef.current.focus()
    }
  }, [editingTitle])

  return (
    <>
      <Box
        className={'react-kanban-column-header'}
        sx={{
          borderBottom: (theme) =>
            column.cards.length === 0
              ? `1px solid ${lighten(theme.palette.divider, 0.5)}`
              : 'none',
          pr: 1,
          width: '100%',
          '& .dragHandle .remixicon': {
            opacity: 0,
          },
          '& .editButton': {
            borderRadius: '4px',
            p: 0,
            ml: '4px',
            opacity: 0,
            transition: 'all 0.6s ease-in-out',
          },
          '& .column-title-container': {
            width: 'calc(100% - 24px)',
            transition: 'all 0.6s ease-in-out',
          },
          '&:hover': {
            '& .dragHandle': {
              backgroundColor: (theme) => theme.palette.background.paper,
              '& .remixicon': {
                opacity: 1,
              },
            },
            '& .editButton': {
              opacity: 1,
            },

            '& .column-title-container': {
              width: 'calc(100% - 48px)',
            },
          },
        }}
      >
        <Row sx={{ alignItems: 'flex-start' }}>
          <Row
            sx={{
              height: pipeline.hasRevenue ? '38px' : '22px',
              width: '8px',
              overflow: 'hidden',
              ml: '1px',
              mr: 1,
              mt: '5px',
              transition: 'all 0.3s ease-in-out',
              backgroundColor:
                typeof column?.position === 'number'
                  ? lighten(getStageColor(column?.position), 0.4)
                  : 'transparent',
              flexShrink: 0,
              borderRadius: '2px',
            }}
            className="dragHandle"
          >
            <RiDraggable
              size={16}
              style={{ flexShrink: 0, marginLeft: '-4px' }}
            />
          </Row>
          <Box sx={{ width: '100%' }}>
            <Row
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Row className="column-title-container">
                {editingTitle ? (
                  <TextField
                    value={title}
                    onChange={handleTitleChange}
                    onKeyDown={handleKeydown}
                    size="small"
                    fullWidth={true}
                    variant="standard"
                    onBlur={() => {
                      setEditingTitle(false)
                    }}
                    InputProps={{
                      endAdornment: (
                        <RiPencilLine
                          style={{ width: '18px', height: '18px' }}
                        />
                      ),
                      sx: {
                        width:
                          editingMetadata && !editingTitle ? '160px' : '100%',
                      },
                    }}
                    inputProps={{
                      ref: inputRef,
                    }}
                  />
                ) : (
                  <Typography
                    className="title"
                    sx={{
                      pt: '2px',
                      mb: '4px',
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => setEditingTitle(true)}
                  >
                    {title}
                  </Typography>
                )}
              </Row>
              {!editingTitle && (
                <Row sx={{ pb: '2px' }}>
                  <Tooltip
                    title="Create opportunity"
                    placement="top"
                    arrow={true}
                  >
                    <IconButton
                      className="editButton"
                      onClick={() => setStageForCreate(column.id)}
                    >
                      <RiAddLine size={20} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Edit stage"
                    placement="top"
                    arrow={true}
                  >
                    <IconButton
                      className="editButton"
                      onClick={() => onEdit(column.id)}
                    >
                      <RiSettings3Line size={20} />
                    </IconButton>
                  </Tooltip>
                </Row>
              )}
            </Row>

            {pipeline.hasRevenue && (
              <Row
                sx={{ m: 0, p: 0, mt: '-6px' }}
                gap={'4px'}
              >
                <Typography
                  sx={{
                    opacity: 0.7,
                    lineHeight: '130%',
                    fontSize: '12px',
                    letterSpacing: '-0.6px',
                  }}
                >
                  {`${oppsData.count} opps | $${Math.round(oppsData.forecast).toLocaleString()} forecast`}
                </Typography>
                <Typography
                  sx={{
                    opacity: 0.7,
                    lineHeight: '130%',
                    fontSize: '12px',
                    letterSpacing: '-0.6px',
                    display: 'none',
                  }}
                >
                  ${Math.round(oppsData.total).toLocaleString()} total
                </Typography>
                <Typography
                  sx={{
                    opacity: 0.7,
                    lineHeight: '130%',
                    fontSize: '12px',
                    letterSpacing: '-0.6px',
                  }}
                ></Typography>
              </Row>
            )}
          </Box>
        </Row>
      </Box>
      {stageForCreate && (
        <OpportunityCreateDialog
          onClose={() => setStageForCreate(null)}
          refetch={refetch}
          pipelineId={pipeline.id}
          stageId={stageForCreate}
        />
      )}
    </>
  )
}

export default PipelineBoardColumnHeader
