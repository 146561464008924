import { useContext } from 'react'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  lighten,
} from '@mui/material'
import {
  RiAddLine,
  RiArrowDownSLine,
  RiCloseLine,
  RiDeleteBinLine,
} from '@remixicon/react'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'
import type { StageUpdateInput } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeSuggestedPipelineStages } from 'src/lib/relationshipSummary'

import { getStageColor } from '../Pipeline'

/*
  input StageUpdateInput {
    id: String!
    workspaceId: String
    pipelineId: String
    position: Int
    description: String
    color: String
    activities: [String!]
    title: String
    entranceCriteria: [String!]
    likelihoodToClose: Float
    type: StageType
  }
*/

const DELETE_STAGE = gql`
  mutation DeleteStage($stageId: String!, $workspaceId: String!) {
    deleteStage(id: $stageId, workspaceId: $workspaceId) {
      id
    }
  }
`

const PipelineEditStage = ({
  stage,
  previousStage,
  onUpdate,
  openOnLoad,
  onDelete,
}: {
  stage: StageUpdateInput
  previousStage?: StageUpdateInput
  onUpdate: (stage: StageUpdateInput) => void
  openOnLoad?: boolean
  onDelete: () => void
}) => {
  const confirm = useConfirm()
  const { selectedWorkspace } = useContext(DayContext)

  const [deleteStage] = useMutation(DELETE_STAGE, {
    onCompleted: () => {
      logger.dev('Stage deleted')
    },
    onError: (error) => {
      logger.warn(`Error deleting stage: ${JSON.stringify(error)}`)
    },
  })

  const handleDeleteStage = async () => {
    try {
      await confirm({
        title: 'Delete stage',
        description: 'Are you sure you want to delete this stage?',
        confirmationText: 'Delete',
        cancellationText: 'Cancel',
      })
      await toast.promise(
        deleteStage({
          variables: { stageId: stage.id, workspaceId: selectedWorkspace },
        }),
        {
          loading: 'Deleting stage...',
          success: () => {
            onDelete()
            return 'Stage deleted'
          },
          error: 'Error deleting stage',
        }
      )
    } catch (error) {
      logger.warn('Error deleting stage:', error)
    }
  }

  const previousLikelihoodToClose = previousStage?.likelihoodToClose || 0

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({ ...stage, title: e.target.value })
  }

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({ ...stage, description: e.target.value })
  }
  // const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   onUpdate({ ...stage, color: e.target.value })
  // }
  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({ ...stage, type: e.target.value })
  }

  const isValidLikelihoodToClose = (value: number) => {
    return value >= 0 && value <= 1 && value >= previousLikelihoodToClose
  }
  const handleLikelihoodToCloseChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(e.target.value) || 0

    const floatValue = (value <= 100 ? value : 100) / 100
    onUpdate({ ...stage, likelihoodToClose: floatValue })
  }

  const handleRemoveEntranceCriteria = (index: number) => {
    onUpdate({
      ...stage,
      entranceCriteria: stage.entranceCriteria.filter((_, i) => i !== index),
    })
  }
  const handleUpdateEntranceCriteria = (index: number, value: string) => {
    onUpdate({
      ...stage,
      entranceCriteria: stage.entranceCriteria.map((criteria, i) =>
        i === index ? value : criteria
      ),
    })
  }

  const handleAddEntranceCriteria = () => {
    onUpdate({ ...stage, entranceCriteria: [...stage.entranceCriteria, ''] })
  }

  return (
    stage && (
      <Accordion
        elevation={0}
        sx={{
          '&:before': {
            display: stage?.position === 0 ? 'none' : 'block',
          },
          '& .Mui-expanded.MuiAccordionSummary-content h2': {
            color: (theme) => theme.palette.secondary.main,
          },
        }}
        defaultExpanded={openOnLoad}
      >
        <AccordionSummary expandIcon={<RiArrowDownSLine size={20} />}>
          <Typography variant="h2">{stage.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Row
            sx={{ flexDirection: 'column', width: '100%' }}
            gap={2}
          >
            <Row
              sx={{ width: '100%', alignItems: 'top', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1 }}
                >
                  Title
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Describe the stage in a word or two (or three)
                </Typography>
              </Box>
              <TextField
                value={stage.title}
                fullWidth={true}
                onChange={handleTitleChange}
              />
            </Row>
            <Row
              sx={{ width: '100%', alignItems: 'top', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography variant="h3">Description</Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Describe the state of the deal in this stage. Include any
                  details that may help Day.ai Assistant tailor recommendations
                  for opportunities in this stage.
                </Typography>
              </Box>
              <TextField
                value={stage.description}
                fullWidth={true}
                onChange={handleDescriptionChange}
                multiline={true}
                rows={4}
              />
            </Row>

            <Row
              sx={{ width: '100%', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1 }}
                >
                  Stage Type
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Setting the general stage of the process will help Day.ai
                  Assistant make better decisions and recommendations, as well
                  as accurately identify current paying customers.
                </Typography>
              </Box>
              <Select
                fullWidth={true}
                value={stage?.type || 'NONE'}
                onChange={handleTypeChange}
                error={!stage?.type}
                placeholder="Select a stage type"
              >
                {!stage?.type && (
                  <MenuItem value="NONE">Please select stage type ...</MenuItem>
                )}
                {Object.entries(NativeSuggestedPipelineStages)
                  .filter(([_key, value]) => value.showInChooser)
                  .map(
                    ([key, value]) =>
                      key != 'NONE' && (
                        <MenuItem
                          key={key}
                          value={key}
                        >
                          {value?.label}
                        </MenuItem>
                      )
                  )}
              </Select>
            </Row>
            <Row
              sx={{ width: '100%', alignItems: 'top', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography variant="h3">Likelihood to Close</Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  The likelihood that an opportunity will close in this stage.
                  Used in forecasting pipeline performance. Must be between 0
                  and 100, and greater than the previous stage.
                </Typography>
              </Box>
              <TextField
                value={parseInt(stage.likelihoodToClose * 100)}
                fullWidth={true}
                InputProps={{
                  endAdornment: '%',
                }}
                disabled={
                  stage.type === NativeSuggestedPipelineStages.CLOSED_LOST.key
                }
                onChange={handleLikelihoodToCloseChange}
                error={
                  stage.type === NativeSuggestedPipelineStages.CLOSED_LOST.key
                    ? false
                    : !isValidLikelihoodToClose(stage.likelihoodToClose)
                }
                helperText={
                  stage.type === NativeSuggestedPipelineStages.CLOSED_LOST.key
                    ? 'Closed lost stages are 0% likely to close, by definition'
                    : !isValidLikelihoodToClose(stage.likelihoodToClose)
                      ? `Must be between 0 and 100, and greater than the previous stage (${parseInt(
                          previousLikelihoodToClose * 100
                        )}%)`
                      : ''
                }
              />
            </Row>
            {false && (
              <Row
                sx={{ width: '100%', alignItems: 'top', mb: 3 }}
                gap={6}
              >
                <Box sx={{ width: '312px', flexShrink: 0 }}>
                  <Typography variant="h3">Color</Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    (Not currently editable)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundColor: lighten(
                      getStageColor(stage.position),
                      0.4
                    ),
                    width: '100%',
                    height: '16px',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
              </Row>
            )}

            <Row
              sx={{ width: '100%', mb: 3, alignItems: 'top' }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Row sx={{ justifyContent: 'space-between', mb: 1 }}>
                  <Typography variant="h3">Entrance Criteria</Typography>

                  <Button
                    variant="text"
                    size="small"
                    disableElevation={true}
                    startIcon={<RiAddLine size={16} />}
                    onClick={handleAddEntranceCriteria}
                  >
                    Add
                  </Button>
                </Row>
                <Typography sx={{ color: 'text.secondary' }}>
                  Add criteria (gates) for facts that need to be true in order
                  for an Opportunity to enter this stage. This will help team
                  members qualify stage progression properly, as well as enhance
                  Day.ai&apos;s ability to automate opportunity progression.
                </Typography>
              </Box>
              <Row
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
                gap={2}
              >
                {stage?.entranceCriteria?.length > 0 ? (
                  <>
                    {stage.entranceCriteria.map((activity, index) => (
                      <Row
                        key={`stage-${stage.id}-entrance-criteria-${index}`}
                        sx={{
                          justifyContent: 'space-between',
                          width: '100%',
                          mb: 2,
                        }}
                        gap={2}
                      >
                        <TextField
                          fullWidth={true}
                          key={`entrance-criteria-${index}`}
                          value={activity}
                          onChange={(e) =>
                            handleUpdateEntranceCriteria(index, e.target.value)
                          }
                        />
                        <IconButton
                          onClick={() => handleRemoveEntranceCriteria(index)}
                          sx={{ borderRadius: '3px' }}
                        >
                          <RiCloseLine size={24} />
                        </IconButton>
                      </Row>
                    ))}
                  </>
                ) : (
                  <Typography></Typography>
                )}
              </Row>
            </Row>
            <Row
              sx={{ width: '100%' }}
              gap={2}
            >
              <Typography variant="h3">Delete Stage</Typography>
              <Tooltip
                title={
                  ['CLOSED_LOST', 'CLOSED_WON'].includes(stage.type)
                    ? 'Cannot remove closed won/lost stages from the pipeline'
                    : ''
                }
                placement="top"
                arrow={true}
              >
                <span>
                  <IconButton
                    onClick={handleDeleteStage}
                    sx={{ mb: '3px' }}
                    disabled={['CLOSED_LOST', 'CLOSED_WON'].includes(
                      stage.type
                    )}
                  >
                    <RiDeleteBinLine size={20} />
                  </IconButton>
                </span>
              </Tooltip>
            </Row>
          </Row>
        </AccordionDetails>
      </Accordion>
    )
  )
}

export default PipelineEditStage
