import { logger } from './logger'
import type { SearchableObject } from './searchService'

export const RECENT_OBJECTS_KEY = 'recent_sidebar_objects'
export const MAX_RECENT_OBJECTS = 10

export const addToRecentObjects = (object: SearchableObject) => {
  try {
    if (!object?.objectId || !object?.objectType) return

    const recentObjects = JSON.parse(
      localStorage.getItem(RECENT_OBJECTS_KEY) || '[]'
    )

    // Remove any existing entry for this object
    const filteredObjects = recentObjects.filter(
      (obj: SearchableObject) =>
        !(
          obj.objectId === object.objectId &&
          obj.objectType === object.objectType
        )
    )

    // Add new object to front of array
    filteredObjects.unshift({
      objectId: object.objectId,
      objectType: object.objectType,
      properties: object.properties,
      label:
        object.properties?.name ||
        object.properties?.title ||
        object.properties?.domain ||
        object.objectId,
      description: object.properties?.description,
      lastViewed: new Date().toISOString(),
    })

    // Keep only the most recent N objects
    const trimmedObjects = filteredObjects.slice(0, MAX_RECENT_OBJECTS)

    localStorage.setItem(RECENT_OBJECTS_KEY, JSON.stringify(trimmedObjects))
  } catch (error) {
    logger.error('Error saving recent objects:', error)
  }
}

export const getRecentObjects = (): SearchableObject[] => {
  try {
    const recentObjects = JSON.parse(
      localStorage.getItem(RECENT_OBJECTS_KEY) || '[]'
    )
    return recentObjects
  } catch (error) {
    logger.error('Error getting recent objects:', error)
    return []
  }
}
