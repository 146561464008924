import { lighten } from '@mui/material'
const universalTextMargin = '40px'
export const editorStyle = {
  height: '100%',
  overflowY: 'auto',
  '.tiptap .is-empty:first-of-type::before': {
    color: '#adb5bd',
    content: 'attr(data-placeholder)',
    pointerEvents: 'none',
  },
  '& blockquote': {
    borderLeft: (theme) => `6px solid ${theme.palette.divider}`,
    ml: 2,
    p: 2,
    '& a, p a': {
      color: (theme) => theme.palette.secondary.main,
      cursor: 'pointer',
    },
    '& p': {
      lineHeight: '2.0',
    },
  },
  '& [contenteditable="true"]': {
    outline: 'none',
  },
  '& .tiptap > *': {
    px: universalTextMargin,
  },
  '& .tiptap .react-renderer': {
    px: 0,
  },
  '& .tiptap': {
    pb: '128px',
    '& .is-empty:first-of-type::before': {
      color: '#adb5bd',
      content: 'attr(data-placeholder)',
      float: 'left',
      height: 0,
      pointerEvents: 'none',
    },
    '& > p': {
      my: 2,
      lineHeight: '2.0',
      '& a': {
        color: (theme) => theme.palette.secondary.main,
        cursor: 'pointer',
      },
    },
    '& p': {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
      letterSpacing: '-0.10px',
      color: 'text.secondary',
      '& > strong': {
        fontWeight: 600,
      },
    },
    '& h1, h2': {
      mt: 3,
    },
    '& h1.title': {
      fontWeight: 600,
      fontSize: {
        xs: '1.5rem',
        sm: '1.6rem',
        md: '2.0rem',
        lg: '2.0rem',
        xl: '2.0rem',
      },
    },
    '& h1': {
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '130%',
      letterSpacing: '-1.5px',
    },
    '& h2': {
      fontWeight: 600,
      fontSize: '24px',
      letterSpacing: '-1.2px',
    },
    '& h3': {
      mt: 2,
      fontWeight: 600,
      fontSize: '18px',
      mb: 2,
      letterSpacing: '-0.9px',
    },
    '& h4': {
      mt: '4px',
      mb: 2,
      fontWeight: 500,
      fontSize: '16px',
      letterSpacing: '-0.2px',
      color: 'text.secondary',
    },
    '& ul': {
      listStyleType: 'disc',
    },
    '& ol': {
      listStyleType: 'decimal',
    },
    '& ul, ol': {
      p: 0,
      ml: 4,
      '& li': {
        my: 1,
        ml: 3,

        '& p': {
          lineHeight: '24px',
          p: 0,
          pl: '6px',
          m: 0,
          fontSize: '14px',
          '& a': {
            color: (theme) => theme.palette.secondary.main,
            cursor: 'pointer',
          },
        },
      },
      '& li::marker': {
        fontSize: '0.9rem',
      },
      '&[data-type=taskList]': {
        ml: 2,
        '& li': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          '& ul': {
            ml: 0,
          },

          '& li': {
            ml: '8px',
          },
          '& label': {
            '& input[type="checkbox"]': {
              mx: 0,
              transform: 'scale(1.1)',
            },
          },
          '& > div': { display: 'flex', flexDirection: 'column', ml: '4px' },
        },
      },
    },
    '& .node-clip': {
      display: 'inline-block',
    },
    '& table.day-ai-table-text': {
      width: '100%',
      borderCollapse: 'collapse',
      borderSpacing: 0,
      borderRadius: '6px',
      mt: 2,
      '& th, td': {
        border: (theme) => `1px solid ${theme.palette.divider}`,
        p: 1,
        textAlign: 'left',
        '& p': {
          p: 0,
          m: 0,
          ml: 1,
          lineHeight: '1.5rem',
        },
      },
      '& td': {
        fontSize: '0.8rem',
        color: (theme) => theme.palette.text.primary,
      },
      '& th': {
        color: (theme) => theme.palette.text.secondary,
        fontSize: '0.8rem',
        fontWeight: 500,
      },
    },
    '& iframe': {
      border: 'none',
      minWidth: '200px',
      minHeight: '200px',
      display: 'block',
      outline: '0px solid transparent',
    },
    '& .ProseMirror-selectednode': {
      iframe: {
        transition: 'outline 0.15s',
        outline: (theme) => `6px solid ${theme.palette.action.selected}`,
      },
      '& .day-ai-node': {
        border: `3px solid #B4D7FF`,
      },
    },
  },
}

export const tiptapButtonSx = {
  '&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeSmall': {
    borderRadius: '0px !important',
    minWidth: '22px',
    p: 1,

    color: (theme) => theme.palette.text.primary,
    '&:disabled': {
      color: (theme) => theme.palette.text.disabled,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.0rem',
    },
    '& .remixicon-icon, .remixicon, tabler-icon': {
      height: '16px',
      width: '16px',
    },
    '&.is-active': {
      background: (theme) => theme.palette.action.selected,
    },
  },
}

export const tiptapBubbleSx = {
  boxShadow: `
  0 1px 2px rgba(136, 161, 183, 0.07),
  0 2px 4px rgba(136, 161, 183, 0.07),
  0 4px 8px rgba(136, 161, 183, 0.07),
  0 8px 16px rgba(136, 161, 183, 0.07),
  0 16px 32px rgba(136, 161, 183, 0.07),
  0 32px 64px rgba(136, 161, 183, 0.07)
`,
  border: (theme) => `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  background: (theme) => theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'row',
}

export const tiptapFloaterSx = {
  boxShadow: `
  0 1px 2px rgba(136, 161, 183, 0.07),
  0 2px 4px rgba(136, 161, 183, 0.07),
  0 4px 8px rgba(136, 161, 183, 0.07),
  0 8px 16px rgba(136, 161, 183, 0.07),
  0 16px 32px rgba(136, 161, 183, 0.07),
  0 32px 64px rgba(136, 161, 183, 0.07)
`,
  border: (theme) => `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  background: (theme) => theme.palette.background.paper,
  '& .MuiListItemIcon-root': {
    minWidth: '24px',
  },

  '& .add-to-crm': {
    py: '4px',
    px: 1,
  },
}

export const nodeViewContainerSx = {
  border: () => `3px solid transparent`,
  borderRadius: '6px',
  height: '88px',
  overflow: 'hidden',
  width: '100%',
  cursor: 'pointer',
  pt: 2,
  pr: universalTextMargin,
  transition: 'all 0.25s linear',
  alignItems: 'flex-start',
  justifyContent: 'left',
  '&.editable:hover': {
    border: (theme) => `3px solid ${theme.palette.divider}`,

    cursor: 'grab',
    '&:hover .drag-handle .MuiSvgIcon-root, .drag-handle .remixicon-icon': {
      color: (theme) => theme.palette.text.secondary,
    },
    '& .drag-handle': {
      opacity: 1,
    },
  },
}

export const nodeDragHandleSx = {
  transition: 'all 0.4s linear',
  opacity: 0,
  px: '7px',
  overflow: 'hidden',
  flexShrink: 0,
  '& .MuiSvgIcon-root .remixicon-icon': {
    color: (theme) => lighten(theme.palette.text.secondary, 0.6),
  },
}

export const nodeTitleSx = {
  display: 'block',
  fontWeight: 600,
  letterSpacing: '-0.6px',
  fontSize: '1.0rem',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
}

export const nodeDescriptionSx = {
  display: 'block',
  fontSize: '11px !important',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
  letterSpacing: '-0.22px',
}

export const nodeTextDetailsContainerSx = {
  width: `calc(100%)`,
  overflow: 'hidden',
  flexShrink: 1,
  pl: 2,
}
