import { useState } from 'react'

import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import {
  RiKanbanView,
  RiQuestionAnswerLine,
  RiSettings4Line,
} from '@remixicon/react'
import toast from 'react-hot-toast'
import type { PipelineUpdateInput, StageUpdateInput } from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import Row from 'src/components/Row/Row'
import { logger } from 'src/lib/logger'
import { NativeSuggestedPipelineTypes } from 'src/lib/relationshipSummary'

import PipelineCreateInteractive from '../PipelineCreateInteractive/PipelineCreateInteractive'
import PipelineEditStage from '../PipelineEditStage/PipelineEditStage'

const GET_PIPELINE_FOR_EDIT = gql`
  query GetPipelineForEdit($id: String!, $workspaceId: String!) {
    pipeline(id: $id, workspaceId: $workspaceId) {
      id
      workspaceId
      title
      description
      type
      automationActive
      hasRevenue
      icp {
        metadata
        organization
        people
      }
      stages {
        id
        title
        description
        type
        position
        entranceCriteria
        activities
        likelihoodToClose
      }
    }
  }
`

const UPDATE_PIPELINE_FROM_EDIT = gql`
  mutation UpdatePipelineFromEdit($id: String!, $input: PipelineUpdateInput!) {
    updatePipeline(id: $id, input: $input) {
      id
    }
  }
`

// const CREATE_STAGE_FROM_EDIT = gql`
//   mutation createStage($input: StageCreateInput!) {
//     createStage(input: $input) {
//       id
//       title
//       workspaceId
//       pipelineId
//       position
//       entranceCriteria
//       likelihoodToClose
//       type
//       description
//       color
//       activities
//     }
//   }
// `

// gql for UPDATE_STAGE_FROM_BOARD:
const UPDATE_STAGE_FROM_EDIT = gql`
  mutation updateStage($input: StageUpdateInput!) {
    updateStage(input: $input) {
      id
      title
      workspaceId
      pipelineId
      position
      entranceCriteria
      likelihoodToClose
      type
      description
      color
      activities
    }
  }
`

const PipelineEdit = ({
  pipelineId,
  workspaceId,
  stageId,
  refetch,
}: {
  pipelineId: string
  workspaceId: string
  stageId: string | null
  refetch: () => void
}) => {
  const [pipeline, setPipeline] = useState<PipelineUpdateInput>()
  const [tab, setTab] = useState<'about' | 'stages' | 'interactive'>(
    stageId != 'pipeline' ? 'stages' : 'about'
  )
  const [stagesWithEdits, setStagesWithEdits] = useState<string[]>([])
  const [saveNeeded, setSaveNeeded] = useState(false)
  const { loading, refetch: refetchPipeline } = useQuery(
    GET_PIPELINE_FOR_EDIT,
    {
      variables: { id: pipelineId, workspaceId },
      skip: !pipelineId || !workspaceId,
      onCompleted: (data) => {
        logger.dev({ editData: data })
        if (!data?.pipeline) {
          return
        }
        const pipeline = { ...data.pipeline }

        delete pipeline.createdAt
        delete pipeline.updatedAt
        delete pipeline.__typename

        const stagesCopy = []
        for (const stage of pipeline.stages) {
          const stageCopy = { ...stage }
          delete stageCopy.createdAt
          delete stageCopy.updatedAt
          delete stageCopy.__typename
          stagesCopy.push(stageCopy)
        }
        pipeline.stages = stagesCopy

        if (!pipeline.type) {
          pipeline.type = 'NEW_CUSTOMER'
        }
        if (!pipeline.description) {
          pipeline.description = ''
        }
        if (!pipeline.automationActive) {
          pipeline.automationActive = false
        }
        if (!pipeline.title) {
          pipeline.title = ''
        }
        if (!pipeline.hasRevenue) {
          pipeline.hasRevenue = false
        }
        if (!pipeline.automationActive) {
          pipeline.automationActive = false
        }

        setPipeline(pipeline)
      },
    }
  )

  const [updatePipelineFromEdit] = useMutation(UPDATE_PIPELINE_FROM_EDIT)
  // const [createStage] = useMutation(CREATE_STAGE_FROM_EDIT)
  const [updateStage] = useMutation(UPDATE_STAGE_FROM_EDIT)

  // const handleCreateStage = async (stage: StageCreateInput) => {
  //   const result = await createStage({
  //     variables: { input: stage },
  //   })
  //   setSaveNeeded(true)
  // }

  const refetchAll = () => {
    refetchPipeline()
    refetch()
  }

  const handleSavePipeline = async () => {
    if (saveNeeded) {
      const pipelineToSave = JSON.parse(JSON.stringify(pipeline))
      if (pipelineToSave?.icp?.__typename) {
        delete pipelineToSave.icp.__typename
      }
      logger.dev({ pipelineToSave })
      await toast.promise(
        updatePipelineFromEdit({
          variables: {
            id: pipelineId,
            input: pipelineToSave,
          },
        }),
        {
          loading: 'Saving pipeline...',
          success: 'Pipeline saved!',
          error: 'Error saving pipeline',
        }
      )
    }
    for (const stageId of stagesWithEdits) {
      const stage = pipeline.stages.find((s) => s.id === stageId)
      if (stage) {
        await toast.promise(updateStage({ variables: { input: stage } }), {
          loading: 'Saving stage...',
          success: 'Stage saved!',
          error: 'Error saving stage',
        })
      }
    }
    refetch()
    setSaveNeeded(false)
    setStagesWithEdits([])
  }

  const handleUpdateFromStageEdit = (stage: StageUpdateInput) => {
    setPipeline((prev) => ({
      ...prev,
      stages: prev.stages.map((s) => (s.id === stage.id ? stage : s)),
    }))
    setStagesWithEdits((prev) => {
      const updatedStages = new Set([...prev, stage.id])
      return Array.from(updatedStages)
    })
  }

  const handleDeleteStage = () => {
    setPipeline((prev) => ({
      ...prev,
      stages: prev.stages.filter((s) => s.id !== stageId),
    }))
    refetch()
  }

  return pipeline ? (
    <Box sx={{ height: '100%', overflowY: 'auto' }}>
      <Row
        sx={{
          justifyContent: 'space-between',
          height: '56px',
          mb: 1,
        }}
      >
        <Tabs
          value={tab}
          onChange={(e, newValue) => setTab(newValue)}
          sx={{ m: 0, py: 0 }}
        >
          <Tab
            value="about"
            label="About"
            icon={<RiQuestionAnswerLine size={16} />}
            iconPosition="start"
          />
          <Tab
            icon={<RiKanbanView size={16} />}
            iconPosition="start"
            value="stages"
            label="Stages"
          />
          <Tab
            icon={<RiSettings4Line size={16} />}
            iconPosition="start"
            value="interactive"
            label="Interactive Setup"
          />
        </Tabs>
        <Button
          variant={
            saveNeeded || stagesWithEdits.length > 0 ? 'contained' : 'outlined'
          }
          color={
            saveNeeded || stagesWithEdits.length > 0 ? 'secondary' : 'primary'
          }
          disableElevation={true}
          onClick={handleSavePipeline}
          sx={{ maxHeight: '36px' }}
        >
          Save
        </Button>
      </Row>
      <Box sx={{ height: 'calc(100% - 64px)', overflowY: 'auto' }}>
        {tab === 'about' && (
          <Row
            sx={{ mt: 3, flexDirection: 'column', width: '100%' }}
            gap={2}
          >
            <Row
              sx={{ width: '100%', alignItems: 'top', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1 }}
                >
                  Title
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Describe the pipeline in a few words
                </Typography>
              </Box>
              <TextField
                fullWidth={true}
                value={pipeline?.title}
                onChange={(e) => {
                  setPipeline({ ...pipeline, title: e.target.value })
                  setSaveNeeded(true)
                }}
              />
            </Row>
            {pipeline?.icp && (
              <Row
                sx={{ width: '100%', mb: 3 }}
                gap={6}
              >
                <Box sx={{ width: '312px', flexShrink: 0 }}>
                  <Typography
                    variant="h3"
                    sx={{ mb: 1 }}
                  >
                    Target Segment (ICP)
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    Define the target segment (ICP) of people and organizations
                    that should be in this pipeline. This helps Day.ai better
                    automate actions, tailor documents, and provide
                    recommendations aligned with your specific pipeline
                    objectives.
                  </Typography>
                </Box>
                <TextField
                  fullWidth={true}
                  value={pipeline?.icp?.organization}
                  multiline={true}
                  rows={4}
                  onChange={(e) => {
                    setPipeline({
                      ...pipeline,
                      icp: {
                        ...pipeline.icp,
                        organization: e.target.value,
                      },
                    })
                    setSaveNeeded(true)
                  }}
                />
              </Row>
            )}
            <Row
              sx={{ width: '100%', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1 }}
                >
                  Pipeline Type
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Day.ai offers more tailored automation, documents, and
                  recommendations for different types of pipelines.
                </Typography>
              </Box>
              <Select
                fullWidth={true}
                label="Pipeline Type"
                value={pipeline?.type}
                onChange={(e) => {
                  setPipeline({ ...pipeline, type: e.target.value })
                  setSaveNeeded(true)
                }}
              >
                {Object.entries(NativeSuggestedPipelineTypes).map(
                  ([key, value]) =>
                    key != 'NONE' && (
                      <MenuItem
                        key={key}
                        value={key}
                      >
                        {value?.label}
                      </MenuItem>
                    )
                )}
              </Select>
            </Row>
            <Row
              sx={{ width: '100%', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1 }}
                >
                  Automation
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Automate opportunity movement between stages, based on
                  conversation (meeting/email) history and the entrance criteria
                  of the stages.
                </Typography>
              </Box>
              <Switch
                checked={pipeline.automationActive}
                onChange={(e) => {
                  setPipeline({
                    ...pipeline,
                    automationActive: e.target.checked,
                  })
                  setSaveNeeded(true)
                }}
              />
            </Row>
            <Row
              sx={{ width: '100%', mb: 3 }}
              gap={6}
            >
              <Box sx={{ width: '312px', flexShrink: 0 }}>
                <Typography
                  variant="h3"
                  sx={{ mb: 1 }}
                >
                  Track Revenue
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Track revenue opportunities in this pipeline.
                </Typography>
              </Box>
              <Switch
                checked={pipeline.hasRevenue}
                onChange={(e) => {
                  setPipeline({ ...pipeline, hasRevenue: e.target.checked })
                  setSaveNeeded(true)
                }}
              />
            </Row>
          </Row>
        )}
        {tab === 'stages' && (
          <>
            <Row sx={{ justifyContent: 'space-between', my: 3 }}>
              <Typography variant="h1">Stages</Typography>
              <Typography>To re-order stages, use the board view.</Typography>
            </Row>
            {pipeline?.stages?.length > 0 ? (
              <>
                {pipeline.stages.map((stage, index) => (
                  <PipelineEditStage
                    key={stage.id}
                    stage={stage}
                    previousStage={pipeline.stages?.[index - 1]}
                    onUpdate={handleUpdateFromStageEdit}
                    openOnLoad={stage.id === stageId}
                    onDelete={handleDeleteStage}
                  />
                ))}
              </>
            ) : null}
          </>
        )}
        {tab === 'interactive' && (
          <Box sx={{ height: '100%', width: '100%' }}>
            <PipelineCreateInteractive
              id={pipelineId}
              workspaceId={workspaceId}
              refetch={refetchAll}
            />
          </Box>
        )}
      </Box>
    </Box>
  ) : loading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <CircularProgress
        size={48}
        color="secondary"
      />
    </Box>
  ) : null
}

export default PipelineEdit
