import type { ReactNode } from 'react'
import {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react'

import type { Action, Pipeline } from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

const GET_WORKSPACE_PIPELINE = gql`
  query GetWorkspacePipeline($workspaceId: String!, $pipelineId: String!) {
    workspacePipeline(workspaceId: $workspaceId, id: $pipelineId) {
      id
      createdAt
      workspaceId
      title
      description
      hasRevenue
      automationActive
      updatedAt
      opportunityTypes
      ownerEmails
      setupSteps
      type
      isGeneric
      icp {
        organization
        metadata
        people
      }
      actions {
        id
        workspaceId
        createdAt
        updatedAt
        title
        reasoning
        type
        priority
        people
        organizations
        opportunityIds
        channel {
          id
          label
          type
          accountId
        }
        status {
          id
          label
          updatedAt
        }
        assignedAt
        owner {
          id
          email
        }
        draft {
          id
          title
          body
        }
        source {
          label
          type
          id
        }
        pipelineType
        timeframe {
          id
          dueDate
          reminderDate
          updatedAt
        }
      }
      stages {
        id
        title
        description
        entranceCriteria
        likelihoodToClose
        type
        updatedAt
        position
        opportunities {
          id
          workspaceId
          pipelineId
          title
          ownerEmail
          ownerId
          expectedCloseDate
          expectedRevenue
          domain
          position
          status
          daysInStage
          type
          updatedAt
          roles {
            personEmail
            roles
          }
          goals {
            content
            source {
              sourceId
              sourceType
            }
          }
          impactOfChange {
            content
            source {
              sourceId
              sourceType
            }
          }
          budgetAndTimeline {
            content
            source {
              sourceId
              sourceType
            }
          }
          challenges {
            challenge
            solution
            source {
              sourceId
              sourceType
            }
          }
          risks {
            content
            source {
              sourceId
              sourceType
            }
          }
          competition {
            content
            source {
              sourceId
              sourceType
            }
          }
          decisionProcess {
            content
            source {
              sourceId
              sourceType
            }
          }
        }
      }
    }
  }
`

const UPDATE_OPPORTUNITY_FROM_CONTEXT = gql`
  mutation UpdateOpportunityFromContext($input: OpportunityUpdateInput!) {
    updateOpportunity(input: $input) {
      id
    }
  }
`

const UPDATE_STAGE_FROM_CONTEXT = gql`
  mutation UpdateStageFromContext($input: StageUpdateInput!) {
    updateStage(input: $input) {
      id
      title
      workspaceId
      pipelineId
      position
      entranceCriteria
      likelihoodToClose
    }
  }
`

const CREATE_STAGE_FROM_CONTEXT = gql`
  mutation CreateStageFromContext($input: StageCreateInput!) {
    createStage(input: $input) {
      id
      title
      workspaceId
      pipelineId
      position
      entranceCriteria
      likelihoodToClose
    }
  }
`

interface PipelineContextType {
  workspaceId: string
  pipeline: Pipeline
  loading: boolean
  refetch: () => Promise<any>
  handleUpdate: (event: any) => void
  onEdit: (stageId: string) => void
  isDragging: boolean
  updateLoading: boolean
  setIsDragging: (isDragging: boolean) => void
  stageToEdit: string | null
  setStageToEdit: (stageId: string | null) => void
  actionsByOppId: Record<string, Action[]>
  lastRefetch: number
  lastUpdate: number
  filters: any
  setFilters: (filters: any) => void
  requiresSetup: boolean
}

const buildStageId = ({ workspaceId, stageId }) => {
  return `${workspaceId} : ${NativeObjectTypes.Stage} : ${stageId}`
}

const PipelineContext = createContext<PipelineContextType | undefined>(
  undefined
)

interface QueuedUpdate {
  type:
    | 'pipeline'
    | 'stage'
    | 'opportunity'
    | 'createStage'
    | 'approveOpportunity'
    | 'declineOpportunity'
  id: string
  input: any
  timestamp: number
}

export const PipelineProvider = ({
  children,
  workspaceId,
  pipelineId,
}: {
  children: ReactNode
  workspaceId: string
  pipelineId: string
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [stageToEdit, setStageToEdit] = useState<string | null>(null)

  const STORAGE_KEY = useMemo(
    () =>
      workspaceId && pipelineId
        ? `pipeline-filters-0.1-${workspaceId}-${pipelineId}`
        : null,
    [workspaceId, pipelineId]
  )
  const [filters, setFilters] = useState<any>()
  useEffect(() => {
    if (!STORAGE_KEY) return
    if (!filters) {
      const savedFilters = localStorage.getItem(STORAGE_KEY)
      const parsedFilters = savedFilters ? JSON.parse(savedFilters) : {}
      logger.dev('Saved filters', { savedFilters, parsedFilters })
      setFilters(
        parsedFilters?.view && parsedFilters?.owner
          ? parsedFilters
          : { view: 'board', owner: 'allOwners' }
      )
    } else {
      if (filters) localStorage.setItem(STORAGE_KEY, JSON.stringify(filters))
    }
  }, [STORAGE_KEY, filters])

  const updateQueue = useRef<QueuedUpdate[]>([])
  const isProcessing = useRef(false)
  const lastRefetch = useRef(0)
  const lastUpdatedAt = useRef(0)

  const [updateOpportunityFromContext, { loading: opportunityUpdateLoading }] =
    useMutation(UPDATE_OPPORTUNITY_FROM_CONTEXT)
  const [updateStageFromContext, { loading: stageUpdateLoading }] = useMutation(
    UPDATE_STAGE_FROM_CONTEXT
  )
  const [createStageFromContext, { loading: stageCreateLoading }] = useMutation(
    CREATE_STAGE_FROM_CONTEXT
  )

  const updateLoading = useMemo(() => {
    return opportunityUpdateLoading || stageUpdateLoading || stageCreateLoading
  }, [opportunityUpdateLoading, stageUpdateLoading, stageCreateLoading])

  const {
    data: pipelineData,
    loading: pipelineLoading,
    refetch,
  } = useQuery(GET_WORKSPACE_PIPELINE, {
    variables: {
      workspaceId,
      pipelineId,
    },
    skip: !workspaceId || !pipelineId,
    onCompleted: ({ workspacePipeline }) => {
      lastRefetch.current = Date.now()
      logger.dev('Pipeline fetched (onCompleted)', {
        lastRefetch: lastRefetch.current,
        updatedAt: workspacePipeline.updatedAt,
      })
    },
  })

  const loading = useMemo(() => {
    return pipelineLoading || updateLoading
  }, [pipelineLoading, updateLoading])

  const pipeline = useMemo(() => {
    lastRefetch.current = Date.now()
    logger.dev('Pipeline refetched (useMemo)', {
      lastRefetch: lastRefetch.current,
      updatedAt: pipelineData?.workspacePipeline?.updatedAt,
    })

    const stages = [...(pipelineData?.workspacePipeline?.stages ?? [])]
    stages.forEach((stage, index) => {
      logger.dev('Stage position', {
        stagePosition: stage.position,
        indexPosition: index,
      })
      if (stage.position !== index + 1) {
        logger.dev('Stage position mismatch', {
          stagePosition: stage.position,
          indexPosition: index + 1,
        })
      }
    })

    return pipelineData?.workspacePipeline
  }, [pipelineData])

  const requiresSetup = useMemo(() => {
    if (!pipeline) return false

    if (
      new Date(pipeline?.createdAt).getTime() < new Date('2024-12-12').getTime()
    ) {
      return false
    }

    const totalOpportunities = pipeline?.stages?.reduce(
      (acc, stage) => acc + stage.opportunities.length,
      0
    )
    const insufficientSteps = pipeline?.setupSteps?.reduce(
      (acc, step) => acc + (!step.sufficient ? 1 : 0),
      0
    )

    const partwayThroughSetup =
      pipeline.setupSteps?.length > 0 && insufficientSteps > 0

    const requires =
      (insufficientSteps > 0 || !pipeline.setupSteps) &&
      (partwayThroughSetup || totalOpportunities === 0)
    return requires
  }, [pipeline])

  const onEdit = useCallback((stageId: string) => {
    logger.dev('Editing stage:', stageId)
  }, [])

  const processUpdateQueue = useCallback(async () => {
    if (isProcessing.current || updateQueue.current.length === 0) return

    isProcessing.current = true
    lastUpdatedAt.current = Date.now()
    logger.dev(`Starting to process queue. Size: ${updateQueue.current.length}`)

    try {
      while (updateQueue.current.length > 0) {
        const update = updateQueue.current[0]
        logger.dev(
          `Processing update. Queue size: ${updateQueue.current.length}`,
          {
            type: update.type,
            id: update.id,
            timestamp: Date.now(),
            lastRefetch: lastRefetch.current,
            lastUpdate: lastUpdatedAt.current,
          }
        )

        switch (update.type) {
          case 'pipeline':
          case 'stage':
            await updateStageFromContext({
              variables: {
                id: update.id,
                input: update.input,
              },
            })
            break
          case 'createStage':
            await createStageFromContext({
              variables: {
                input: update.input,
              },
            })
            break
          case 'opportunity':
            logger.dev('Updating opportunity:', {
              update,
              timestamp: Date.now(),
              lastRefetch: lastRefetch.current,
              lastUpdate: lastUpdatedAt.current,
            })
            await updateOpportunityFromContext({
              variables: {
                input: update.input,
              },
            })
            break
        }

        updateQueue.current = updateQueue.current.slice(1)
        if (updateQueue.current.length === 0) {
          logger.dev('Queue empty, triggering refetch', {
            timestamp: Date.now(),
            lastRefetch: lastRefetch.current,
            lastUpdate: lastUpdatedAt.current,
          })
          refetch()
        }
      }
    } catch (error) {
      logger.warn('Error processing update:', error)
      if (updateQueue.current.length > 0) {
        updateQueue.current = updateQueue.current.slice(1)
        logger.dev('Removed failed update from queue')
      }
    } finally {
      isProcessing.current = false
      lastUpdatedAt.current = Date.now()
      logger.dev('Queue processing complete', {
        timestamp: Date.now(),
        lastRefetch: lastRefetch.current,
        lastUpdate: lastUpdatedAt.current,
      })
    }
  }, [
    updateOpportunityFromContext,
    createStageFromContext,
    updateStageFromContext,
    refetch,
  ])

  const queueUpdate = useCallback(
    (update: QueuedUpdate) => {
      updateQueue.current = [
        ...updateQueue.current,
        {
          ...update,
          timestamp: Date.now(),
        },
      ]
      logger.dev(
        `Update queued. New queue size: ${updateQueue.current.length}`,
        {
          type: update.type,
          id: update.id,
          isProcessing: isProcessing.current,
        }
      )

      if (!isProcessing.current) {
        processUpdateQueue()
      }
    },
    [processUpdateQueue]
  )

  const handleUpdate = useCallback(
    (event) => {
      logger.dev(`Board change: ${event.event}`)
      switch (event.event) {
        case 'columnAdd': {
          const createStageId = event.column.id
          const createStageInput = {
            id: createStageId,
            title: event.column.title,
            pipelineId,
            workspaceId,
            likelihoodToClose: event.column.likelihoodToClose,
            position: event.column.position,
          }
          logger.dev({ createStageInput, event })
          queueUpdate({
            type: 'createStage',
            id: createStageId,
            input: createStageInput,
            timestamp: Date.now(),
          })
          break
        }
        case 'columnRemove': {
          // Not supported - handled only from stage editor
          break
        }
        case 'columnRename': {
          const stageRenameStageId = buildStageId({
            workspaceId,
            stageId: event.column,
          })
          const stageRenameInput = {
            id: stageRenameStageId,
            title: event.title,
          }

          queueUpdate({
            type: 'stage',
            id: stageRenameStageId,
            input: stageRenameInput,
            timestamp: Date.now(),
          })
          break
        }
        case 'cardMove': {
          logger.dev({ event })
          const stageId = buildStageId({
            workspaceId,
            stageId: event.destination.toColumnId,
          })
          const opportunityMoveInput = {
            id: event.card.id,
            position: event.destination.toPosition,
            stageId,
            pipelineId,
            workspaceId,
          }

          queueUpdate({
            type: 'opportunity',
            id: event.card.id,
            input: opportunityMoveInput,
            timestamp: Date.now(),
          })
          break
        }
        case 'columnMove': {
          logger.dev('Column move:', event)

          const columnMoveStageId = buildStageId({
            workspaceId,
            stageId: event.stageId,
          })
          const columnMoveInput = {
            id: columnMoveStageId,
            position: event.destination.toPosition + 1,
          }
          queueUpdate({
            type: 'stage',
            id: columnMoveStageId,
            input: columnMoveInput,
            timestamp: Date.now(),
          })
          break
        }
      }
    },
    [pipelineId, workspaceId, queueUpdate]
  )

  const actionsByOppId = useMemo(() => {
    if (!pipeline) return {}
    const actionsByOppId = {}
    for (const action of pipeline.actions) {
      for (const opportunityId of action.opportunityIds) {
        actionsByOppId[opportunityId] = actionsByOppId[opportunityId]
          ? [...actionsByOppId[opportunityId], action]
          : [action]
      }
    }
    return actionsByOppId
  }, [pipeline])

  useEffect(() => {
    if (filters) {
      try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(filters))
      } catch (error) {
        logger.warn('Error saving filters to localStorage:', error)
      }
    }
  }, [filters, STORAGE_KEY])

  const value = useMemo(
    () => ({
      workspaceId,
      pipeline,
      loading,
      refetch,
      handleUpdate,
      requiresSetup,
      isDragging,
      setIsDragging,
      updateLoading,
      onEdit,
      stageToEdit,
      setStageToEdit,
      lastRefetch: lastRefetch.current,
      lastUpdate: lastUpdatedAt.current,
      actionsByOppId,
      filters,
      setFilters,
    }),
    [
      workspaceId,
      pipeline,
      loading,
      refetch,
      handleUpdate,
      requiresSetup,
      isDragging,
      setIsDragging,
      updateLoading,
      onEdit,
      stageToEdit,
      setStageToEdit,
      actionsByOppId,
      filters,
      setFilters,
      lastRefetch,
      lastUpdatedAt,
    ]
  )

  return (
    <PipelineContext.Provider value={value}>
      {children}
    </PipelineContext.Provider>
  )
}

export const usePipeline = () => {
  const context = useContext(PipelineContext)
  if (context === undefined) {
    throw new Error('usePipeline must be used within a PipelineProvider')
  }
  return context
}
