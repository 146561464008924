import { useContext, useState } from 'react'

import type { SxProps } from '@mui/material'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material'
import { RiBardLine } from '@remixicon/react'
import toast from 'react-hot-toast'
import { getDomain } from 'tldts'
import type { Opportunity } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

import { actionChipStyle } from '../Sidebar/styles'

const CREATE_PAGE_INSTRUCTIONS = gql`
  mutation CreatePageInstructions($input: CreatePageInstructionsInput!) {
    createPageInstructions(input: $input) {
      id
      contentJson
    }
  }
`

const OrganizationPageCreateInstructions = ({
  orgId,
  // opportunity,
  sx,
}: {
  orgId: string
  opportunity?: Opportunity
  sx?: SxProps
}) => {
  const { selectedWorkspace, setSidebarObject } = useContext(DayContext)
  const [open, setOpen] = useState(false)
  const [instructions, setInstructions] = useState('')

  const domain = getDomain(`http://${orgId}`)

  const [createPageInstructions] = useMutation(CREATE_PAGE_INSTRUCTIONS)

  const handleOpen = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const handleCreatePage = async () => {
    const input = {
      orgId,
      workspaceId: selectedWorkspace,
      instructions,
    }

    toast.promise(
      createPageInstructions({
        variables: {
          input,
        },
      }),
      {
        loading: 'Creating ...',
        success: (result) => {
          setSidebarObject(null)
          setOpen(false)
          logger.dev({ page: result.data.createPageInstructions })
          navigate(
            routes.pageDetail({ id: result.data.createPageInstructions.id })
          )
          return 'Page created successfully!'
        },
        error: 'Failed to create page',
      }
    )
  }

  return (
    domain && (
      <>
        <Chip
          size="small"
          variant="outlined"
          icon={<RiBardLine size={16} />}
          sx={{
            ...actionChipStyle,
            ...sx,
          }}
          onClick={(e) => {
            e.stopPropagation()
            handleOpen()
          }}
          label="Ask"
        />
        <Dialog
          open={open}
          onClose={onClose}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogContent sx={{}}>
            <TextField
              label="Ask anything, or request a document"
              fullWidth={true}
              value={instructions}
              multiline={true}
              rows={8}
              onChange={(e) => setInstructions(e.target.value)}
              sx={{ my: 1 }}
            />
          </DialogContent>
          <DialogActions sx={{ p: 3, pt: 0 }}>
            <Button
              fullWidth={true}
              variant="contained"
              color="secondary"
              onClick={handleCreatePage}
              disabled={instructions.length === 0}
              disableElevation={true}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  )
}

export default OrganizationPageCreateInstructions
