import { logger } from './logger'
import { NativeObjectTypes, type NativeObjectType } from './objects'
import { deepMerge } from './objects'

const DB_NAME = 'dayDB'
const DB_VERSION = 2

// interface WorkspacePerson {
//   workspaceId: string
//   email: string
//   data: any
//   lastUpdated: number
// }

// interface WorkspaceOrganization {
//   workspaceId: string
//   domain: string
//   data: any
//   lastUpdated: number
// }

const STORES = {
  PEOPLE: 'workspacePeople',
  ORGANIZATIONS: 'workspaceOrganizations',
  SEARCH_INDEX: 'searchIndex',
} as const

const SEARCH_INDEX_KEY = 'searchIndex'

export const initDb = async () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION)

    request.onerror = () => {
      logger.warn('Error opening IndexedDB')
      reject(request.error)
    }

    request.onsuccess = () => {
      resolve(request.result)
    }

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result

      // Create stores with compound indexes
      if (!db.objectStoreNames.contains(STORES.PEOPLE)) {
        const peopleStore = db.createObjectStore(STORES.PEOPLE, {
          keyPath: ['workspaceId', 'email'],
        })
        peopleStore.createIndex('byWorkspace', 'workspaceId')
        peopleStore.createIndex('lastUpdated', 'lastUpdated')
      }

      if (!db.objectStoreNames.contains(STORES.ORGANIZATIONS)) {
        const orgsStore = db.createObjectStore(STORES.ORGANIZATIONS, {
          keyPath: ['workspaceId', 'domain'],
        })
        orgsStore.createIndex('byWorkspace', 'workspaceId')
        orgsStore.createIndex('lastUpdated', 'lastUpdated')
      }

      if (!db.objectStoreNames.contains(STORES.SEARCH_INDEX)) {
        db.createObjectStore(STORES.SEARCH_INDEX)
      }
    }
  })
}

export const cacheWorkspacePeople = async (
  workspaceId: string,
  people: Record<string, any>
) => {
  try {
    const db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.PEOPLE, 'readwrite')
    const store = tx.objectStore(STORES.PEOPLE)
    const index = store.index('byWorkspace')

    // First get existing data
    const existingData = await new Promise<Record<string, any>>(
      (resolve, reject) => {
        const request = index.getAll(IDBKeyRange.only(workspaceId))
        request.onsuccess = () => {
          const existingPeople = request.result.reduce((acc, item) => {
            acc[item.email] = item.data
            return acc
          }, {})
          resolve(existingPeople)
        }
        request.onerror = () => reject(request.error)
      }
    )

    const timestamp = Date.now()
    const promises = Object.values(people).map((data) => {
      const email = data.email
      // Merge new data with existing data
      const mergedData = deepMerge(existingData[email], data)

      return new Promise((resolve, reject) => {
        const request = store.put({
          workspaceId,
          email,
          data: mergedData,
          lastUpdated: timestamp,
        })
        request.onsuccess = () => resolve(undefined)
        request.onerror = () => reject(request.error)
      })
    })

    await Promise.all(promises)
    await new Promise((resolve) => (tx.oncomplete = resolve))
  } catch (error) {
    logger.error('Error caching workspace people:', error)
  }
}

export const getWorkspacePeople = async (
  workspaceId: string
): Promise<Record<string, any>> => {
  try {
    const db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.PEOPLE, 'readonly')
    const store = tx.objectStore(STORES.PEOPLE)
    const index = store.index('byWorkspace')

    return new Promise((resolve, reject) => {
      const request = index.getAll(IDBKeyRange.only(workspaceId))

      request.onsuccess = () => {
        // Transform array into object keyed by email
        const people = {}
        for (const person of request.result) {
          people[person.data.email] = person.data
        }
        resolve(people)
      }

      request.onerror = () => reject(request.error)
    })
  } catch (error) {
    logger.error('Error getting workspace people from cache:', error)
    return {}
  }
}

export const cacheWorkspaceOrganizations = async (
  workspaceId: string,
  organizations: Record<string, any>
) => {
  try {
    const db = await initDb()
    const tx = (db as IDBDatabase).transaction(
      STORES.ORGANIZATIONS,
      'readwrite'
    )
    const store = tx.objectStore(STORES.ORGANIZATIONS)
    const index = store.index('byWorkspace')

    // First get existing data
    const existingData = await new Promise<Record<string, any>>(
      (resolve, reject) => {
        const request = index.getAll(IDBKeyRange.only(workspaceId))
        request.onsuccess = () => {
          const orgs = request.result.reduce((acc, item) => {
            acc[item.domain] = item.data
            return acc
          }, {})
          resolve(orgs)
        }
        request.onerror = () => reject(request.error)
      }
    )

    const timestamp = Date.now()
    const promises = Object.values(organizations).map((data) => {
      const domain = data.domain
      // Merge new data with existing data
      const mergedData = deepMerge(existingData[domain], data)

      return new Promise((resolve, reject) => {
        const request = store.put({
          workspaceId,
          domain,
          data: mergedData,
          lastUpdated: timestamp,
        })
        request.onsuccess = () => resolve(undefined)
        request.onerror = () => reject(request.error)
      })
    })

    await Promise.all(promises)
    await new Promise((resolve) => (tx.oncomplete = resolve))
  } catch (error) {
    logger.error('Error caching workspace organizations:', error)
  }
}

export const getWorkspaceOrganizations = async (
  workspaceId: string
): Promise<Record<string, any>> => {
  try {
    const db = await initDb()
    const tx = (db as IDBDatabase).transaction(STORES.ORGANIZATIONS, 'readonly')
    const store = tx.objectStore(STORES.ORGANIZATIONS)
    const index = store.index('byWorkspace')

    return new Promise((resolve, reject) => {
      const request = index.getAll(IDBKeyRange.only(workspaceId))

      request.onsuccess = () => {
        const orgs = request.result.reduce((acc, item) => {
          acc[item.domain] = item.data
          return acc
        }, {})
        resolve(orgs)
      }

      request.onerror = () => reject(request.error)
    })
  } catch (error) {
    logger.error('Error getting workspace organizations from cache:', error)
    return {}
  }
}

export interface ObjectMetadata {
  label: string
  avatarUrl?: string
  description?: string
  // ... other fields ...
}

export const getObjectMetadata = async ({
  workspaceId,
  objectType,
  objectId,
}: {
  workspaceId: string
  objectType: NativeObjectType
  objectId: string
}): Promise<ObjectMetadata> => {
  if (!workspaceId || !objectType || !objectId) {
    return {
      avatarUrl: null,
      label: null,
      description: null,
    }
  }

  try {
    // First try the object-specific stores for Person and Organization
    if (objectType === NativeObjectTypes.Organization) {
      const db = await initDb()
      const tx = (db as IDBDatabase).transaction(
        STORES.ORGANIZATIONS,
        'readonly'
      )
      const store = tx.objectStore(STORES.ORGANIZATIONS)

      return new Promise((resolve, reject) => {
        try {
          const request = store.get([workspaceId, objectId])

          request.onsuccess = () => {
            const org = request.result

            resolve({
              avatarUrl: org?.data?.photos?.square ?? null,
              label: org?.data?.name ?? null,
              description:
                (org?.data?.about?.description ||
                  org?.data?.about?.aiDescription) ??
                null,
            })
          }

          request.onerror = () => {
            logger.error('IndexedDB request error', request.error, {
              store: STORES.ORGANIZATIONS,
              workspaceId,
              objectId,
            })
            reject(request.error)
          }
        } catch (err) {
          const error = err as Error
          logger.error('Error executing IndexedDB get operation', error, {
            store: STORES.ORGANIZATIONS,
            workspaceId,
            objectId,
          })
          resolve({
            avatarUrl: null,
            label: null,
            description: null,
          })
        }
      })
    } else if (objectType === NativeObjectTypes.Contact) {
      const db = await initDb()
      const tx = (db as IDBDatabase).transaction(STORES.PEOPLE, 'readonly')
      const store = tx.objectStore(STORES.PEOPLE)

      return new Promise((resolve, reject) => {
        try {
          const request = store.get([workspaceId, objectId])

          request.onsuccess = () => {
            const person = request.result

            resolve({
              avatarUrl: person?.data?.photoUrl ?? null,
              label: person?.data?.fullName ?? null,
            })
          }

          request.onerror = () => {
            logger.error('IndexedDB request error', request.error, {
              store: STORES.PEOPLE,
              workspaceId,
              objectId,
            })
            reject(request.error)
          }
        } catch (err) {
          const error = err as Error
          logger.error('Error executing IndexedDB get operation', error, {
            store: STORES.PEOPLE,
            workspaceId,
            objectId,
          })
          resolve({
            avatarUrl: null,
            label: null,
          })
        }
      })
    } else {
      // For other object types, try the search index
      const db = await initDb()
      const tx = (db as IDBDatabase).transaction(
        STORES.SEARCH_INDEX,
        'readonly'
      )
      const store = tx.objectStore(STORES.SEARCH_INDEX)

      return new Promise((resolve, reject) => {
        try {
          const request = store.get(SEARCH_INDEX_KEY)

          request.onsuccess = () => {
            const searchObjects = request.result || []
            const object = searchObjects.find(
              (obj) =>
                obj.objectType === objectType && obj.objectId === objectId
            )

            if (object) {
              resolve({
                avatarUrl: object.photoUrl ?? null,
                label: object.label ?? null,
                description: object.description ?? null,
              })
            } else {
              resolve({
                avatarUrl: null,
                label: null,
                description: null,
              })
            }
          }

          request.onerror = () => {
            logger.error('IndexedDB request error', request.error, {
              store: STORES.SEARCH_INDEX,
              objectType,
              objectId,
            })
            reject(request.error)
          }
        } catch (err) {
          const error = err as Error
          logger.error('Error executing IndexedDB get operation', error, {
            store: STORES.SEARCH_INDEX,
            objectType,
            objectId,
          })
          resolve({
            avatarUrl: null,
            label: null,
            description: null,
          })
        }
      })
    }
  } catch (err) {
    const error = err as Error
    logger.error('Error getting object metadata from cache', error, {
      objectType,
      workspaceId,
      objectId,
    })
    return {
      avatarUrl: null,
      label: null,
      description: null,
    }
  }
}
