import type { ReactElement } from 'react'

import { Chip } from '@mui/material'

import { actionButtonStyle } from '../styles'

const SidebarButton = ({
  label,
  icon,
  onClick,
  disabled = false,
}: {
  label: string
  icon: ReactElement
  onClick: (e?: any) => void
  disabled?: boolean
}) => {
  return (
    <Chip
      variant="outlined"
      size="small"
      disabled={disabled}
      label={label}
      icon={icon}
      onClick={onClick}
      sx={actionButtonStyle}
    />
  )
}

export default SidebarButton
