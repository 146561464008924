import { useMemo } from 'react'

import { Box, ListItemText, Typography } from '@mui/material'
import { ListItemButton } from '@mui/material'
import type { Action } from 'types/graphql'

import { dayjs } from 'src/lib/dayjs'
import { NativeObjectTypes } from 'src/lib/objects'

import ActionPriorityChip from '../Actions/ActionPriorityChip/ActionPriorityChip'
import MultiObjectChip from '../Chips/MultiObjectChip/MultiObjectChip'
import Row from '../Row/Row'

import { useInbox } from './InboxContext'

const InboxListItem = ({ action }: { action: Action }) => {
  const { selectedAction, setSelectedAction } = useInbox()

  const crmObjects = useMemo(() => {
    const objects = {
      [NativeObjectTypes.Person]: [],
      [NativeObjectTypes.Organization]: [],
    }
    for (const person of action.people) {
      objects[NativeObjectTypes.Person].push({
        objectType: NativeObjectTypes.Person,
        objectId: person?.email,
      })
    }
    for (const organization of action.organizations) {
      objects[NativeObjectTypes.Organization].push({
        objectType: NativeObjectTypes.Organization,
        objectId: organization?.domain,
      })
    }
    return objects
  }, [action])

  return (
    <ListItemButton
      selected={selectedAction?.id === action.id}
      key={action.id}
      onClick={() => setSelectedAction(action)}
      className="action-item"
      sx={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        p: '12px',
        pl: '10px',
        height: '88px',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Row
          gap={1}
          sx={{ justifyContent: 'space-between', width: '100%' }}
        >
          <Row
            sx={{
              width: '224px',
              overflow: 'hidden',
            }}
          >
            {crmObjects[NativeObjectTypes.Person].length > 0 && (
              <MultiObjectChip
                crmObjects={crmObjects[NativeObjectTypes.Person]}
                workspaceId={action.workspaceId}
              />
            )}
            {crmObjects[NativeObjectTypes.Organization].length > 0 && (
              <MultiObjectChip
                crmObjects={crmObjects[NativeObjectTypes.Organization]}
                workspaceId={action.workspaceId}
              />
            )}
          </Row>

          <Row gap={0}>
            <Typography
              sx={{
                fontSize: '11px',
                letterSpacing: '-0.3px',
                lineHeight: '12px',
                opacity: 0.8,
                display: 'none',
              }}
            >
              {dayjs(action.createdAt).fromNow(true)}
            </Typography>
            <ActionPriorityChip
              action={action}
              variant="icon"
            />
          </Row>
        </Row>
      </Box>
      <ListItemText
        primary={''}
        primaryTypographyProps={{}}
        secondary={action.title}
        secondaryTypographyProps={{
          fontWeight: action.status?.id === 'UNREAD' ? 600 : 400,
          noWrap: false,
          sx: {
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            fontSize: '12px',
            letterSpacing: '-0.3px',
            lineHeight: '16px',
            opacity:
              action.status?.id === 'UNREAD'
                ? 1
                : ['COMPLETED', 'DISMISSED', 'REDUNDANT'].includes(
                      action.status?.id
                    )
                  ? 0.5
                  : 0.8,
          },
        }}
      />
    </ListItemButton>
  )
}

export default InboxListItem
