import { useContext } from 'react'

import { AccessTime, CalendarTodayOutlined } from '@mui/icons-material'
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  TableContainer,
  lighten,
  Alert,
} from '@mui/material'
import {
  RiCalendarCloseFill,
  RiCheckboxCircleFill,
  RiLoopRightFill,
} from '@remixicon/react'

import { Link } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { DayContext } from 'src/lib/dayContext'
import { formatEventTimeForTable } from 'src/lib/formatters'
import {
  recordingLink,
  videoSubtitle,
  videoTitle,
} from 'src/lib/meetingRecordingFormatting'
import { getAvatarEmails } from 'src/pages/LibraryPage/LibraryPage'

import AvatarRow from '../AvatarRow/AvatarRow'
import OrganizationChip from '../Organizations/OrganizationChip/OrganizationChip'
import Row from '../Row/Row'

export function calculateMeetingLength(meetingRecording) {
  if (!meetingRecording) return null
  const { startedAt, endedAt } = meetingRecording
  const duration =
    !!startedAt && !!endedAt
      ? (new Date(endedAt).getTime() - new Date(startedAt).getTime()) / 1000
      : null
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration - hours * 3600) / 60)
  const seconds = Math.floor(duration - hours * 3600 - minutes * 60)

  let result = ''
  if (hours > 0) result += `${hours}h `
  if (minutes > 0) result += `${minutes}m `
  if (hours === 0 && minutes === 0) result += `${seconds}s`

  return result
}

const MeetingRecordingsTable = ({ meetingRecordings, tab }) => {
  const { workAccounts, selectedWorkspace, workAccountWorkspaceConnections } =
    useContext(DayContext)
  const { currentUser: user } = useAuth()
  const allUserEmails = [
    user.email,
    ...workAccounts.map((account) => account.email),
  ]

  const recordingWorkspaceId = workAccountWorkspaceConnections?.some(
    ({ connectedWorkspaces }) => connectedWorkspaces.length > 0
  )
    ? selectedWorkspace
    : null

  return (
    <TableContainer sx={{}}>
      <Table
        aria-label="meeting recordings table"
        size="medium"
        sx={{
          border: 'none',
          '& .MuiTableRow-root': {
            cursor: 'pointer',
          },
          '& .MuiTableCell-root': {
            borderBottom: 'none',
            pr: 0,
          },
          tableLayout: 'fixed',
        }}
      >
        <TableBody sx={{ p: 0, border: 'none' }}>
          {meetingRecordings.map((video, index) => (
            <TableRow
              key={`meeting-recording-table-${index}`}
              hover={true}
              style={{ textDecoration: 'none', border: 'none' }}
            >
              <TableCell
                sx={{
                  flexGrow: 0,
                  width: tab === 'ready' ? '96px' : '80px',
                  px: 3,
                }}
              >
                <>
                  {tab != 'ready' ? (
                    <Alert
                      severity={video?.severity}
                      sx={{ width: '60px' }}
                      iconMapping={{
                        error: <RiCalendarCloseFill size={28} />,
                        warning: <RiCalendarCloseFill size={28} />,
                        info: <RiLoopRightFill size={28} />,
                        success: <RiCheckboxCircleFill size={28} />,
                      }}
                    />
                  ) : (
                    <>
                      {video.participants && (
                        <AvatarRow
                          max={3}
                          emails={getAvatarEmails(video, allUserEmails)}
                          showVerification={false}
                          showSidebar={true}
                          size={36}
                          borderRadius={50}
                          margin={'-16px'}
                          sx={{
                            pl: '8px',
                            justifyContent: 'left',
                            '& .MuiAvatarGroup-avatar': {
                              fontSize: '12px',
                              height: '36px',
                              width: '36px',
                              fontWeight: 600,
                              color: (theme) =>
                                lighten(theme.palette.text.secondary, 0.4),
                              background: 'transparent',
                            },
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              </TableCell>
              {/*<TableCell sx={{ width: '80px', alignItems: 'center', py: 0 }}>
                  {video.video?.private?.thumbnail?.status === 'READY' && (
                    <Box
                      component="img"
                      src={video?.video.private.thumbnail.url}
                      alt="thumbnail"
                      sx={{ width: '80px', borderRadius: 1 }}
                    />
                  )}
                  </TableCell>*/}
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                <Link
                  to={recordingLink({
                    workspaceId: recordingWorkspaceId,
                    meetingRecording: video,
                  })}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Box
                    sx={{
                      fontSize: '14px',
                      letterSpacing: '-0.2px',
                      fontWeight: 600,
                      mb: '4px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      lineHeight: '120%',
                    }}
                  >
                    {videoTitle(video)}
                  </Box>
                  <Box
                    sx={{
                      fontSize: '12px',
                      letterSpacing: '-0.2px',
                      fontWeight: 300,
                      textAlign: 'left',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      lineHeight: '120%',
                    }}
                  >
                    {videoSubtitle(video)}
                  </Box>
                </Link>
              </TableCell>

              {tab === 'ready' && (
                <TableCell
                  sx={{
                    fontSize: '0.7rem',
                    fontWeight: 600,
                    textAlign: 'right',
                    width: '284px',
                    flexGrow: 1,
                  }}
                  align="right"
                >
                  <Link
                    to={recordingLink({
                      workspaceId: recordingWorkspaceId,
                      meetingRecording: video,
                    })}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Row
                      sx={{ justifyContent: 'right' }}
                      gap={1}
                    >
                      {video.domains?.length > 0 &&
                        video.domains.map((domain, index) => (
                          <OrganizationChip
                            key={`meeting-recording-${video.id}-${domain}-${index}`}
                            domain={domain}
                            size="small"
                          />
                        ))}
                    </Row>
                  </Link>
                </TableCell>
              )}

              {tab === 'ready' && (
                <TableCell
                  sx={{
                    flexGrow: 0,
                    fontSize: '0.7rem',
                    fontWeight: 600,
                    width: '80px',
                  }}
                  align="right"
                >
                  <Link
                    to={recordingLink({
                      workspaceId: recordingWorkspaceId,
                      meetingRecording: video,
                    })}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Row sx={{ justifyContent: 'right' }}>
                      <AccessTime
                        sx={{
                          fontSize: '0.8rem',
                          mr: '4px',
                          color: (theme) =>
                            lighten(theme.palette.text.secondary, 0.5),
                        }}
                      />
                      {calculateMeetingLength(video)}
                    </Row>
                  </Link>
                </TableCell>
              )}
              <TableCell
                sx={{
                  flexGrow: 0,
                  fontSize: '0.7rem',
                  fontWeight: 600,
                  width: '80px',
                }}
                align="right"
              >
                <Link
                  to={recordingLink({
                    workspaceId: recordingWorkspaceId,
                    meetingRecording: video,
                  })}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  {formatEventTimeForTable(video.endedAt) && (
                    <Row sx={{ justifyContent: 'right' }}>
                      <CalendarTodayOutlined
                        sx={{
                          fontSize: '0.8rem',
                          mr: '4px',
                          color: (theme) =>
                            lighten(theme.palette.text.secondary, 0.5),
                        }}
                      />
                      {formatEventTimeForTable(video.endedAt)}
                    </Row>
                  )}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MeetingRecordingsTable
