export const getLocalSelectedPipelineStorageKey = (workspace: string) =>
  `selectedOpportunityPagePipeline_${workspace}`

export const getStoredPipelineId = (workspaceId: string) => {
  if (!workspaceId) return null
  const storedValue = localStorage.getItem(
    getLocalSelectedPipelineStorageKey(workspaceId)
  )
  if (storedValue === 'null' || storedValue === 'undefined' || !storedValue) {
    return null
  }
  return storedValue
}

export const setStoredPipelineId = (
  workspaceId: string,
  pipelineId: string
) => {
  if (!workspaceId || !pipelineId) return
  localStorage.setItem(
    getLocalSelectedPipelineStorageKey(workspaceId),
    pipelineId
  )
}

export const removeStoredPipelineId = (workspaceId: string) => {
  if (!workspaceId) return
  localStorage.removeItem(getLocalSelectedPipelineStorageKey(workspaceId))
}
