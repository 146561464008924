import { Chip } from '@mui/material'
import { IconCalendarBolt } from '@tabler/icons-react'
import type { Action } from 'types/graphql'

import { dayjs } from 'src/lib/dayjs'

const ActionTimeframeChip = ({ action }: { action: Action }) => {
  return dayjs(action.timeframe?.dueDate).isValid() ? (
    <Chip
      size="small"
      variant="outlined"
      icon={<IconCalendarBolt />}
      label={dayjs(action.timeframe?.dueDate).format('MMM D, YYYY')}
      sx={{
        border: 'none',
        justifyContent: 'flex-start',
        width: '100%',
        p: 0,
        m: 0,
        '&:hover': {
          backgroundColor: 'transparent !important',
        },
        '& .MuiChip-icon': {
          m: 0,
          height: '12px',
          width: '12px',
          flexShrink: 0,
          mt: '-1px',
        },
      }}
    />
  ) : null
}

export default ActionTimeframeChip
