import { IconMessageCircle } from '@tabler/icons-react'
import type { ActionType } from 'types/graphql'

export const completedStatuses = ['COMPLETED', 'DISMISSED', 'REDUNDANT']

export const typeLabels: Record<
  ActionType,
  { label: string; icon: React.ReactNode; pluralDescriptor: string }
> = {
  SUPPORT: {
    label: 'Support',
    icon: <IconMessageCircle size={14} />,
    pluralDescriptor: 'support actions',
  },
  FOLLOWUP: {
    label: 'Follow Ups',
    icon: <IconMessageCircle size={14} />,
    pluralDescriptor: 'follow ups',
  },
  MEETINGPREP: {
    label: 'Meeting Prep',
    icon: <IconMessageCircle size={14} />,
    pluralDescriptor: 'meeting prep',
  },
  FEATURE_REQUEST: {
    label: 'Feature Requests',
    icon: <IconMessageCircle size={14} />,
    pluralDescriptor: 'feature requests',
  },
  MEETING_RECORDING_FOLLOWUP: {
    label: 'Meeting Follow Ups',
    icon: <IconMessageCircle size={14} />,
    pluralDescriptor: 'meeting follow ups',
  },
  EMAIL_RESPONSE: {
    label: 'Email Responses',
    icon: <IconMessageCircle size={14} />,
    pluralDescriptor: 'email responses',
  },
  SCHEDULE_MEETING: {
    label: 'Schedule Meetings',
    icon: <IconMessageCircle size={14} />,
    pluralDescriptor: 'meetings to schedule',
  },
  OTHER: {
    label: 'Other',
    icon: <IconMessageCircle size={14} />,
    pluralDescriptor: 'other actions',
  },
  NUDGE: {
    label: 'Nudges',
    icon: <IconMessageCircle size={14} />,
    pluralDescriptor: 'nudges',
  },
}
