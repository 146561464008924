import { useMemo } from 'react'

import { Chip } from '@mui/material'
import { IconX } from '@tabler/icons-react'

import { AvatarCacheProvider } from '../AvatarCacheProvider/AvatarCacheProvider'
import Row from '../Row/Row'

interface BaseChipProps {
  label?: string
  avatar?: React.ReactElement
  icon?: React.ReactElement
  fullWidth?: boolean
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void | null
  onRemove?: () => void | null
  sx?: any
}

const defaults = {
  fullWidth: true,
  label: null,
  avatar: null,
  icon: null,
  onClick: null,
  onRemove: null,
  sx: {},
}

const BaseChip = ({
  label = defaults.label,
  avatar = defaults.avatar,
  icon = defaults.icon,
  onClick = defaults.onClick,
  onRemove = defaults.onRemove,
  fullWidth = defaults.fullWidth,
  sx = defaults.sx,
}: BaseChipProps) => {
  const containerSx = useMemo(() => {
    return {
      maxWidth: '256px',
      height: '24px',
      backgroundColor: 'transparent',
      justifyContent: 'flex-start',
      overflow: 'hidden',
      ...(fullWidth ? { width: '100%' } : {}),
      ...(!onClick ? { border: 'none' } : {}),

      '& .MuiChip-avatar, .domainAvatar, .personAvatarBox': {
        ml: '0px',
        mr: '0px',
        pl: '0px',
        height: '16px',
        width: '16px',
        flexShrink: 0,
      },

      '& .MuiChip-icon': {
        ml: 0,
        height: '14px',
        width: '14px',
        flexShrink: 0,
      },

      '& .MuiChip-deleteIcon': {
        color: (theme) => theme.palette.primary.main,
        opacity: 0.7,
        flexShrink: 0,
      },

      '&:hover': {
        ml: '0px',
        backgroundColor: 'transparent !important',

        '& .MuiChip-deleteIcon': {
          opacity: 1,
          '&:hover': {
            color: (theme) => theme.palette.primary.main,
          },
        },
      },
      '&.day-ai-clickable-chip': {
        backgroundColor: (theme) => theme.palette.background.paper,
        '& .MuiChip-avatar, .domainAvatar, .personAvatarBox': {
          ml: '2px',
          mr: '0px',
          height: '16px',
          width: '16px',
          flexShrink: 0,
        },
        '& .MuiChip-icon': {
          ml: '6px',
          height: '14px',
          width: '14px',
          flexShrink: 0,
        },
        '&:hover': {
          ml: '-0px',
          backgroundColor: (theme) => theme.palette.secondary.light,

          '& .MuiChip-avatar, .domainAvatar, .personAvatarBox, .MuiAvatarGroup-root.multi-object-avatar-group':
            {
              ml: '2px',
            },
          '& .MuiChip-icon': {
            ml: '6px',
          },
        },
      },
      ...sx,
    }
  }, [fullWidth, onClick, sx])

  return (
    <AvatarCacheProvider>
      <Chip
        size="small"
        variant="outlined"
        clickable={!!onClick}
        label={label}
        avatar={avatar}
        icon={icon && !avatar ? icon : null}
        className={onClick ? 'day-ai-clickable-chip' : ''}
        sx={containerSx}
        onClick={onClick}
        onDelete={onRemove}
        deleteIcon={
          onRemove ? (
            <Row sx={{ justifyContent: 'flex-end', flex: 1 }}>
              <IconX size={12} />
            </Row>
          ) : null
        }
      />
    </AvatarCacheProvider>
  )
}

export default BaseChip
