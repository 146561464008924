import { useCallback, useContext, useEffect, useMemo } from 'react'

import {
  Autocomplete,
  Box,
  Button,
  Chip,
  lighten,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'
import { LicenseInfo } from '@mui/x-license'
import { RiFlashlightLine } from '@remixicon/react'
import { IconThumbUp } from '@tabler/icons-react'
import { IconThumbDown } from '@tabler/icons-react'
import dayjs from 'dayjs'
import SortedDescendingIcon from 'remixicon-react/ArrowDownSLineIcon'
import SortedAscendingIcon from 'remixicon-react/ArrowUpSLineIcon'
import MoreActionsIcon from 'remixicon-react/More2LineIcon'

import { useAuth } from 'src/auth'
import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import ContactTile from 'src/components/ContactTile/ContactTile'
import { DataGridToolbar } from 'src/components/DataGrid/util'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import Row from 'src/components/Row/Row'
import StageChip from 'src/components/StageChip/StageChip'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import { shouldShowOpp } from '../Pipeline'

LicenseInfo.setLicenseKey(process.env.MUI_PREMIUM_LICENSE_KEY)

const UnsortedIcon = null

const cellStyle = {
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}

const PipelineTable = ({
  pipeline,
  opportunities,
  owners,
  filters,
  setTableFilters,
  onUpdate,
  onApproveGenericOpportunity,
  onDeclineGenericOpportunity,
  refetch,
}) => {
  const { currentUser: user } = useAuth()
  const { setSidebarObject } = useContext(DayContext)

  const apiRef = useGridApiRef()

  const memoizedRefetch = useCallback(refetch, [refetch])

  const openOppInSidebar = useCallback(
    (params) => {
      const crmObject = {
        objectType: NativeObjectTypes.Opportunity,
        objectId: params.row.id,
        properties: {
          ...params.row,
          opportunityTypes: pipeline?.opportunityTypes,
          refetch: memoizedRefetch,
        },
      }
      setSidebarObject(crmObject)
    },
    [pipeline, memoizedRefetch, setSidebarObject]
  )

  const processRowUpdate = (params) => {
    const input = {
      id: params.id,
      expectedCloseDate: params.expectedCloseDate,
      expectedRevenue: params.expectedRevenue,
      type: params.type,
    }
    onUpdate(input)
    return params
  }

  const processRowUpdateError = (params) => {
    logger.dev({ params })
  }

  const ownerColumn = useMemo(
    () => ({
      field: 'ownerEmail',
      headerName: 'Owner',
      width: 196,
      renderCell: (params) => {
        return (
          <Chip
            variant="outlined"
            sx={{
              fontSize: '0.7rem',
              fontWeight: 600,
              letterSpacing: '-0.2px',
              width: '100%',
              justifyContent: 'flex-start',
              '& .MuiChip-label': {
                pl: '4px',
              },
            }}
            label={
              <ContactTile
                email={params.row.ownerEmail}
                subtitle={false}
                size={24}
              />
            }
          />
        )
      },
    }),
    []
  )

  const revenueColumn = useMemo(
    () => ({
      field: 'expectedRevenue',
      headerName: 'Amount',
      width: 124,
      editable: true,
      type: 'number',
      valueGetter: (value, row) => row.expectedRevenue,
      renderCell: (params) => (
        <Tooltip
          title="Double click to update"
          arrow={true}
        >
          <Box sx={{ fontWeight: 600, cursor: 'pointer', fontSize: '0.7rem' }}>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(params.row.expectedRevenue)}
          </Box>
        </Tooltip>
      ),
    }),
    []
  )

  const columns = useMemo(
    () => [
      {
        field: 'expectedCloseDate',
        headerName: 'Close Date',
        width: 104,
        editable: true,
        type: 'date',
        valueGetter: (value, row) => dayjs(row.expectedCloseDate).toDate(),
        renderCell: (params) => (
          <Tooltip
            title="Double click to update"
            arrow={true}
          >
            <Box
              sx={{ fontWeight: 600, cursor: 'pointer', fontSize: '0.7rem' }}
            >
              {dayjs(params.row.expectedCloseDate).format('M/D/YY')}
            </Box>
          </Tooltip>
        ),
      },
      {
        field: 'title',
        headerName: 'Title',
        width: 190,
        renderCell: (params) => (
          <Chip
            variant="outlined"
            icon={
              params.row.domain ? (
                <DomainAvatar
                  domain={params.row.domain}
                  photoUrl={params.row.organization?.photos?.square}
                  size={24}
                  borderRadius={50}
                />
              ) : params.row?.primaryPerson ? (
                <ContactAvatar
                  email={params.row?.primaryPerson.objectId}
                  size={24}
                />
              ) : (
                <> </>
              )
            }
            label={params.row.title}
            sx={{
              fontSize: '0.7rem',
              fontWeight: 600,
              letterSpacing: '-0.2px',
              width: '100%',
              px: '4px',
              justifyContent: 'flex-start',
            }}
            onClick={(e) => {
              e.stopPropagation()
              openOppInSidebar(params)
            }}
          />
        ),
      },
      {
        field: 'currentStatus',
        headerName: 'Status',
        flex: 1,
        minWidth: 512,
        renderCell: (params) => (
          <Box
            onClick={(e) => {
              e.stopPropagation()
              openOppInSidebar(params)
            }}
            sx={{
              cursor: 'pointer',
              width: '100%',
              overflow: 'hidden',
              height: '100%',
              textWrap: 'wrap',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {params.row.isSuggested ? (
              <Row
                gap={1}
                sx={{ alignItems: 'center', height: '100%', width: '100%' }}
              >
                <Chip
                  size="small"
                  color="success"
                  label="Suggested Opportunity"
                />
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<IconThumbUp size={16} />}
                  sx={{
                    width: '48%',
                    height: '24px',
                    minHeight: '24px',
                    py: 0,
                    background: (theme) => theme.palette.background.paper,
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    e.nativeEvent.stopImmediatePropagation()
                    onApproveGenericOpportunity(params.row.id)
                  }}
                >
                  Approve
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<IconThumbDown size={16} />}
                  sx={{
                    width: '48%',
                    height: '24px',
                    minHeight: '24px',
                    py: 0,
                    background: (theme) => theme.palette.background.paper,
                  }}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    e.nativeEvent.stopImmediatePropagation()
                    onDeclineGenericOpportunity(params.row.id)
                  }}
                >
                  Decline
                </Button>
              </Row>
            ) : (
              <Row sx={{ alignItems: 'center', height: '100%' }}>
                <Typography
                  sx={{
                    fontSize: '0.7rem',
                    fontWeight: 400,
                    letterSpacing: '-0.2px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    WebkitLineClamp: 2,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    height: '30px',
                    textWrap: 'wrap',
                    lineHeight: '140%',
                  }}
                >
                  {params.row.currentStatus}
                </Typography>
              </Row>
            )}
          </Box>
        ),
      },
      {
        field: 'stage',
        headerName: 'Stage',
        width: 172,
        valueGetter: (value, row) => {
          return row.stagePosition
        },
        renderCell: (params) => {
          const stage = pipeline?.stages?.find(
            (s) => s.position === params.row.stagePosition
          )
          return (
            <StageChip
              stage={stage}
              allowChange={true}
              stages={pipeline?.stages}
              opportunityId={params.row.id}
              onUpdate={() => {
                refetch()
              }}
            />
          )
        },
      },
      {
        field: 'stageTitle',
        headerName: 'Stage',
        width: 172,
      },
      {
        field: 'daysInStage',
        headerName: 'Days in Stage',
        width: 112,
      },
      {
        field: 'type',
        headerName: 'Type',
        width: 188,
        editable: true,
        renderEditCell: (params) => {
          return (
            <Autocomplete
              value={params.value}
              fullWidth={true}
              options={pipeline?.opportunityTypes}
              freeSolo={true}
              onChange={(e, newValue) => {
                const { id, field } = params
                apiRef.current.setEditCellValue({
                  id,
                  field,
                  value: newValue,
                  debounceMs: 200,
                })
              }}
              renderInput={(autoCompleteParams) => (
                <TextField
                  fullWidth={true}
                  {...autoCompleteParams}
                />
              )}
            />
          )
        },
        renderCell: (params) =>
          params.row.type && (
            <Chip
              variant="outlined"
              sx={{
                ...cellStyle,
                fontSize: '0.7rem',
                width: '100%',
                justifyContent: 'flex-start',
              }}
              onClick={(e) => {
                e.stopPropagation()
              }}
              label={params.row.type}
            />
          ),
      },
      {
        field: 'nextMeeting',
        headerName: 'Next Meeting',
        width: 128,
        valueGetter: (value, row) => {
          try {
            const startTimestamp =
              row.organization?.relationship?.upcomingEvents?.[0]
                ?.startTimestamp

            if (startTimestamp) {
              const startDt = dayjs(parseInt(startTimestamp) * 1000)
              if (startDt.isAfter(dayjs())) {
                return startDt.toDate().getTime()
              }
            }
          } catch (e) {
            logger.dev(`Error parsing next meeting start timestamp: ${e}`)
          }

          return null
        },
        renderCell: ({ row, value }) => {
          if (!value) {
            return null
          }

          const startTime = dayjs(value)
          const title =
            row?.organization?.relationship?.upcomingEvents?.[0]?.title
          const minutesUntil = dayjs().diff(startTime, 'minutes') * -1

          let color
          if (minutesUntil <= 60 * 4) {
            color = 'success'
          } else if (minutesUntil <= 60 * 24) {
            color = 'info'
          } else if (minutesUntil <= 60 * 24 * 7) {
            color = 'warning'
          } else {
            color = 'primary'
          }
          return (
            startTime && (
              <Tooltip
                title={`"${title}" ${dayjs(startTime).format(
                  'M/D/YY hh:mm A'
                )}`}
                arrow={true}
              >
                <Chip
                  sx={{
                    fontSize: '0.7rem',
                    fontWeight: 600,
                    background: (theme) =>
                      lighten(theme.palette[color].main, 0.7),
                    color: (theme) => theme.palette[color].dark,
                  }}
                  label={startTime ? dayjs(startTime).fromNow() : null}
                />
              </Tooltip>
            )
          )
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 96,
        valueGetter: (value, row) => {
          return row?.actions?.length
        },
        renderCell: (params) =>
          params.row?.actions?.length > 0 && (
            <Chip
              clickable={true}
              onClick={(e) => {
                e.stopPropagation()
                openOppInSidebar(params)
              }}
              sx={{ px: '4px', fontSize: '0.7rem' }}
              icon={<RiFlashlightLine size={16} />}
              variant={
                params.row?.actions?.some(
                  (action) => action.owner?.id === user?.id
                )
                  ? 'filled'
                  : 'outlined'
              }
              color={
                params.row?.actions?.some(
                  (action) => action.owner?.id === user?.id
                )
                  ? 'secondary'
                  : 'default'
              }
              label={params.row?.actions?.length}
            />
          ),
      },
      {
        field: 'summaryText',
        headerName: 'Summary',
        width: 150,
        renderCell: (params) =>
          params.row?.organization?.relationship?.oneSentenceSummary?.length >
            0 && (
            <Box
              onClick={(e) => {
                e.stopPropagation()
                openOppInSidebar(params)
              }}
              sx={{ cursor: 'pointer' }}
            >
              {params.row?.organization?.relationship?.oneSentenceSummary}
            </Box>
          ),
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        width: 80,
        renderCell: (params) => (
          <Box
            sx={cellStyle}
            onClick={(e) => {
              e.stopPropagation()
              openOppInSidebar(params)
            }}
          >
            {dayjs(params.row.createdAt).format('M/D/YY')}
          </Box>
        ),
      },
      {
        field: 'updatedAt',
        headerName: 'Updated At',
        width: 80,
        renderCell: (params) => (
          <Box
            sx={cellStyle}
            onClick={(e) => {
              e.stopPropagation()
              openOppInSidebar(params)
            }}
          >
            {dayjs(params.row.updatedAt).format('M/D/YY')}
          </Box>
        ),
      },
    ],
    [pipeline, apiRef, refetch, user?.id, openOppInSidebar]
  )

  const filteredOpportunities = useMemo(
    () =>
      opportunities?.filter((opportunity) =>
        shouldShowOpp(opportunity, filters)
      ),
    [opportunities, filters]
  )

  useEffect(() => {
    if (Array.isArray(filters?.table?.columns?.orderedFields)) {
      logger.dev({ columnOrder: filters?.table?.columns?.orderedFields })
    }
  }, [filters?.table?.columns?.orderedFields])

  const finalColumns = useMemo(() => {
    const cols = []
    if (owners?.length > 0) {
      cols.push(ownerColumn)
    }
    if (pipeline?.hasRevenue) {
      cols.push(revenueColumn)
    }
    return [...cols, ...columns]
  }, [columns, owners, pipeline?.hasRevenue, ownerColumn, revenueColumn])

  return (
    <Box
      sx={{
        height: 'calc(100vh - 104px - 16px)',
        width: '100%',
        overflowY: 'auto',
        px: 3,
        mt: '-24px',
      }}
    >
      <DataGridPremium
        rows={filteredOpportunities}
        columns={finalColumns}
        apiRef={apiRef}
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
          moreActionsIcon: MoreActionsIcon,
          toolbar: DataGridToolbar,
        }}
        slotProps={{
          toolbar: {
            onUpdate: setTableFilters,
            showQuickFilter: true,
            showColumnFilter: true,
            showFilter: true,
            showExport: true,
            csvOptions: {
              fields: [
                'title',
                'currentStatus',
                'stage',
                'stageTitle',
                'daysInStage',
                'expectedCloseDate',
                'expectedRevenue',
                'ownerEmail',
              ],
            },
          },
        }}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={processRowUpdateError}
        onColumnVisibilityModelChange={(model) => {
          setTableFilters({
            columns: {
              visibility: model,
            },
          })
        }}
        onColumnResize={(model) => {
          logger.dev({ model })
        }}
        onColumnOrderChange={(params, event, details) => {
          logger.dev({ params, event, details })
          const state = apiRef?.current?.exportState()
          const orderedFields = state?.columns?.orderedFields
          logger.dev({ orderedFields })
          // if (false && Array.isArray(orderedFields)) {
          //   setTableFilters({
          //     columns: {
          //       orderedFields,
          //     },
          //   })
          // }
        }}
        onSortModelChange={(model) => {
          setTableFilters({
            sorting: model,
          })
        }}
        onPinnedColumnsChange={(pinnedColumns) => {
          setTableFilters({
            pinnedColumns,
          })
        }}
        onFilterModelChange={(filterModel) => {
          logger.dev({ filterModel })
          setTableFilters({
            filters: filterModel,
          })
        }}
        sortingOrder={['desc', 'asc']}
        initialState={{
          sorting: {
            sortModel: filters?.table?.sorting || [
              { field: 'expectedCloseDate', sort: 'desc' },
            ],
          },
          filter: filters?.table?.filters
            ? { filterModel: filters?.table?.filters }
            : {},
          columns: {
            columnVisibilityModel: filters?.table?.columns?.visibility
              ? {
                  ...filters?.table?.columns?.visibility,
                  expectedRevenue: pipeline?.hasRevenue,
                }
              : {
                  type: false,
                  createdAt: false,
                  updatedAt: false,
                  summaryText: false,
                  nextStepsText: false,
                  progress: false,
                  warmth: false,
                  stageTitle: false,
                  expectedRevenue: pipeline?.hasRevenue,
                },
          },
          pinnedColumns: filters?.table?.pinnedColumns,
        }}
        disableRowSelectionOnClick={true}
        autoHeight={false}
        columnHeaderHeight={48}
        rowHeight={48}
        hideFooter={true}
        sx={{
          '& .MuiDataGrid-toolbarContainer': {
            borderBottom: 'none',
            height: '64px',
          },
          '& .MuiDataGrid-main': {
            width: '100%',
            px: 0,
            mt: 0,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderRadius: '8px',
            background: (theme) => theme.palette.background.paper,
          },
          '& .MuiDataGrid-columnHeader': {
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            pb: '12px',
            borderRight: 'none',
            pt: '20px',
            '.MuiDataGrid-columnHeaderTitle': {
              fontSize: '12px',
              fontWeight: 600,
            },
          },
        }}
      />
    </Box>
  )
}

export default PipelineTable
