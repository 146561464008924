import { useEffect, useMemo, useState } from 'react'

import { Avatar, type AvatarProps } from '@mui/material'
import { IconCircleFilled } from '@tabler/icons-react'
import type { CRMObject } from 'types/graphql'

import { getObjectMetadata } from 'src/lib/indexedDb'
import { logger } from 'src/lib/logger'
import type { NativeObjectType } from 'src/lib/objects'
import { ObjectTypeMetadata } from 'src/lib/objects'
const useObjectMetadata = (
  workspaceId: string,
  objectId: string,
  objectType: NativeObjectType
) => {
  const [metadata, setMetadata] = useState<Record<string, any> | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let ignore = false

    const fetchMetadata = async () => {
      if (!objectId) {
        setIsLoading(false)
        return
      }

      try {
        const result = await getObjectMetadata({
          workspaceId,
          objectType,
          objectId,
        })

        if (!ignore) {
          setMetadata(result)
          setIsLoading(false)
        }
      } catch (error) {
        logger.error('Failed to fetch object metadata', error)
        if (!ignore) {
          setIsLoading(false)
        }
      }
    }

    setIsLoading(true)
    fetchMetadata()

    return () => {
      ignore = true
    }
  }, [workspaceId, objectId, objectType])

  return { metadata, isLoading }
}

const getFallbackAvatar = (crmObject: CRMObject, avatarProps: AvatarProps) => {
  const objectIcon =
    ObjectTypeMetadata[crmObject.objectType]?.icon || IconCircleFilled
  return (
    <Avatar
      src={null}
      {...avatarProps}
      sx={{
        background: (theme) => theme.palette.background.default,
        color: (theme) => theme.palette.text.disabled,
        ...avatarProps.sx,
      }}
    >
      {React.createElement(objectIcon, {
        size: '12px',
        stroke: 2.45,
        style: {
          marginTop: '-1px',
        },
      })}
    </Avatar>
  )
}

const ObjectAvatar = ({
  workspaceId,
  crmObject,
  avatarProps,
}: {
  workspaceId: string
  crmObject: CRMObject
  avatarProps: AvatarProps
}): React.ReactNode => {
  // this component's only job is to get the proper avatar url from the crmObject
  // and pass it to a MUI Avatar component. It should do as little as possible
  // to provide flexibility for the caller.

  // we have two ways to get the avatar url, in order of preference:
  // 1. from the metadata cache
  // 2. by querying for the object

  // first, try to get the avatar url from metadata
  const { metadata } = useObjectMetadata(
    workspaceId,
    crmObject.objectId,
    crmObject.objectType as NativeObjectType
  )

  const avatarUrl = metadata?.avatarUrl

  // if we don't have the avatar from the metadata, we need to query for the object
  // and get the avatar url from there
  const fallbackAvatar = useMemo(
    () => getFallbackAvatar(crmObject, avatarProps),
    [crmObject, avatarProps]
  )

  return avatarUrl ? (
    <Avatar
      src={avatarUrl}
      {...avatarProps}
    />
  ) : (
    fallbackAvatar
  )
}

export default ObjectAvatar
