import { useState, useCallback } from 'react'

import {
  Box,
  List,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { IconChevronDown, IconRefresh } from '@tabler/icons-react'
import type { Action, ActionStatus, ActionType } from 'types/graphql'

import { dayjs } from 'src/lib/dayjs'

import { actionStatusMap } from '../Actions/styles'
import Row from '../Row/Row'

import { completedStatuses, typeLabels } from './inbox'
import InboxActionDetail from './InboxActionDetail'
import { InboxProvider, useInbox } from './InboxContext'
import InboxListItem from './InboxListItem'
import InboxSidebar from './InboxSidebar'

const inboxStyles = {
  '.action-item': {
    borderLeft: '6px solid transparent',
    '&.Mui-selected': {
      borderLeft: (theme) => `6px solid ${theme.palette.primary.main}`,
    },
  },
}

const sortLabels = {
  newest: 'Newest',
  oldest: 'Oldest',
  priority: 'Priority',
  revenue: 'Revenue',
}

export const isActionCompleted = (action: Action) =>
  completedStatuses.includes(action.status?.id)

export const isActionSnoozed = (action: Action) =>
  action.status?.id === 'SNOOZED' &&
  action.timeframe?.reminderDate &&
  dayjs(action.timeframe?.reminderDate).unix() > dayjs().unix()

const sidebarWidth = 320

const InboxActionsContent = () => {
  const {
    actions,
    type,
    setType,
    refetch,
    loading,
    sort,
    setSort,
    status,
    setStatus,
    selectedAction,
    addContext,
    updateAction,
  } = useInbox()

  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null)
  const [typeAnchorEl, setTypeAnchorEl] = useState<null | HTMLElement>(null)
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenuClose = useCallback(() => {
    setSortAnchorEl(null)
    setTypeAnchorEl(null)
    setStatusAnchorEl(null)
  }, [])

  const handleChangeStatus = useCallback(
    (newStatus: ActionStatus) => {
      setStatus(newStatus)
      handleMenuClose()
    },
    [setStatus, handleMenuClose]
  )

  const handleSortClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setSortAnchorEl(e.currentTarget)
  }, [])

  const handleSort = useCallback(
    (newSort: 'newest' | 'oldest' | 'priority' | 'revenue') => {
      setSort(newSort)
      handleMenuClose()
    },
    [setSort, handleMenuClose]
  )

  const handleChangeType = (type: ActionType) => {
    setType(type)
    setTypeAnchorEl(null)
  }

  const handleStatusClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      setStatusAnchorEl(e.currentTarget)
    },
    []
  )

  return (
    <Row sx={{ height: '100%', width: '100%', ...inboxStyles }}>
      <Box
        sx={{
          width: `${sidebarWidth}px`,
          borderRight: (theme) => `1px solid ${theme.palette.divider}`,
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            height: '72px',
            p: 2,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Row sx={{ justifyContent: 'space-between', width: '100%' }}>
            {type && (
              <Row gap={1}>
                <Typography variant="h2">{typeLabels[type].label}</Typography>
                <IconButton
                  onClick={(e) => setTypeAnchorEl(e.currentTarget)}
                  sx={{ p: 0 }}
                >
                  <IconChevronDown size={14} />
                </IconButton>
                <Menu
                  anchorEl={typeAnchorEl}
                  open={!!typeAnchorEl}
                  onClose={() => setTypeAnchorEl(null)}
                >
                  {Object.entries(typeLabels).map(([type, item]) => (
                    <MenuItem
                      key={type}
                      onClick={() => handleChangeType(type as ActionType)}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText>{item.label}</ListItemText>
                    </MenuItem>
                  ))}
                </Menu>
              </Row>
            )}
            <IconButton
              onClick={() => refetch()}
              sx={{
                animation: loading ? 'spin 1s linear infinite' : 'none',
                '@keyframes spin': {
                  '0%': {
                    transform: 'rotate(0deg)',
                  },
                  '100%': {
                    transform: 'rotate(360deg)',
                  },
                },
              }}
            >
              <IconRefresh size={16} />
            </IconButton>
          </Row>
        </Box>
        <Row
          sx={{
            height: '24px',
            background: (theme) => theme.palette.background.default,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderLeft: 'none',
            borderRight: 'none',
            px: 2,
          }}
          gap={2}
        >
          <Row
            gap={1}
            onClick={handleSortClick}
            sx={{ cursor: 'pointer' }}
          >
            <Typography
              sx={{
                fontSize: '11px',
                fontWeight: 600,
                letterSpacing: '-0.17px',
              }}
            >
              {sortLabels[sort]}
            </Typography>
            <IconButton
              sx={{ p: '4px', borderRadius: 0 }}
              onClick={(e) => e.stopPropagation()}
            >
              <IconChevronDown size={14} />
            </IconButton>
            <Menu
              anchorEl={sortAnchorEl}
              open={Boolean(sortAnchorEl)}
              onClose={handleMenuClose}
              onClick={(e) => e.stopPropagation()}
              MenuListProps={{
                'aria-labelledby': 'sort-button',
              }}
            >
              {Object.entries(sortLabels).map(([value, label]) => (
                <MenuItem
                  key={`sort-${value}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleSort(
                      value as 'newest' | 'oldest' | 'priority' | 'revenue'
                    )
                  }}
                >
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </Row>
          <Row
            gap={1}
            onClick={handleStatusClick}
            sx={{ cursor: 'pointer' }}
          >
            {status && (
              <Typography
                sx={{
                  fontSize: '11px',
                  fontWeight: 600,
                  letterSpacing: '-0.17px',
                }}
              >
                {actionStatusMap[status].label}
              </Typography>
            )}
            <Box sx={{ p: '4px', borderRadius: 0 }}>
              <IconChevronDown size={14} />
            </Box>
            <Menu
              anchorEl={statusAnchorEl}
              open={Boolean(statusAnchorEl)}
              onClose={handleMenuClose}
              onClick={(e) => e.stopPropagation()}
              MenuListProps={{
                'aria-labelledby': 'status-button',
              }}
            >
              {Object.entries(actionStatusMap).map(([status, item]) => (
                <MenuItem
                  key={`status-chooser-${status}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleChangeStatus(status as ActionStatus)
                  }}
                >
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </Row>
        </Row>
        {(actions?.length ?? 0) > 0 ? (
          <List
            sx={{
              height: 'calc(100vh - 80px)',
              overflowY: 'auto',
              p: 0,
              m: 0,
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
            }}
          >
            {actions.map((action, index) => (
              <InboxListItem
                key={`inbox-action-${index}-${action.id}`}
                action={action}
              />
            ))}
          </List>
        ) : (
          !loading && (
            <Box sx={{ p: 2 }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  textAlign: 'center',
                  letterSpacing: '-0.3px',
                  opacity: 0.5,
                }}
              >
                {`No ${(status
                  ? actionStatusMap[status].label
                  : ''
                ).toLowerCase()} ${typeLabels[type].pluralDescriptor.toLowerCase()} found`}
              </Typography>
            </Box>
          )
        )}
      </Box>
      <Box
        sx={{
          flex: 1,
          height: '100%',
          px: 3,
          overflowY: 'auto',
          background: (theme) => theme.palette.background.default,
        }}
      >
        <InboxActionDetail
          selectedAction={selectedAction}
          refetch={refetch}
          type={type}
          updateAction={updateAction}
          showCrmObjects={true}
        />
      </Box>
      <InboxSidebar
        selectedAction={selectedAction}
        width={sidebarWidth}
        grid={false}
        addContext={addContext}
        refetch={refetch}
      />
    </Row>
  )
}

export const InboxActions = ({ workspaceId }: { workspaceId: string }) => {
  return (
    <InboxProvider workspaceId={workspaceId}>
      <InboxActionsContent />
    </InboxProvider>
  )
}

export default InboxActions
