import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { IconX } from '@tabler/icons-react'
import { useConfirm } from 'material-ui-confirm'

import { logger } from 'src/lib/logger'

import { useViews } from './ViewsContext'

const ViewDelete = () => {
  const confirm = useConfirm()
  const { currentView, deleteView, pinnedViews } = useViews()

  const handleDelete = async () => {
    try {
      await confirm({
        title: 'Delete view',
        description: 'Are you sure you want to delete this view?',
      })
      const position = pinnedViews.findIndex((v) => v.id === currentView?.id)
      deleteView(currentView?.id, position)
    } catch (error) {
      logger.dev('Error deleting view (or user declined)', error)
    }
  }

  return (
    <ListItemButton onClick={handleDelete}>
      <ListItemIcon sx={{ minWidth: '28px' }}>
        <IconX
          size={16}
          stroke={3}
        />
      </ListItemIcon>
      <ListItemText
        primary="Delete view"
        primaryTypographyProps={{ fontSize: '12px', letterSpacing: '-0.22px' }}
      />
    </ListItemButton>
  )
}

export default ViewDelete
