import { useState } from 'react'

import {
  Box,
  Button,
  List,
  ListItem,
  Typography,
  useTheme,
} from '@mui/material'
import {
  RiCheckFill,
  RiFlagFill,
  RiFlashlightFill,
  RiFlashlightLine,
  RiZzzLine,
} from '@remixicon/react'
import type { Action } from 'types/graphql'

import { logger } from 'src/lib/logger'

import ActionActions from '../../Actions/ActionActions/ActionActions'
import ContactTile from '../../ContactTile/ContactTile'
import Row from '../../Row/Row'

const SidebarActions = ({
  actions,
  onUpdate,
  opportunityId,
}: {
  actions: Action[]
  onUpdate: () => void
  opportunityId?: string
}) => {
  const theme = useTheme()
  const [openAction, setOpenAction] = useState<Action | null>(null)
  const [expandedAssignee, setExpandedAssignee] = useState<string | null>(null)

  logger.dev({ openAction })
  if (!Array.isArray(actions)) return null

  const actionsByAssignee = {}
  for (const action of actions
    .filter(Boolean)
    .filter((a) => !['DISMISSED', 'REDUNDANT'].includes(a?.status?.id))) {
    if (action?.owner?.email) {
      if (!actionsByAssignee[action?.owner?.email]) {
        actionsByAssignee[action?.owner?.email] = [] as Action[]
      }
      actionsByAssignee[action?.owner?.email].push(action)
    }
  }
  for (const assignee of Object.keys(actionsByAssignee)) {
    actionsByAssignee[assignee].sort((a, b) => {
      // First, sort completed actions to the bottom
      if (a?.status?.id === 'COMPLETED' && b?.status?.id !== 'COMPLETED')
        return 1
      if (a?.status?.id !== 'COMPLETED' && b?.status?.id === 'COMPLETED')
        return -1

      // Then, sort by snoozed actions to the bottom
      if (a?.status?.id === 'SNOOZED' && b?.status?.id !== 'SNOOZED') return 1
      if (a?.status?.id !== 'SNOOZED' && b?.status?.id === 'SNOOZED') return -1

      // Then, sort by opportunity
      const aHasOpportunity = a?.opportunityIds?.includes(opportunityId)
      const bHasOpportunity = b?.opportunityIds?.includes(opportunityId)

      if (aHasOpportunity && !bHasOpportunity) return -1
      if (!aHasOpportunity && bHasOpportunity) return 1
      return 0
    })
  }
  const assignees: [string, Action[]][] = Object.entries(actionsByAssignee)

  return (
    <>
      {assignees
        .filter(([assignee]) => assignee.includes('@'))
        .map(([assignee, actions], index) => (
          <Box
            sx={{
              borderBottom: (theme) =>
                index === assignees?.length - 1
                  ? 'none'
                  : `1px solid ${theme.palette.divider}`,
              mt: 3,
            }}
            key={`sidebar-actions-assignee-${assignee}`}
          >
            <Row sx={{ justifyContent: 'space-between' }}>
              <ContactTile email={assignee} />
              {actions?.length > 2 && (
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    if (expandedAssignee === assignee) {
                      setExpandedAssignee(null)
                    } else {
                      setExpandedAssignee(assignee)
                    }
                  }}
                  sx={{ flexShrink: 0, width: '104px' }}
                >
                  {expandedAssignee === assignee
                    ? 'View less'
                    : `View all (${actions?.length})`}
                </Button>
              )}
            </Row>
            <List
              sx={{
                px: '0px !important',
                py: 2,
                listStyle: 'none !important',
              }}
            >
              {actions
                ?.slice(0, expandedAssignee === assignee ? actions?.length : 2)
                ?.map((action, index) => (
                  <ListItem
                    key={`${action.id}-${assignee}-${index}`}
                    sx={{ pr: 0, pl: 0, pt: 0, minHeight: '48px', pb: 3 }}
                    onClick={() => setOpenAction(action)}
                  >
                    <Row
                      sx={{
                        justifyContent: 'space-between',
                        alignItems: 'top',
                        width: '100%',
                      }}
                    >
                      <Row
                        gap={'12px'}
                        sx={{ cursor: 'pointer' }}
                      >
                        {action.status?.id !== 'COMPLETED' ? (
                          <Row
                            sx={{ alignItems: 'top' }}
                            gap={1}
                          >
                            <ActionActions
                              action={action}
                              onUpdate={onUpdate}
                              inFocus={openAction?.id === action?.id}
                              onClose={() => setOpenAction(null)}
                              size={18}
                              showButtons={true}
                              buttons={['COMPLETE', 'SNOOZE']}
                            />
                          </Row>
                        ) : (
                          <Box sx={{ flexShrink: 0 }}>
                            {action.status?.id === 'COMPLETED' ? (
                              <RiCheckFill
                                size={16}
                                color={theme.palette.success.main}
                              />
                            ) : opportunityId &&
                              action?.opportunityIds?.includes(
                                opportunityId
                              ) ? (
                              <RiFlagFill
                                size={16}
                                color={theme.palette.primary.main}
                              />
                            ) : action.status?.id === 'SNOOZED' ? (
                              <RiZzzLine
                                size={16}
                                color={theme.palette.primary.light}
                              />
                            ) : action?.priority === 'URGENT' ? (
                              <RiFlashlightFill
                                size={16}
                                color={theme.palette.error.light}
                              />
                            ) : (
                              <>
                                {action?.priority === 'HIGH' ? (
                                  <RiFlashlightLine
                                    size={16}
                                    color={theme.palette.secondary.main}
                                  />
                                ) : (
                                  <RiFlashlightLine size={16} />
                                )}
                              </>
                            )}
                          </Box>
                        )}
                        <Typography
                          sx={{
                            cursor: 'pointer',
                            pr: 1,
                            pl: 0,
                            fontSize: '12px',
                            letterSpacing: '-0.3px',
                            fontWeight: 500,
                          }}
                        >
                          {action?.title}
                        </Typography>
                      </Row>
                    </Row>
                  </ListItem>
                ))}
            </List>
          </Box>
        ))}
    </>
  )
}

export default SidebarActions
