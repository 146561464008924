import { useEffect, useRef, useState } from 'react'

import {
  KnockFeedProvider,
  NotificationFeedPopover,
  NotificationIconButton,
  useKnockFeed,
} from '@knocklabs/react-notification-feed'
import toast, { Toaster } from 'react-hot-toast'

import { useAuth } from 'src/auth'

const NotificationToaster = () => {
  const { feedClient } = useKnockFeed()

  const onNotificationsReceived = ({ items }) => {
    // Whenever we receive a new notification from our real-time stream, show a toast
    // (note here that we can receive > 1 items in a batch)
    items.forEach((notification) => {
      toast(notification.blocks[0].rendered, { id: notification.id })
    })

    // Optionally, you may want to mark them as "seen" as well
    feedClient.markAsSeen(items)
  }

  useEffect(() => {
    // Receive all real-time notifications on our feed
    feedClient.on('items.received.realtime', onNotificationsReceived)

    // Cleanup
    return () =>
      feedClient.off('items.received.realtime', onNotificationsReceived)
  }, [feedClient])

  return <Toaster />
}

const Notifications = () => {
  const { currentUser: user } = useAuth()
  const [expanded, setExpanded] = useState(false)
  const icon = useRef(null)
  return (
    <>
      <KnockFeedProvider
        apiKey={process.env.KNOCK_PUBLIC_KEY}
        feedId={process.env.KNOCK_FEED_ID}
        userId={user.email}
      >
        <>
          {expanded && (
            <NotificationFeedPopover
              isVisible={expanded}
              buttonRef={icon}
              onClose={() => setExpanded(false)}
            />
          )}
          <NotificationIconButton
            ref={icon}
            onClick={() => setExpanded(true)}
          />
          <NotificationToaster />
        </>
      </KnockFeedProvider>
    </>
  )
}

export default Notifications
