import { useCallback, useReducer, useEffect } from 'react'

import { Box, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import type { GridStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'
import { IconRefresh } from '@tabler/icons-react'
import type { View } from 'types/view'

import { useAuth } from 'src/auth'

import MetadataChip from '../Chips/MetadataChip/MetadataChip'
import Row from '../Row/Row'

import ViewCreate from './ViewCreate'
import ViewEdit from './ViewEdit'
import ViewRemovePin from './ViewRemovePin'
import { useViews } from './ViewsContext'

interface ViewManagerState {
  selectedTabId: string | null
  localPinnedViews: View[] // Keep a local copy of pinned views
}

type ViewManagerAction =
  | { type: 'SET_SELECTED_TAB'; payload: string | null }
  | {
      type: 'HANDLE_VIEW_CHANGE'
      payload: { view: View; gridState: GridStatePremium }
    }
  | { type: 'SET_LOCAL_VIEWS'; payload: View[] }
  | { type: 'RESET_STATE' }

function viewManagerReducer(
  state: ViewManagerState,
  action: ViewManagerAction
): ViewManagerState {
  switch (action.type) {
    case 'SET_SELECTED_TAB':
      return {
        ...state,
        selectedTabId: action.payload,
      }
    case 'HANDLE_VIEW_CHANGE':
      return {
        ...state,
        selectedTabId: action.payload.view.id,
      }
    case 'SET_LOCAL_VIEWS':
      return {
        ...state,
        localPinnedViews: action.payload,
      }
    case 'RESET_STATE':
      return {
        selectedTabId: null,
        localPinnedViews: [],
      }
    default:
      return state
  }
}

const ViewManager = () => {
  const { currentUser } = useAuth()
  const {
    views,
    setCurrentView,
    setExternalGridState,
    refetchViews,
    currentView,
    pinnedViews,
    loading,
    saving,
  } = useViews()

  const [state, dispatch] = useReducer(viewManagerReducer, {
    selectedTabId: currentView?.id ?? pinnedViews[0]?.id ?? null,
    localPinnedViews: pinnedViews,
  })

  const handleViewChange = useCallback(
    (viewId: string | null) => {
      if (!viewId) return

      const view = views.find((v) => v.id === viewId)
      if (view) {
        dispatch({
          type: 'HANDLE_VIEW_CHANGE',
          payload: {
            view,
            gridState: view.gridState as unknown as GridStatePremium,
          },
        })
        setCurrentView(view)
        setExternalGridState(view.gridState as unknown as GridStatePremium)
      }
    },
    [views, setCurrentView, setExternalGridState]
  )

  useEffect(() => {
    if (!state.selectedTabId && pinnedViews.length > 0) {
      const firstView = pinnedViews[0]
      dispatch({
        type: 'SET_SELECTED_TAB',
        payload: firstView.id,
      })
      handleViewChange(firstView.id)
    }
  }, [pinnedViews, state.selectedTabId, handleViewChange])

  return (
    <Row
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '36px',
        zIndex: 10,
        position: 'relative',
        '& .MuiTabs-indicator': {
          display: 'none',
        },
        '& .MuiTab-root': {
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderTopLeftRadius: '3px',
          borderTopRightRadius: '3px',
          mr: 1,
          minHeight: '36px',
          padding: '4px 8px',
          backgroundColor: (theme) => theme.palette.background.default,
          '& .MuiTypography-root': {
            fontSize: '12px',
            letterSpacing: '-0.22px',
            fontWeight: 500,
            opacity: 0.6,
          },
          '&.Mui-selected': {
            borderBottom: (theme) =>
              `1px solid ${theme.palette.background.paper}`,
            backgroundColor: (theme) => theme.palette.background.paper,
            '& .MuiTypography-root': {
              opacity: 1,
              fontWeight: 600,
            },
            '&.Mui-disabled': {
              '& .MuiTypography-root': {
                opacity: 0.6,
              },
            },
          },
        },
      }}
    >
      <Row sx={{ pl: '12px' }}>
        {pinnedViews.length > 0 ? (
          <Tabs
            value={currentView?.id ?? state.selectedTabId ?? pinnedViews[0]?.id}
            onChange={(_, viewId) => {
              if (pinnedViews.some((v) => v.id === viewId)) {
                dispatch({
                  type: 'SET_SELECTED_TAB',
                  payload: viewId,
                })
                handleViewChange(viewId)
              }
            }}
          >
            {pinnedViews.map((view) => (
              <Tab
                key={view.id}
                label={
                  <Row sx={{ justifyContent: 'space-between' }}>
                    <Typography>{view.title}</Typography>
                    {currentView?.id === view.id &&
                      currentUser?.id === view.creatorId && (
                        <Row sx={{ ml: '6px' }}>
                          <ViewEdit />
                          <ViewRemovePin />
                        </Row>
                      )}
                  </Row>
                }
                value={view.id}
                disableFocusRipple={true}
                disabled={loading}
              />
            ))}
          </Tabs>
        ) : (
          <Tabs value={loading ? 'loading' : 'all'}>
            <Tab
              value={loading ? 'loading' : 'all'}
              disabled={loading}
              label={
                <Row>
                  <Typography>{loading ? 'Loading...' : 'All'}</Typography>
                </Row>
              }
            />
          </Tabs>
        )}
        <Box sx={{ pb: '2px' }}>
          <ViewCreate />
        </Box>
      </Row>
      <Row
        gap={0}
        sx={{ pb: '2px' }}
      >
        {saving ? (
          <MetadataChip
            state={{
              label: 'saving ...',
              color: 'primary',
              value: 'saving',
            }}
            icon={<IconRefresh size={14} />}
          />
        ) : (
          <Tooltip title="Refresh views">
            <IconButton
              onClick={() => refetchViews()}
              sx={{
                p: '4px',
                borderRadius: '3px',
              }}
            >
              <IconRefresh size={14} />
            </IconButton>
          </Tooltip>
        )}
      </Row>
    </Row>
  )
}

export default ViewManager
