import { Chip, Tooltip } from '@mui/material'
import { IconStack2 } from '@tabler/icons-react'
import toast from 'react-hot-toast'
import type { CRMObject } from 'types/graphql'

import { navigate } from '@redwoodjs/router'
import { routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { actionButtonStyle } from 'src/components/Sidebar/styles'

const CREATE_THREAD_FROM_BUTTON = gql`
  mutation CreateThreadFromButton(
    $workspaceId: String!
    $sourceObject: CRMObjectInput!
    $otherObjects: [CRMObjectInput!]!
    $welcomeMessage: String!
    $title: String!
  ) {
    createThread(
      workspaceId: $workspaceId
      sourceObject: $sourceObject
      contextObjects: $otherObjects
      welcomeMessage: $welcomeMessage
      title: $title
    ) {
      id
    }
  }
`

const ThreadCreateButton = ({
  workspaceId,
  sourceObject,
  otherObjects,
  welcomeMessage = null,
  title = null,
  onThreadCreated,
}: {
  workspaceId: string
  sourceObject: CRMObject
  otherObjects: CRMObject[]
  welcomeMessage: string
  title: string
  onThreadCreated: () => void
}) => {
  const [createThread] = useMutation(CREATE_THREAD_FROM_BUTTON)

  const welcomeMessageWithFallback =
    welcomeMessage || 'Ask anything and create Pages with Day.ai Threads'

  const handleCreateThread = async (e) => {
    e.stopPropagation()
    const input = {
      workspaceId,
      sourceObject: {
        objectId: sourceObject.objectId,
        objectType: sourceObject.objectType,
      },
      otherObjects: otherObjects.map((object) => ({
        objectId: object.objectId,
        objectType: object.objectType,
      })),
      welcomeMessage: welcomeMessageWithFallback,
      title,
    }

    await createThread({
      variables: input,
      onError: () => {
        toast.error('Error creating thread')
      },
      onCompleted: (data) => {
        navigate(routes.thread({ id: data.createThread.id }))
        onThreadCreated()
      },
    })
  }

  return (
    <Tooltip
      title={welcomeMessageWithFallback}
      arrow={true}
      placement="top"
    >
      <Chip
        label="Thread"
        icon={<IconStack2 size={12} />}
        clickable={true}
        onClick={handleCreateThread}
        variant="outlined"
        size="small"
        sx={actionButtonStyle}
      />
    </Tooltip>
  )
}

export default ThreadCreateButton
