import { useMemo } from 'react'

import { AvatarGroup } from '@mui/material'
import type { CRMObject } from 'types/graphql'

import ObjectAvatar from 'src/components/Avatars/ObjectAvatar'
import ObjectChip from 'src/components/Chips/ObjectChip/ObjectChip'
import Row from 'src/components/Row/Row'
import { logger } from 'src/lib/logger'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'

import BaseChip from '../BaseChip'

const AVATAR_SIZE = 16
const GENERIC_LABEL = 'Record'
const AVATAR_GROUP_STYLES = {
  '& .MuiAvatar-root, .domainAvatar': {
    height: `${AVATAR_SIZE}px`,
    width: `${AVATAR_SIZE}px`,
    ml: `-4px`,
    flexShrink: 0,
    border: '0px',
    fontSize: '8px',
    fontWeight: 400,
  },
  sx: {
    color: '#616161',
    background: 'red',
  },
  '& .overflow-avatar': {
    marginLeft: '-4px',
  },
}

interface MultiObjectChipProps {
  workspaceId: string
  crmObjects: ReadonlyArray<Partial<CRMObject>>
  max?: number
  fullWidth?: boolean
  label?: string
  onClick?: () => void
}

const extractObjectType = (objectData: Partial<CRMObject>) => {
  return objectData.objectType
}

const defaultMultiObjectParams = {
  max: 2,
  fullWidth: false,
  label: null,
  onClick: null,
}

const MultiObjectChip = ({
  workspaceId,
  crmObjects,
  max = defaultMultiObjectParams.max,
  fullWidth = defaultMultiObjectParams.fullWidth,
  label = defaultMultiObjectParams.label,
  onClick = defaultMultiObjectParams.onClick,
}: MultiObjectChipProps) => {
  const validUniqueObjects = useMemo(() => {
    const objs = []
    const objIds = new Set<string>()
    for (const obj of crmObjects) {
      if (
        obj &&
        obj.objectId &&
        !objIds.has(obj.objectId) &&
        Object.values(NativeObjectTypes).includes(
          obj.objectType as NativeObjectType
        )
      ) {
        objs.push(obj)
        objIds.add(obj.objectId)
      } else {
        logger.warn('Invalid object', { object: obj })
      }
    }

    return objs
  }, [crmObjects])

  const objectData = useMemo(() => {
    return validUniqueObjects.map((crmObject) => ({
      objectType: crmObject.objectType,
      objectId: crmObject.objectId,
      objectProperties: crmObject.properties,
    }))
  }, [validUniqueObjects]) as Partial<CRMObject>[]

  const bestLabel = useMemo(() => {
    if (label) {
      return label
    }
    const objectTypes = new Set(
      objectData.map(extractObjectType).filter(Boolean)
    )

    if (objectTypes.size === 1) {
      const firstObjectType = objectData[0]?.objectType
      const metadata = ObjectTypeMetadata[firstObjectType ?? '']
      return objectData.length > 1
        ? `${objectData.length} ${metadata?.pluralLabel ?? GENERIC_LABEL}`
        : `${objectData.length} ${metadata?.label ?? GENERIC_LABEL}`
    }
    return `${objectData.length} ${objectData.length === 1 ? GENERIC_LABEL : GENERIC_LABEL + 's'}`
  }, [objectData, label])

  const avatarGroup = useMemo(() => {
    const renderedAvatars = Math.min(max, validUniqueObjects.length)
    const baseMargin = (renderedAvatars - 1) * (16 - 4) + 2
    const avatarMargin =
      validUniqueObjects.length > max ? baseMargin + 6 : baseMargin

    const avatarGroupSx = {
      ml: `${avatarMargin}px !important`,
      ...AVATAR_GROUP_STYLES,
    }

    return (
      <AvatarGroup
        className="multi-object-avatar-group"
        sx={avatarGroupSx}
      >
        {validUniqueObjects.slice(0, max + 1).map((objectData, index) => {
          return (
            <ObjectAvatar
              workspaceId={workspaceId}
              crmObject={objectData}
              key={objectData.objectId ?? index}
              avatarProps={{
                className: index === max ? 'overflow-avatar' : undefined,
                sx: {
                  background: (theme) => theme.palette.action.selected,
                },
              }}
            />
          )
        })}
      </AvatarGroup>
    )
  }, [validUniqueObjects, workspaceId, max])

  return (
    <Row>
      {validUniqueObjects.length > 1 ? (
        <BaseChip
          label={bestLabel}
          avatar={avatarGroup}
          icon={null}
          fullWidth={fullWidth}
          onClick={onClick}
        />
      ) : validUniqueObjects.length === 1 ? (
        <Row sx={{ '& .MuiAvatar-root': { ml: '2px' }, width: '100%' }}>
          <ObjectChip
            workspaceId={workspaceId}
            crmObject={validUniqueObjects[0]}
            onRemove={null}
            fullWidth={fullWidth}
          />
        </Row>
      ) : null}
    </Row>
  )
}

export default MultiObjectChip
