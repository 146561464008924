import { useContext, useEffect, useMemo, useState } from 'react'

import {
  Box,
  darken,
  GlobalStyles,
  lighten,
  List,
  ListItemButton,
  Typography,
} from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import {
  IconBolt,
  IconBubbleText,
  IconBuildingSkyscraper,
  IconBuildingStore,
  IconCalendar,
  IconChartBar,
  IconChartScatter,
  IconCoins,
  IconColumns2,
  IconFiles,
  IconHeartHandshake,
  IconInbox,
  IconLaurelWreath,
  IconMicrophone,
  IconReportAnalytics,
  IconSearch,
  IconServer,
  IconSitemap,
  IconStack2,
  IconTrophy,
  IconUser,
} from '@tabler/icons-react'
import ReactGA from 'react-ga4'
import { Toaster } from 'react-hot-toast'
import { useHotkeys } from 'react-hotkeys-hook'
import { v4 as uuid } from 'uuid'

import { routes, Link, navigate } from '@redwoodjs/router'
import { useLocation } from '@redwoodjs/router'
import { useRouteName } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { AvatarCacheProvider } from 'src/components/AvatarCacheProvider/AvatarCacheProvider'
import { ObjectCacheProvider } from 'src/components/ObjectCacheProvider/ObjectCacheProvider'
import Row from 'src/components/Row/Row'
import SearchModal from 'src/components/Search/SearchModal/SearchModal'
import Sidebar from 'src/components/Sidebar/Sidebar'
import UserErrorRemediation from 'src/components/UserErrorRemediation/UserErrorRemediation'
import WorkspaceInviteBanner from 'src/components/WorkspaceInviteBanner/WorkspaceInviteBanner'
import ProfileController from 'src/layouts/ModernLayout/ProfileController/ProfileController'
import WorkspaceDropdown from 'src/layouts/ModernLayout/WorkspaceDropdown/WorkspaceDropdown'
import { AnalyticsActions, AnalyticsCategories } from 'src/lib/analytics'
import { DayContext, DayProvider } from 'src/lib/dayContext'
import {
  ungatedForActivityReporting,
  ungatedForCrm3,
  ungatedForCustomers,
  ungatedForInbox,
  ungatedForMarketing,
  ungatedForProduct,
} from 'src/lib/gates'
import { logger } from 'src/lib/logger'

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID, {
  nonce: uuid(),
})

type ModernLayoutProps = {
  children?: React.ReactNode
}

export const headerStyle = {
  fontWeight: 600,
  fontSize: '0.8rem',
  ml: '20px',
  mt: 2,
  cursor: 'pointer',
}

export const DrawerNavLabel = ({ label, collapsed }) => (
  <Box
    component={'span'}
    sx={{
      opacity: collapsed ? 0 : 1,
      transition: 'all 0.22s ease',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '1.4rem',
      fontSize: '0.9rem',
      width: collapsed ? '0px' : 'auto',
      ml: '8px',
      color: (theme) =>
        collapsed ? theme.palette.divider : theme.palette.text.primary,
    }}
  >
    {label}
  </Box>
)

const sectionsByRoute = {
  ['/today']: 'TODAY',
  ['/prospects']: 'SALES',
  ['/opportunities']: 'SALES',
  ['/reports/pipeline']: 'SALES',
  ['/reports/activity']: 'SALES',
  ['/marketing']: 'MARKETING',
  ['/customers']: 'CUSTOMERS',
  ['/recordings']: 'DATA',
  ['/actions']: 'DATA',
  ['/organizations']: 'DATA',
  ['/pages']: 'DATA',
  ['/people']: 'DATA',
  ['/pipelines']: 'SALES',
  ['/context']: 'DATA',
  ['/product']: 'PRODUCT',
}

const DrawerNavSubItem = ({
  label,
  collapsed,
  route,
  hoveredSection,
  currentRoute,
  icon,
}) => {
  const sectionSelected =
    sectionsByRoute[route] === sectionsByRoute[`/${currentRoute}`]
  const routeSelected = route === `/${currentRoute}`
  const routeHovered =
    sectionsByRoute[route] === sectionsByRoute[hoveredSection]
  const show = (sectionSelected || routeHovered) && !collapsed

  return (
    <ListItemButton
      component={Link}
      to={route}
      sx={{
        opacity: show ? 1 : 0,
        transition: 'all 0.12s ease',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        height: show ? '26px' : '0px',
        fontWeight: 600,
        fontSize: '12px',
        letterSpacing: '-0.36px',
        py: show ? '4px' : 0,
        mt: show ? 1 : 0,
        px: '0px',
        borderRadius: '4px',
        color: (theme) => theme.palette.text.primary,
        backgroundColor: (theme) =>
          routeSelected ? theme.palette.secondary.light : 'transparent',
        '&:hover': {
          backgroundColor: (theme) => theme.palette.secondary.light,
          color: (theme) => theme.palette.secondary.dark,
        },
      }}
    >
      {icon &&
        React.cloneElement(icon, {
          size: 20,
          style: {
            flexShrink: 0,
            marginRight: '11px',
            marginLeft: '10px',
          },
        })}
      {label}
    </ListItemButton>
  )
}

const drawerWidth = 280
const drawerWidthCollapsed = 64
// const drawerMargin = '20px'
const listItemHeight = '40px'

const NavItem = ({
  icon,
  label,
  route,
  selected,
  collapsed,
  hasSubItems = false,
}) => {
  const adjustForSubItems = hasSubItems && !collapsed && selected

  const containerSx = useMemo(() => {
    return {
      mt: adjustForSubItems ? 0 : 1,
      fontWeight: 500,
      borderRadius: '4px',
      width: collapsed ? '64px' : 'auto',
      minWidth: collapsed ? '36px' : 'auto',
      p: '8px',
      ml: adjustForSubItems ? '0px' : '4px',
      height: listItemHeight,
      transition: 'all 0.22s ease',
      '&:hover': {
        background: (theme) => theme.palette.secondary.light,
      },
      '&.Mui-selected': {
        background: (theme) => theme.palette.secondary.light,
        '&:hover': {
          background: (theme) => darken(theme.palette.secondary.light, 0.2),
        },
      },
      '& .navIcon': {
        flexShrink: 0,
        height: '24px',
        width: '24px',
      },
    }
  }, [adjustForSubItems, collapsed])

  return (
    <ListItemButton
      className="navItem"
      disableGutters={true}
      selected={selected}
      LinkComponent={Link}
      to={route}
      onClick={() => {
        navigate(route)
      }}
      sx={containerSx}
    >
      {React.cloneElement(icon, { className: 'navIcon' })}
      <DrawerNavLabel
        label={label}
        collapsed={collapsed}
      />
    </ListItemButton>
  )
}

const ModernLayoutInner = ({ children }: ModernLayoutProps) => {
  const { currentUser } = useAuth()
  const [collapsed, setCollapsed] = useState(true)
  const [hoveredSection, setHoveredSection] = useState(null)
  const route = useRouteName()
  const [searchOpen, setSearchOpen] = useState(false)

  // Bind to command/ctrl + k
  useHotkeys(
    'mod+k',
    (event) => {
      event.preventDefault()
      setSearchOpen(true)
    },
    { enableOnFormTags: true }
  )

  const {
    userCoreContact,
    sidebarObject,
    setSidebarObject,
    selectedWorkspace,
    workAccounts,
    workspaces,
  } = useContext(DayContext)

  useEffect(() => {
    const handleMouseLeaveWindow = (event) => {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        setCollapsed(true)
      }
    }

    document.addEventListener('mouseout', handleMouseLeaveWindow)

    return () => {
      document.removeEventListener('mouseout', handleMouseLeaveWindow)
    }
  }, [])

  useEffect(() => {
    const label = sidebarObject?.objectType || 'Unknown objectType'
    const category = AnalyticsCategories.SIDEBAR
    const action = AnalyticsActions.OPEN
    const event = {
      category,
      action,
      label,
    }

    if (sidebarObject) {
      ReactGA.event(event)
    }

    const handleMouseMove = (e) => {
      const sidebar = document.getElementById('sidebar')
      const workspaceDropdown = document.getElementById('workspace-select-menu')
      if (
        sidebar &&
        !sidebar.contains(e.target) &&
        !workspaceDropdown?.contains(e.target)
      ) {
        setCollapsed(true)
      }
    }

    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [sidebarObject, setCollapsed])

  const workspaceInvites = workspaces?.filter(
    (workspace) => workspace.status === 'INVITED'
  )

  const globalStyles = useMemo(() => {
    return {
      body: {
        backgroundColor: (theme) =>
          `${theme.palette.background.paper} !important`,
      },
    }
  }, [])

  const containerSx = useMemo(() => {
    return {
      color: (theme) => theme.palette.text.primary,
      background: (theme) => theme.palette.background.paper,
      display: 'flex',
      flexDirection: 'row',
      height: '100vh',
      overflow: 'hidden',
      '& .sidebar': {
        color: (theme) => theme.palette.text.primary,
        background: (theme) =>
          collapsed
            ? theme.palette.background.paper
            : theme.palette.background.default,
        borderRight: (theme) =>
          collapsed ? `1px solid ${theme.palette.divider}` : 'none',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '100vh',
        width: collapsed ? drawerWidthCollapsed : drawerWidth,
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 3,
        transition: 'all 0.22s ease',
        boxShadow: collapsed
          ? 'none'
          : `0 1px 1px rgba(0,0,0,0.12),
        0 2px 2px rgba(0,0,0,0.12),
        0 4px 4px rgba(0,0,0,0.12),
        0 8px 8px rgba(0,0,0,0.12),
        0 16px 16px rgba(0,0,0,0.12)`,
      },
      '& .sidebar-search-button-collapsed': {
        justifyContent: 'center',
        height: listItemHeight,
        mt: 1,
        cursor: 'pointer',
      },
      '& .sidebar-search-button': {
        mt: 1,
        pt: 1,
        minHeight: listItemHeight,
        px: 2,
        '& .sidebar-search-button-content': {
          width: '100%',
          height: '100%',
          color: 'text.secondary',
          alignItems: 'center',
          pl: '4px',
          flexWrap: 'nowrap',
          overflow: 'hidden',
        },
      },
      '& .main-content': {
        width: '100%',
        height: '100vh',
        pl: '64px',
        pr: 0,
        py: 0,
        m: 0,
        pt: 0,
        overflow: 'hidden',
        zIndex: 2,
        background: (theme) => theme.palette.background.paper,
      },
    }
  }, [collapsed])

  return (
    currentUser && (
      <>
        <GlobalStyles styles={globalStyles} />
        <Toaster />
        <CssBaseline />
        <SearchModal
          open={searchOpen}
          onClose={() => setSearchOpen(false)}
          allowCreation={true}
          onSelect={(result) => {
            setSidebarObject({
              objectType: result.objectType,
              objectId: result.objectId,
              properties: result.properties,
            })
            setSearchOpen(false)
          }}
        />

        <Sidebar
          crmObject={sidebarObject}
          setCrmObject={setSidebarObject}
          onClose={() => {}}
          workspaceId={selectedWorkspace}
        />
        <Box sx={containerSx}>
          <Box
            id="sidebar"
            className="sidebar"
            component="div"
            onMouseOver={() => {
              setCollapsed(false)
            }}
            onMouseOut={() => {
              setCollapsed(true)
            }}
          >
            <WorkspaceDropdown collapsed={collapsed} />
            <Box sx={{ mt: 1 }}>
              {collapsed ? (
                <Row
                  className="sidebar-search-button-collapsed"
                  onClick={() => setSearchOpen(true)}
                >
                  <Box>
                    <IconSearch
                      size={20}
                      stroke={2.4}
                    />
                  </Box>
                </Row>
              ) : (
                <ListItemButton
                  onClick={() => setSearchOpen(true)}
                  className="sidebar-search-button"
                >
                  <Row
                    gap={1}
                    className="sidebar-search-button-content"
                  >
                    <IconSearch
                      size={20}
                      stroke={2.4}
                    />
                    <Box
                      sx={{
                        flexGrow: 1,
                        opacity: 0.7,
                        mt: '1px',
                        ml: '2px',
                        fontWeight: 500,
                      }}
                    >
                      Search
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        height: '24px',
                        padding: '4px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                        color: 'text.secondary',
                        opacity: 0.5,
                      }}
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: '12px',
                          fontWeight: 500,
                          letterSpacing: '-0.3px',
                          border: (theme) =>
                            `1px solid ${darken(theme.palette.divider, 0.2)}`,
                          borderRadius: '4px',
                          background: (theme) =>
                            theme.palette.background.default,
                          px: 0.5,
                          py: 0,
                        }}
                      >
                        ⌘K
                      </Typography>
                    </Box>
                  </Row>
                </ListItemButton>
              )}
            </Box>
            <List
              disablePadding={true}
              sx={{
                display: 'block',
                mt: 0,
                px: 1,
                width: collapsed ? drawerWidthCollapsed : drawerWidth,
              }}
              id="sidebar-nav-item-list"
            >
              <NavItem
                icon={<IconCalendar />}
                label="Today"
                route={routes.today()}
                collapsed={collapsed}
                selected={
                  window.location.pathname === routes.today() && !collapsed
                }
              />
              {ungatedForInbox(currentUser) && (
                <NavItem
                  icon={<IconInbox />}
                  label="Inbox"
                  route={routes.customerInbox()}
                  collapsed={collapsed}
                  selected={
                    window.location.pathname === routes.customerInbox() &&
                    !collapsed
                  }
                />
              )}
              <NavItem
                icon={<IconStack2 />}
                label="Threads"
                route={routes.threads()}
                collapsed={collapsed}
                selected={
                  window.location.pathname === routes.threads() && !collapsed
                }
              />
              {ungatedForCrm3({ selectedWorkspace, workAccounts }) && (
                <Box
                  onMouseEnter={() => setHoveredSection(routes.opportunities())}
                  onMouseLeave={() => setHoveredSection(null)}
                  sx={
                    !collapsed &&
                    [
                      sectionsByRoute[`/${route}`],
                      sectionsByRoute[hoveredSection],
                    ].includes(sectionsByRoute[routes.opportunities()])
                      ? {
                          p: '4px',
                          borderRadius: '8px',
                          background: (theme) =>
                            lighten(theme.palette.secondary.light, 0.6),
                          mt: 1,
                          transition: 'all 0.22s ease',
                        }
                      : {
                          transition: 'all 0.22s ease',
                        }
                  }
                >
                  <NavItem
                    icon={<IconChartBar />}
                    label="Sales"
                    route={routes.opportunities()}
                    collapsed={collapsed}
                    hasSubItems={true}
                    selected={
                      !collapsed &&
                      [
                        sectionsByRoute[`/${route}`],
                        sectionsByRoute[hoveredSection],
                      ].includes(sectionsByRoute[routes.opportunities()])
                    }
                  />

                  <DrawerNavSubItem
                    label="Opportunities"
                    collapsed={collapsed}
                    route={routes.opportunities()}
                    currentRoute={route}
                    hoveredSection={hoveredSection}
                    icon={<IconTrophy />}
                  />
                  <DrawerNavSubItem
                    label="Pipelines"
                    collapsed={collapsed}
                    route={routes.pipelines()}
                    currentRoute={route}
                    hoveredSection={hoveredSection}
                    icon={<IconColumns2 />}
                  />
                  {/*}
                  <DrawerNavSubItem
                    label="Prospects"
                    collapsed={collapsed}
                    route={routes.prospects()}
                    currentRoute={route}
                    icon={<IconBinoculars />}
                  />*/}
                  <DrawerNavSubItem
                    label="Forecast"
                    collapsed={collapsed}
                    route={routes.reportsPipeline()}
                    currentRoute={route}
                    hoveredSection={hoveredSection}
                    icon={<IconChartBar />}
                  />
                  {ungatedForActivityReporting(currentUser) && (
                    <DrawerNavSubItem
                      label="Activity Reporting"
                      collapsed={collapsed}
                      route={routes.reportsActivity()}
                      currentRoute={route}
                      hoveredSection={hoveredSection}
                      icon={<IconChartScatter />}
                    />
                  )}
                </Box>
              )}
              {ungatedForMarketing(currentUser) && (
                <Box
                  onMouseEnter={() => setHoveredSection(routes.marketing())}
                  onMouseLeave={() => setHoveredSection(null)}
                  sx={
                    !collapsed &&
                    [
                      sectionsByRoute[`/${route}`],
                      sectionsByRoute[hoveredSection],
                    ].includes(sectionsByRoute[routes.marketing()])
                      ? {
                          p: '4px',
                          borderRadius: '8px',
                          background: (theme) =>
                            [
                              sectionsByRoute[`/${route}`],
                              sectionsByRoute[hoveredSection],
                            ].includes(sectionsByRoute[routes.marketing()])
                              ? lighten(theme.palette.secondary.light, 0.6)
                              : 'transparent',
                          mt: 1,
                          transition: 'all 0.22s ease',
                        }
                      : {
                          transition: 'all 0.22s ease',
                        }
                  }
                >
                  <NavItem
                    icon={<IconReportAnalytics />}
                    label="Marketing"
                    route={routes.marketing()}
                    collapsed={collapsed}
                    hasSubItems={true}
                    selected={
                      !collapsed &&
                      [
                        sectionsByRoute[`/${route}`],
                        sectionsByRoute[hoveredSection],
                      ].includes(sectionsByRoute[routes.marketing()])
                    }
                  />

                  <DrawerNavSubItem
                    label="Segments"
                    collapsed={collapsed}
                    route={routes.marketing()}
                    currentRoute={route}
                    hoveredSection={hoveredSection}
                    icon={<IconSitemap />}
                  />
                </Box>
              )}
              {ungatedForCustomers(currentUser) && (
                <Box
                  onMouseEnter={() => setHoveredSection(routes.customerInbox())}
                  onMouseLeave={() => setHoveredSection(null)}
                  sx={
                    !collapsed &&
                    [
                      sectionsByRoute[`/${route}`],
                      sectionsByRoute[hoveredSection],
                    ].includes(sectionsByRoute[routes.customersCurrent()])
                      ? {
                          p: '4px',
                          borderRadius: '8px',
                          background: (theme) =>
                            [
                              sectionsByRoute[`/${route}`],
                              sectionsByRoute[hoveredSection],
                            ].includes(
                              sectionsByRoute[routes.customersCurrent()]
                            )
                              ? lighten(theme.palette.secondary.light, 0.6)
                              : 'transparent',
                          mt: 1,
                          transition: 'all 0.22s ease',
                        }
                      : {
                          transition: 'all 0.22s ease',
                        }
                  }
                >
                  <NavItem
                    icon={<IconHeartHandshake />}
                    label="Customers"
                    route={routes.customersCurrent()}
                    collapsed={collapsed}
                    hasSubItems={true}
                    selected={
                      !collapsed &&
                      [
                        sectionsByRoute[`/${route}`],
                        sectionsByRoute[hoveredSection],
                      ].includes(sectionsByRoute[routes.customersCurrent()])
                    }
                  />
                  <DrawerNavSubItem
                    label="Current Customers"
                    collapsed={collapsed}
                    route={routes.customersCurrent()}
                    currentRoute={route}
                    hoveredSection={hoveredSection}
                    icon={<IconCoins />}
                  />
                </Box>
              )}
              {ungatedForProduct(currentUser) && (
                <Box
                  onMouseEnter={() => setHoveredSection(routes.product())}
                  onMouseLeave={() => setHoveredSection(null)}
                  sx={
                    !collapsed &&
                    [
                      sectionsByRoute[`/${route}`],
                      sectionsByRoute[hoveredSection],
                    ].includes(sectionsByRoute[routes.product()])
                      ? {
                          p: '4px',
                          borderRadius: '8px',
                          background: (theme) =>
                            [
                              sectionsByRoute[`/${route}`],
                              sectionsByRoute[hoveredSection],
                            ].includes(sectionsByRoute[routes.product()])
                              ? lighten(theme.palette.secondary.light, 0.6)
                              : 'transparent',
                          mt: 1,
                          transition: 'all 0.22s ease',
                        }
                      : {
                          transition: 'all 0.22s ease',
                        }
                  }
                >
                  <NavItem
                    icon={<IconBuildingStore />}
                    label="Product"
                    route={routes.product()}
                    collapsed={collapsed}
                    hasSubItems={true}
                    selected={
                      !collapsed &&
                      [
                        sectionsByRoute[`/${route}`],
                        sectionsByRoute[hoveredSection],
                      ].includes(sectionsByRoute[routes.product()])
                    }
                  />

                  <DrawerNavSubItem
                    label="Feature Requests"
                    collapsed={collapsed}
                    route={routes.product()}
                    currentRoute={route}
                    hoveredSection={hoveredSection}
                    icon={<IconLaurelWreath />}
                  />
                </Box>
              )}
              <Box
                onMouseEnter={() => setHoveredSection(routes.recordings())}
                onMouseLeave={() => setHoveredSection(null)}
                sx={
                  !collapsed &&
                  [
                    sectionsByRoute[`/${route}`],
                    sectionsByRoute[hoveredSection],
                  ].includes(sectionsByRoute[routes.recordings()])
                    ? {
                        p: '4px',
                        borderRadius: '8px',
                        background: (theme) =>
                          [
                            sectionsByRoute[`/${route}`],
                            sectionsByRoute[hoveredSection],
                          ].includes(sectionsByRoute[routes.recordings()])
                            ? lighten(theme.palette.secondary.light, 0.6)
                            : 'transparent',
                        mt: 1,
                        transition: 'all 0.22s ease',
                      }
                    : {
                        transition: 'all 0.22s ease',
                      }
                }
              >
                <NavItem
                  icon={<IconServer />}
                  label="Data"
                  route={routes.recordings()}
                  collapsed={collapsed}
                  hasSubItems={true}
                  selected={
                    !collapsed &&
                    [
                      sectionsByRoute[`/${route}`],
                      sectionsByRoute[hoveredSection],
                    ].includes(sectionsByRoute[routes.recordings()])
                  }
                />
                <DrawerNavSubItem
                  label="Recordings"
                  collapsed={collapsed}
                  route={routes.recordings()}
                  currentRoute={route}
                  hoveredSection={hoveredSection}
                  icon={<IconMicrophone />}
                />
                <DrawerNavSubItem
                  label="Actions"
                  collapsed={collapsed}
                  route={routes.actions()}
                  currentRoute={route}
                  hoveredSection={hoveredSection}
                  icon={<IconBolt />}
                />
                <DrawerNavSubItem
                  label="Context"
                  collapsed={collapsed}
                  route={routes.context()}
                  currentRoute={route}
                  hoveredSection={hoveredSection}
                  icon={<IconBubbleText />}
                />
                <DrawerNavSubItem
                  label="Organizations"
                  collapsed={collapsed}
                  route={routes.organizations()}
                  currentRoute={route}
                  hoveredSection={hoveredSection}
                  icon={<IconBuildingSkyscraper />}
                />
                <DrawerNavSubItem
                  label="People"
                  collapsed={collapsed}
                  route={routes.people()}
                  currentRoute={route}
                  hoveredSection={hoveredSection}
                  icon={<IconUser />}
                />
                <DrawerNavSubItem
                  label="Pages"
                  collapsed={collapsed}
                  route={routes.pages()}
                  currentRoute={route}
                  hoveredSection={hoveredSection}
                  icon={<IconFiles />}
                />
              </Box>
            </List>
            {userCoreContact && (
              <ProfileController
                userCoreContact={userCoreContact}
                drawerWidth={drawerWidth}
                collapsed={collapsed}
              />
            )}
          </Box>
          <Box className="main-content">
            <UserErrorRemediation />
            <WorkspaceInviteBanner workspaceInvites={workspaceInvites} />
            {children}
          </Box>
        </Box>
      </>
    )
  )
}

const Ga4ContentGroup = {
  today: 'Home',
  prospects: 'Sales',
  opportunities: 'Sales',
  recordings: 'Meetings',
  marketing: 'Marketing',
  customers: 'Customers',
  pages: 'Pages',
  objectDetail: 'Sales',
  people: 'Data',
  pipelines: 'Sales',
  context: 'Data',
  reviewMeeting: 'Meetings',
  pageDetail: 'Pages',
  workspaceMeetingRecording: 'Meetings',
  workspaceLibrary: 'Meetings',
  library: 'Meetings',
  home: 'Home',
  settings: 'Data',
  actions: 'Data',
  organizations: 'Data',
  reportsPipeline: 'Sales',
  customerInbox: 'Customers',
  customersCurrent: 'Customers',
  product: 'Product',
  marketingContent: 'Marketing',
  marketingCampaigns: 'Marketing',
}

const ModernLayout = ({ children }) => {
  const location = useLocation()
  const route = useRouteName()
  const { currentUser } = useAuth()

  useEffect(() => {
    const path = location?.pathname
    const payload = {
      hitType: 'pageview' as const,
      page: path,
      title: route,
      contentGroup1: Ga4ContentGroup[route] || 'Other',
    }

    if (path) {
      try {
        ReactGA.send({ ...payload, userId: currentUser?.id })
      } catch (e) {
        logger.error('GA tracking error', e as Error, {
          location: path,
        })
      }
    }
  }, [location?.pathname, route, currentUser?.id])

  return (
    <DayProvider>
      <AvatarCacheProvider>
        <ObjectCacheProvider>
          <ModernLayoutInner>{children}</ModernLayoutInner>
        </ObjectCacheProvider>
      </AvatarCacheProvider>
    </DayProvider>
  )
}

export default ModernLayout
