import { useCallback, memo } from 'react'

import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material'
import {
  IconCopy,
  IconFile,
  IconHistory,
  IconMailShare,
  IconPencil,
  IconSparkles,
  IconWorld,
} from '@tabler/icons-react'
import toast from 'react-hot-toast'
import type { Page } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import EmbeddedAiPageEditor from 'src/components/Page/EmbeddedAiPageEditor/EmbeddedAiPageEditor'
import PageActions from 'src/components/Page/PageActions/PageActions'
import PageShare from 'src/components/Page/PageShare/PageShare'
import Row from 'src/components/Row/Row'
import { PageProvider } from 'src/hooks/usePage'
import { usePage } from 'src/hooks/usePage/usePage'
import { logger } from 'src/lib/logger'

import SidebarLoader from '../SidebarLoader/SidebarLoader'
import { actionButtonStyle } from '../styles'

const CREATE_EMAIL_DRAFT = gql`
  mutation CreateEmailDraft($draft: GmailDraftInput!) {
    createGmailDraft(draft: $draft)
  }
`

const ellipsizedSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: `${8 * 69}px`,
}

const SidebarPageHeader = memo(({ onClose }: { onClose: () => void }) => {
  const {
    page,
    showPrompt,
    setShowPrompt,
    showVersionHistory,
    setShowVersionHistory,
    handleUpdatePage,
    refetch,
    ungatedForButtons,
  } = usePage()

  const [createEmailDraft] = useMutation(CREATE_EMAIL_DRAFT)

  const handleTogglePrompt = useCallback(() => {
    setShowPrompt((prev) => !prev)
  }, [setShowPrompt])

  const handleToggleVersionHistory = useCallback(() => {
    setShowVersionHistory((prev) => !prev)
  }, [setShowVersionHistory])

  const handleMaximize = useCallback(() => {
    navigate(routes.pageDetail({ id: page?.id }))
    onClose()
  }, [page?.id, onClose])

  const handleUpdatePageMeta = useCallback(
    (input: Partial<Page>) => {
      handleUpdatePage(input)
    },
    [handleUpdatePage]
  )

  const handleCreateWebPage = useCallback(() => {
    console.log('create web page')
  }, [])

  const handleDraftEmail = useCallback(() => {
    toast.promise(
      createEmailDraft({
        variables: {
          draft: {
            subject: 'test subject',
            body: 'test body',
            to: 'test@test.com',
          },
        },
      }),
      {
        loading: 'Creating email draft',
        success: 'Email draft created',
        error: 'Failed to create email draft',
      }
    )
  }, [createEmailDraft])

  const handleCopyToClipboard = useCallback(() => {
    const text = 'test text'
    navigator.clipboard.writeText(text)
    toast.success('Copied to clipboard')
  }, [])

  return (
    page && (
      <Box
        sx={{
          height: ungatedForButtons ? '96px' : '64px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Row
          sx={{
            px: '12px',
            justifyContent: 'space-between',
            height: '36px',
          }}
        >
          <Row
            gap={1}
            sx={{ height: '36px' }}
          >
            <IconFile
              size={24}
              style={{ flexShrink: 0 }}
            />
            <Typography
              variant="h2"
              sx={ellipsizedSx}
            >
              {page?.title}
            </Typography>
          </Row>
          <Row sx={{ justifyContent: 'flex-end', height: '100%' }}>
            <Tooltip title="Edit in full page view">
              <IconButton
                onClick={handleMaximize}
                sx={{
                  p: '4px',
                  borderRadius: '4px',
                }}
              >
                <IconPencil size={16} />
              </IconButton>
            </Tooltip>
            <PageShare
              page={page}
              updatePage={handleUpdatePageMeta}
            />
            <PageActions
              page={page}
              refetch={refetch}
              orientation={'vertical'}
            />
          </Row>
        </Row>
        {ungatedForButtons && (
          <Row
            sx={{
              px: '12px',
              mt: 1,
            }}
            gap={0.5}
          >
            <Chip
              size="small"
              variant="outlined"
              label={showPrompt ? 'Hide revise' : 'Revise with Day.ai'}
              onClick={handleTogglePrompt}
              sx={actionButtonStyle}
              icon={<IconSparkles />}
            />
            <Chip
              size="small"
              variant="outlined"
              label={
                showVersionHistory ? 'Hide version history' : 'Version history'
              }
              onClick={handleToggleVersionHistory}
              sx={actionButtonStyle}
              icon={<IconHistory size={16} />}
              className={showVersionHistory ? 'selected' : ''}
            />
            <Chip
              size="small"
              variant="outlined"
              label={'Create web page'}
              onClick={handleCreateWebPage}
              sx={actionButtonStyle}
              icon={<IconWorld />}
            />
            <Chip
              size="small"
              variant="outlined"
              label={'Draft email'}
              onClick={handleDraftEmail}
              sx={actionButtonStyle}
              icon={<IconMailShare />}
            />
            <Chip
              size="small"
              variant="outlined"
              label={'Copy'}
              onClick={handleCopyToClipboard}
              sx={actionButtonStyle}
              icon={<IconCopy />}
            />
          </Row>
        )}
      </Box>
    )
  )
})

const SidebarPageContent = () => {
  const { token, contextStatus, ungatedForButtons } = usePage()
  logger.dev('SidebarPageContent', {
    token,
    contextStatus,
    ungatedForButtons,
  })
  return token ? (
    <Box
      sx={{
        height: ungatedForButtons ? 'calc(100% - 96px)' : '100%',
      }}
    >
      <EmbeddedAiPageEditor />
    </Box>
  ) : (
    <SidebarLoader />
  )
}

const SidebarLayoutPage = ({
  crmObject,
  onClose,
}: {
  crmObject: any
  onClose: () => void
}) => {
  const pageId = crmObject.objectId
  const workspaceId = crmObject.workspaceId

  const boxSx = {
    height: '100%',
    overflow: 'hidden',
  }

  return (
    pageId &&
    workspaceId && (
      <Box
        sx={boxSx}
        key={pageId}
      >
        <PageProvider
          pageId={pageId}
          workspaceId={workspaceId}
        >
          <Box sx={boxSx}>
            <SidebarPageHeader onClose={onClose} />
            <SidebarPageContent />
          </Box>
        </PageProvider>
      </Box>
    )
  )
}

export default SidebarLayoutPage
