import { useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material'
import {
  IconThumbDown,
  IconThumbDownFilled,
  IconThumbUpFilled,
  IconThumbUp,
} from '@tabler/icons-react'

import { useMutation, useQuery } from '@redwoodjs/web'

import Row from 'src/components/Row/Row'

const GET_OBJECT_FEEDBACK_FOR_USER = gql`
  query ObjectFeedbackForUser(
    $objectType: String!
    $objectId: String!
    $workspaceId: String!
  ) {
    objectFeedbackForUser(
      objectType: $objectType
      objectId: $objectId
      workspaceId: $workspaceId
    ) {
      id
      path
      version
      positive
      text
      createdAt
      updatedAt
      resolvedAt
      userId
      workspaceId
      objectType
      objectId
    }
  }
`

const CREATE_DATA_FEEDBACK = gql`
  mutation CreateDataFeedback($input: CreateDataFeedbackInput!) {
    createDataFeedback(input: $input) {
      id
      path
      version
      positive
      text
    }
  }
`

const codeStyle = {
  letterSpacing: '-0.3px',
  fontFamily: 'Fira Code !important',
  fontWeight: 500,
}

export type ObjectFeedbackProps = {
  objectType: string
  objectId: string
  workspaceId: string
  path: string
  version: string | number
  onFeedbackSubmitted?: () => void
  /** Whether to show feedback input in a dialog or inline. Defaults to 'dialog' */
  variant?: 'dialog' | 'inline'
}

const ObjectFeedback = ({
  objectType,
  objectId,
  workspaceId,
  path,
  version,
  onFeedbackSubmitted,
  variant = 'dialog',
}: ObjectFeedbackProps) => {
  const [thumbsDownNote, setThumbsDownNote] = useState('')
  const [showThumbsDownNote, setShowThumbsDownNote] = useState(false)
  const [optimisticPositive, setOptimisticPositive] = useState<boolean | null>(
    null
  )

  const { data: objectFeedbackForUserData, refetch } = useQuery(
    GET_OBJECT_FEEDBACK_FOR_USER,
    {
      variables: {
        objectType,
        objectId,
        workspaceId,
      },
      skip: !objectType || !objectId || !workspaceId,
    }
  )

  const [createDataFeedback] = useMutation(CREATE_DATA_FEEDBACK, {
    onCompleted: () => {
      refetch()
      onFeedbackSubmitted?.()
    },
  })

  const handleThumbsUp = async () => {
    setOptimisticPositive(true)
    await createDataFeedback({
      variables: {
        input: {
          positive: true,
          text: 'This seems right',
          objectType,
          objectId,
          path,
          workspaceId,
          version,
        },
      },
    })
  }

  const handleThumbsDown = async () => {
    setOptimisticPositive(false)
    await createDataFeedback({
      variables: {
        input: {
          positive: false,
          text: thumbsDownNote,
          objectType,
          objectId,
          path,
          workspaceId,
          version,
        },
      },
    })
    clearThumbsDownNote()
  }

  const clearThumbsDownNote = () => {
    setThumbsDownNote('')
    setShowThumbsDownNote(false)
  }

  const previousFeedback =
    objectFeedbackForUserData?.objectFeedbackForUser?.find(
      (feedback) => feedback.path === path && feedback.version === version
    )

  // Use optimistic state if available, otherwise use server state
  const isPositive =
    optimisticPositive !== null
      ? optimisticPositive
      : previousFeedback?.positive

  const renderInlineFeedback = () => (
    <Box sx={{ px: 1, pb: 2 }}>
      <TextField
        value={thumbsDownNote}
        fullWidth={true}
        onChange={(e) => setThumbsDownNote(e.target.value)}
        multiline={true}
        rows={4}
        sx={{
          '& .MuiInputBase-root': {
            fontSize: '12px',
            color: '#EDECEE',
            fontWeight: 500,
            border: '1px solid #636365',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#21202E',
            },
            ...codeStyle,
          },
        }}
      />
      <Row
        sx={{
          justifyContent: 'flex-end',
          mt: '-28px',
          mr: '4px',
          '& .MuiButtonBase-root': {
            fontSize: '10px',
            textTransform: 'none',
            color: '#bebdc1',
            fontWeight: 500,
            px: '7px',
            minHeight: '24px',
            height: '24px',
            '&:hover': {
              backgroundColor: '#21202E',
            },
          },
          ...codeStyle,
        }}
      >
        <Button onClick={clearThumbsDownNote}>Cancel</Button>
        <Button
          sx={{
            background: '#63f9ca',
            color: '#262b36 !important',
            '&:hover': {
              background: '#56d3ab !important',
            },
            ...codeStyle,
          }}
          onClick={handleThumbsDown}
        >
          Save
        </Button>
      </Row>
    </Box>
  )

  const renderDialogFeedback = () => (
    <Dialog
      open={showThumbsDownNote}
      onClose={clearThumbsDownNote}
      aria-labelledby="feedback-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="feedback-dialog-title">
        What should we review?
      </DialogTitle>
      <DialogContent>
        <TextField
          value={thumbsDownNote}
          fullWidth
          onChange={(e) => setThumbsDownNote(e.target.value)}
          multiline
          rows={4}
          autoFocus
          placeholder="Tell us what needs to be reviewed..."
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={clearThumbsDownNote}>Cancel</Button>
        <Button
          onClick={handleThumbsDown}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <Box>
      <Row gap={'6px'}>
        <Row sx={{ pr: '4px', mt: '4px' }}>
          <Tooltip
            title="This seems right"
            arrow={true}
            placement="top"
          >
            <IconButton
              sx={{ p: '2px' }}
              onClick={handleThumbsUp}
            >
              {isPositive ? (
                <IconThumbUpFilled
                  size={14}
                  color="#56D3AB"
                  stroke={1.5}
                  style={{
                    marginTop: '-4px',
                    transform: 'scaleX(-1)',
                  }}
                />
              ) : (
                <IconThumbUp
                  size={14}
                  color="#56D3AB"
                  stroke={1.5}
                  style={{
                    marginTop: '-4px',
                    transform: 'scaleX(-1)',
                  }}
                />
              )}
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Day.ai should review this"
            arrow={true}
            placement="top"
          >
            <IconButton
              sx={{ p: '2px' }}
              onClick={() => setShowThumbsDownNote(true)}
            >
              {isPositive === false ? (
                <IconThumbDownFilled
                  size={14}
                  color="#f47e6f"
                  stroke={1.5}
                  style={{
                    marginTop: '4px',
                    transform: 'scaleX(-1)',
                  }}
                />
              ) : (
                <IconThumbDown
                  size={14}
                  stroke={1.5}
                  color="#f47e6f"
                  style={{
                    marginTop: '4px',
                  }}
                />
              )}
            </IconButton>
          </Tooltip>
        </Row>
      </Row>

      {showThumbsDownNote && variant === 'inline' && renderInlineFeedback()}
      {variant === 'dialog' && renderDialogFeedback()}
    </Box>
  )
}

export default ObjectFeedback
