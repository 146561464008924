import { useCallback, useMemo, useState } from 'react'

import { Typography } from '@mui/material'
import {
  DataGridPremium,
  gridPreferencePanelStateSelector,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { LicenseInfo } from '@mui/x-license'

import Row from 'src/components/Row/Row'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import { DayDataGridToolbar } from '../dataTables'
LicenseInfo.setLicenseKey(process.env.MUI_PREMIUM_LICENSE_KEY)

const slugifyFilename = (str: string) => {
  return str.toLowerCase().replace(/[^a-z0-9]+/g, '-')
}

const DayTable = ({
  id,
  viewsObjectType = null,
  workspaceId = null,
  title,
  subtitle = null,
  data,
  loading,
  initialState = {},
  tabSets = null,
  onRowClick,
  rowObjectName = 'rows',
  navItems = [],
  rowObjectNameSingular = 'row',
  showStats = true,
  headerLevel = 2,
  csvFilename = null,
  showFilters = true,
  actionButtons = [],
}) => {
  const [filterButtonEl, setFilterButtonEl] =
    useState<HTMLButtonElement | null>(null)
  const [columnMenuEl, setColumnMenuEl] = useState<HTMLDivElement | null>(null)

  const apiRef = useGridApiRef()

  //log({ passedRef: apiRef, init: initialized.current, ref: apiRef.current })

  const height = '100%'
  const width = '100%'

  logger.dev('Render DayTable')

  const memoizedLoading = useMemo(
    () => (loading && !(data.rows?.length > 0)) || data.waitingToQuery,
    [loading, data]
  )

  const hasViews = useMemo(() => {
    return viewsObjectType &&
      Object.values(NativeObjectTypes).includes(viewsObjectType) &&
      workspaceId
      ? true
      : false
  }, [viewsObjectType, workspaceId])

  const dayTableStyle = useMemo(() => {
    return {
      height,
      width,
      '& .MuiDataGrid-toolbarContainer': {
        //border: 'none',
      },
      '.MuiDataGrid-cell': {
        //border: 'none',
        cursor: onRowClick ? 'pointer' : 'default',
      },
      '&.MuiDataGrid-root': {
        border: 'none',
        borderWidth: '0px',
        '--DataGrid-containerBackground': (theme) =>
          theme.palette.background.paper,
      },
      '.MuiDataGrid-main': {
        border: (theme) => `1px solid ${theme.palette.divider}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
        borderTopLeftRadius: hasViews ? '0px' : '4px',
        backgroundColor: (theme) => theme.palette.background.paper,
        '& .MuiDataGrid-virtualScroller': {
          //width: width - 2,
          '& .MuiDataGrid-virtualScrollerContent': {},
        },
        '& .MuiDataGrid-topContainer': {
          height: '42px',
        },
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 600,
        fontSize: '12px',
        letterSpacing: '-0.4px',
      },
      '& .MuiDataGrid-cell': {
        fontSize: '11px',
        letterSpacing: '-0.22px',
        opacity: 0.8,
        color: (theme) => theme.palette.text.primary,
        '& .MuiChip-sizeSmall': {},
        '& .MuiDataGrid-groupingCriteriaCell': {
          fontSize: '12px',
          letterSpacing: '-0.17px',
          opacity: 0.8,
          fontWeight: 600,
          color: (theme) => theme.palette.text.primary,
          '& .MuiDataGrid-groupingCriteriaCellToggle': {
            mr: 0,
            ml: '-8px',
          },
        },
      },
      '& .MuiDataGrid-aggregationColumnHeaderLabel': {
        fontWeight: 500,
        fontSize: '9px',
        letterSpacing: '-0.4px',
        opacity: 0.5,
      },
      '& .MuiDataGrid-menu': {},
      '& .MuiDataGrid-panelWrapper': {
        '& .MuiDataGrid-panelHeader': {},
        '& .MuiDataGrid-panelContent': {
          '& .MuiDataGrid-columnsPanel': {
            '& .MuiDataGrid-columnsPanelRow': {
              '& .MuiFormControlLabel-root': {
                '& .MuiSwitch-root': {
                  '& .MuiSwitch-switchBase': {},
                  '& .MuiSwitch-track': {},
                },
                '& .MuiTypography-root': {},
              },
            },
          },
          '& .MuiDataGrid-panelFooter': {},
        },
      },
    }
  }, [hasViews, onRowClick])

  const memoizedOnRowClick = useCallback(onRowClick, [onRowClick])

  return (
    <DataGridPremium
      apiRef={apiRef}
      rows={data.rows}
      columns={data.columns}
      hideFooterSelectedRowCount={true}
      loading={memoizedLoading}
      initialState={initialState}
      slots={{
        toolbar: DayDataGridToolbar,
        noResultsOverlay: () => (
          <Row sx={{ justifyContent: 'center', height: '100%', width: '100%' }}>
            <Typography variant="h4">{`No ${rowObjectName} match these filters`}</Typography>
          </Row>
        ),
        noRowsOverlay: () => (
          <Row sx={{ justifyContent: 'center', height: '100%', width: '100%' }}>
            <Typography variant="h4">{`No ${rowObjectName} to display`}</Typography>
          </Row>
        ),
        footerRowCount: (params) => {
          return (
            <Row gap={1}>
              {memoizedLoading ? (
                <Typography variant="h3">Loading {rowObjectName}...</Typography>
              ) : (
                <>
                  <Typography variant="h3">
                    {params.visibleRowCount}{' '}
                    {params.visibleRowCount === 1
                      ? rowObjectNameSingular || rowObjectName
                      : rowObjectName}
                  </Typography>
                  {params.visibleRowCount !== params.rowCount && (
                    <Typography
                      variant="h3"
                      sx={{ opacity: 0.5 }}
                    >
                      {Math.round(
                        (params.visibleRowCount / params.rowCount) * 100
                      )}
                      %
                    </Typography>
                  )}
                </>
              )}
            </Row>
          )
        },
      }}
      onRowClick={memoizedOnRowClick}
      sortingOrder={['desc', 'asc']}
      slotProps={{
        toolbar: {
          id,
          showColumnFilter: true,
          showFilter: showFilters,
          showQuickFilter: showFilters,
          showExport: showFilters,
          showDensitySelector: false,
          title,
          subtitle,
          navItems,
          headerLevel,
          viewsObjectType,
          workspaceId,
          tabSets,
          initialState,
          rowObjectName,
          showStats,
          setFilterButtonEl,
          setColumnMenuEl,
          showFilters,
          actionButtons,
          csvOptions: {
            fileName: slugifyFilename(
              csvFilename || `Day.ai ${rowObjectName || 'Export'}`
            ),
          },
        },
        panel: {
          anchorEl: () => {
            const preferencePanelState = gridPreferencePanelStateSelector(
              apiRef.current.state
            )
            if (preferencePanelState.open) {
              if (preferencePanelState.openedPanelValue === 'filters') {
                apiRef.current.hideColumnMenu()
                return filterButtonEl
              } else if (preferencePanelState.openedPanelValue === 'columns') {
                return columnMenuEl
              }
            }
            return null
          },
        },
      }}
      columnHeaderHeight={40}
      rowHeight={40}
      sx={dayTableStyle}
    />
  )
}

export default DayTable
