import { useContext } from 'react'

import { Box, Popover } from '@mui/material'

import ObjectTile from 'src/components/ObjectTile/ObjectTile'
import { DayContext } from 'src/lib/dayContext'

interface ObjectPreviewMenuProps {
  objectType: string
  objectId: string
  workspaceId: string
  anchorEl: HTMLElement | null
  open: boolean
  onClose: () => void
}

const ObjectPreviewMenu = ({
  objectType,
  objectId,
  workspaceId,
  anchorEl,
  open,
  onClose,
}: ObjectPreviewMenuProps) => {
  const { setSidebarObject } = useContext(DayContext)

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      slotProps={{
        paper: {
          elevation: 4,
          sx: {
            width: '320px',
            height: '72px',
            background: (theme) => theme.palette.background.paper,
            p: 1,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            marginBottom: '8px',
            overflow: 'hidden',
          },
        },
      }}
      onClick={() => {
        setSidebarObject({
          objectType,
          objectId,
          properties: {},
        })
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <ObjectTile
          objectType={objectType}
          objectId={objectId}
          workspaceId={workspaceId}
          size={48}
        />
      </Box>
    </Popover>
  )
}

export default ObjectPreviewMenu
