import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'

import {
  AvatarGroup,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  darken,
  lighten,
  DialogTitle,
  DialogActions,
} from '@mui/material'
import {
  RiAddLine,
  RiFlashlightLine,
  RiKanbanView2,
  RiPencilLine,
  RiSettingsLine,
  RiTableLine,
} from '@remixicon/react'
import dayjs from 'dayjs'
import { debounce } from 'lodash'
import { toast } from 'react-hot-toast'

import { useParams } from '@redwoodjs/router'
import { Metadata, useMutation, useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { setStoredPipelineId } from 'src/lib/localStorage'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import PipelineActions from '../PipelineActions/PipelineActions'
import Row from '../Row/Row'

import OpportunityCreateDialog from './OpportunityCreateDialog/OpportunityCreateDialog'
import PipelineBoard from './PipelineBoard/PipelineBoard'
import PipelineCreateInteractive from './PipelineCreateInteractive/PipelineCreateInteractive'
import PipelineEdit from './PipelineEdit/PipelineEdit'
import PipelineForecast from './PipelineForecast/PipelineForecast'
import PipelineList from './PipelineList/PipelineList'
import PipelineOppTypeFilter from './PipelineOppTypeFilter/PipelineOppTypeFilter'
import PipelineOwnerFilter from './PipelineOwnerFilter/PipelineOwnerFilter'
import PipelineTable from './PipelineTable/PipelineTable'
import PipelineTimePeriodFilter from './PipelineTimePeriodFilter/PipelineTimePeriodFilter'

export type Opportunity = {
  id: string
  title: string
  type: string
  ownerEmail: string
  ownerId: string
  expectedCloseDate: Date
  primaryPerson: any
  createdAt: Date
  updatedAt: Date
  currentStatus: string
  expectedRevenue: number
  domain: string
  organization: any
  stageId: string
  position: number
  workspaceId: string
  notes: any[]
}

type Stage = {
  id: string
  workspaceId: string
  pipelineId: string
  position: number
  title: string
  entranceCriteria: string[]
  likelihoodToClose: number
  opportunities: Opportunity[]
}

type Pipeline = {
  id: string
  workspaceId: string
  title: string
  stages: Stage[]
  ownerCoreContacts: any[]
  opportunityTypes: string[]
}

// interface PipelineCreateInput {
//   workspaceId: string
//   title: string
// }

export interface OpportunityUpsertInput {
  workspaceId: string
  stageId: string
  position: number
  title: string
  type: string
  ownerId: string
  ownerEmail: string
  expectedCloseDate: Date
  primaryPerson: string
  currentStatus: string
  expectedRevenue: number
  domain: string
}

// interface StageUpsertInput {
//   title: string
//   pipelineId: string
//   position: number
//   entranceCriteria: string[]
//   likelihoodToClose: number
// }

// interface PipelineUpdateInput {
//   title?: string
// }

const GET_PIPELINE = gql`
  query pipeline($id: String!, $workspaceId: String!) {
    pipeline(id: $id, workspaceId: $workspaceId) {
      id
      workspaceId
      title
      hasRevenue
      updatedAt
      opportunityTypes
      ownerEmails
      setupSteps
      type
      stages {
        id
        title
        description
        entranceCriteria
        likelihoodToClose
        type
        opportunities {
          id
          title
          ownerEmail
          ownerId
          expectedCloseDate
          expectedRevenue
          domain
          position
          status
          daysInStage
          suggestedStageType
          isGeneric
          isSuggested
          type
          roles {
            personEmail
            roles
          }
          goals {
            content
            source {
              sourceId
              sourceType
            }
          }
          impactOfChange {
            content
            source {
              sourceId
              sourceType
            }
          }
          budgetAndTimeline {
            content
            source {
              sourceId
              sourceType
            }
          }
          recommendedStage {
            expectedCloseDate
            expectedRevenue
            proofOfPayment
            readyToProgress
            reasoningforStage
            stageId
          }
          challenges {
            challenge
            solution
            source {
              sourceId
              sourceType
            }
          }
          risks {
            content
            source {
              sourceId
              sourceType
            }
          }
          competition {
            content
            source {
              sourceId
              sourceType
            }
          }
          decisionProcess {
            content
            source {
              sourceId
              sourceType
            }
          }
        }
        position
      }
    }
  }
`

const GET_GENERIC_OPPORTUNITIES = gql`
  query getGenericOpportunities($workspaceId: String!, $pipelineType: String!) {
    getGenericOpportunities(
      workspaceId: $workspaceId
      pipelineType: $pipelineType
    ) {
      id
      title
      type
      domain
      ownerEmail
      ownerId
      suggestedStageType
    }
  }
`

const UPDATE_PIPELINE_TITLE = gql`
  mutation updatePipeline($id: String!, $input: PipelineUpdateInput!) {
    updatePipeline(id: $id, input: $input) {
      id
      workspaceId
      title
      hasRevenue
      updatedAt
      opportunityTypes
      ownerEmails
      setupSteps
      type
      updatedAt
      stages {
        id
        title
        description
        entranceCriteria
        likelihoodToClose
        type
        opportunities {
          id
          title
          ownerEmail
          ownerId
          expectedCloseDate
          expectedRevenue
          domain
          position
          status
          daysInStage
          suggestedStageType
          isGeneric
          isSuggested
          type
          roles {
            personEmail
            roles
          }
          goals {
            content
            source {
              sourceId
              sourceType
            }
          }
          impactOfChange {
            content
            source {
              sourceId
              sourceType
            }
          }
          budgetAndTimeline {
            content
            source {
              sourceId
              sourceType
            }
          }
          recommendedStage {
            expectedCloseDate
            expectedRevenue
            proofOfPayment
            readyToProgress
            reasoningforStage
            stageId
          }
          challenges {
            challenge
            solution
            source {
              sourceId
              sourceType
            }
          }
          risks {
            content
            source {
              sourceId
              sourceType
            }
          }
          competition {
            content
            source {
              sourceId
              sourceType
            }
          }
          decisionProcess {
            content
            source {
              sourceId
              sourceType
            }
          }
        }
        position
      }
    }
  }
`

const MOVE_GENERIC_OPPORTUNITIES = gql`
  mutation moveGenericOpportunities(
    $workspaceId: String!
    $pipelineId: String!
  ) {
    moveGenericPipelineOpportunities(
      workspaceId: $workspaceId
      pipelineId: $pipelineId
    )
  }
`

const APPROVE_GENERIC_OPPORTUNITY = gql`
  mutation approveGenericOpportunity(
    $workspaceId: String!
    $opportunityId: String!
  ) {
    approveGenericOpportunity(
      workspaceId: $workspaceId
      opportunityId: $opportunityId
    )
  }
`

const DECLINE_GENERIC_OPPORTUNITY = gql`
  mutation declineGenericOpportunity(
    $workspaceId: String!
    $opportunityId: String!
    $reason: String!
  ) {
    declineGenericOpportunity(
      workspaceId: $workspaceId
      opportunityId: $opportunityId
      reason: $reason
    )
  }
`

const UPDATE_OPPORTUNITY_FROM_PIPELINE = gql`
  mutation updateOpportunity($input: OpportunityUpdateInput!) {
    updateOpportunity(input: $input) {
      id
    }
  }
`

const GET_PIPELINE_ACTIONS = gql`
  query actionsForPipeline($pipelineId: String!, $workspaceId: String!) {
    actionsForPipeline(pipelineId: $pipelineId, workspaceId: $workspaceId) {
      id
      workspaceId
      createdAt
      updatedAt
      title
      reasoning
      type
      priority
      people
      organizations
      opportunityIds
      channel {
        id
        label
        type
        accountId
      }
      status {
        id
        label
        updatedAt
      }
      assignedAt
      owner {
        id
        email
      }
      draft {
        title
        body
      }
      source {
        label
        type
        id
      }
      pipelineType
      timeframe {
        dueDate
        reminderDate
        updatedAt
      }
      opportunity
    }
  }
`
type PipelineViewType = 'board' | 'table' | 'list' | 'forecast' | 'actions'

export const toggleIconStyle = { height: '18px', width: '18px', flexShrink: 0 }
const stageColors = {
  0: '#4CAF50',
  1: '#8BC34A',
  2: '#FF5722',
  3: '#E91E63',
  4: '#9C27B0',
  5: '#673AB7',
  6: '#3F51B5',
  7: '#2196F3',
  8: '#03A9F4',
  9: '#00BCD4',
  10: '#009688',
}

const suggestedStageColors = [
  '#4CAF50',
  '#8BC34A',
  '#FF5722',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
]

export const getStageColor = (stagePosition, suggested = false) => {
  return suggested
    ? suggestedStageColors[stagePosition % 10]
    : stageColors[stagePosition % 10]
}

export const getStageStyle = (stagePosition, suggested = false) => {
  const color = suggested
    ? suggestedStageColors[stagePosition % 10]
    : stageColors[stagePosition % 10]
  return {
    color: darken(color, 0.3),
    textAlign: 'left',
    justifyContent: 'left',
    border: `1px solid ${lighten(color, 0.7)}`,
    background: lighten(color, 0.92),
    fontWeight: 500,
    py: '4px',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      background: lighten(color, 0.7),
      border: `1px solid ${lighten(color, 0.3)}`,
    },
  }
}

export interface PipelineFilters {
  id?: string
  view?: PipelineViewType
  forecastTimePeriod?: 'month' | 'quarter' | 'year'
  owner?: string
  oppType?: string
  table?: {
    pinnedColumns?: string[]
    columns?: {
      visibility?: string[]
      orderedFields?: string[]
    }
    sorting?: {
      field?: string
      direction?: 'asc' | 'desc'
    }
    filters?: any
  }
}

export const pipelineFilterWidths = {
  xs: '64px',
  sm: '96px',
  md: '128px',
  lg: '196px',
  xl: '256px',
}

export const shouldShowOpp = (opportunity, filters) => {
  let shouldShow = true

  if (
    !(
      !filters?.owner ||
      filters?.owner === 'allOwners' ||
      opportunity?.ownerEmail === filters.owner
    )
  ) {
    shouldShow = false
  }

  if (
    !(
      !filters?.oppType ||
      filters?.oppType === 'allOppTypes' ||
      opportunity?.type === filters.oppType
    )
  ) {
    shouldShow = false
  }

  return shouldShow
}

const Pipeline = ({ id, workspaceId, actions = <></> }) => {
  const pipelineViewStorageKey = `pipeline_${id}`
  const params = useParams()
  const { opportunityId } = params

  const { setSidebarObject, orgsByDomain } = useContext(DayContext)
  const [view, setView] = useState<PipelineViewType | null>('board')
  const [editing, setEditing] = useState<string | null>(null)
  const [title, setTitle] = useState<string>('')
  const [titleFocused, setTitleFocused] = useState<boolean>(false)
  const [filters, setFilters] = useState<PipelineFilters | null>(null)
  const [board, setBoard] = useState(null)
  const [stageForCreate, setStageForCreate] = useState<string | null>(null)
  const [updatedAt, setUpdatedAt] = useState<Date | null>(new Date(0))
  const [actionsHidden, setActionsHidden] = useState([])
  const [forceSetup] = useState(false)
  const [oppOpenFromParams, setOppOpenFromParams] = useState<string | null>(
    opportunityId
  )
  const [genericOpportunities, setGenericOpportunities] = useState<
    Opportunity[]
  >([])

  const workspaceIdToUse = workspaceId

  const {
    data: pipelineData,
    refetch,
    loading: pipelineLoading,
  } = useQuery(GET_PIPELINE, {
    variables: { id, workspaceId: workspaceIdToUse },
    skip: !id || !workspaceIdToUse,
    onCompleted: (data) => {
      if (new Date(data.pipeline.updatedAt).getTime() > updatedAt.getTime()) {
        setUpdatedAt(data.pipeline.updatedAt)
      }
    },
  })

  const { refetch: refetchGenericOpportunities } = useQuery(
    GET_GENERIC_OPPORTUNITIES,
    {
      variables: {
        workspaceId: workspaceIdToUse,
        pipelineType: pipelineData?.pipeline?.type,
      },
      skip: !workspaceIdToUse || !pipelineData?.pipeline?.type,
      onCompleted: (genericOpportunitiesData) => {
        const genericOpps =
          genericOpportunitiesData.getGenericOpportunities?.filter((opp) => {
            return (
              opp.suggestedStageType &&
              new Set(
                pipelineData?.pipeline?.stages.map((stage) => stage.type)
              ).has(opp.suggestedStageType)
            )
          })
        setGenericOpportunities(genericOpps)
        if (genericOpps.length > 0 && !requiresSetup) {
          handleMoveGenericOpportunities()
        }
      },
    }
  )

  const { data: actionsData, refetch: refetchPipelineActions } = useQuery(
    GET_PIPELINE_ACTIONS,
    {
      variables: { pipelineId: id, workspaceId: workspaceIdToUse },
      skip: !id || !workspaceIdToUse,
    }
  )

  const [moveGenericOpportunities] = useMutation(MOVE_GENERIC_OPPORTUNITIES)

  const handleMoveGenericOpportunities = async () => {
    await moveGenericOpportunities({
      variables: { workspaceId: workspaceIdToUse, pipelineId: id },
      onCompleted: () => {
        refetchGenericOpportunities()
        refetch()
        refetchPipelineActions()
      },
    })
  }

  const [approveGenericOpportunity] = useMutation(APPROVE_GENERIC_OPPORTUNITY)
  const [declineGenericOpportunity] = useMutation(DECLINE_GENERIC_OPPORTUNITY)

  const handleApproveGenericOpportunity = useCallback(
    async (oppId: string) => {
      toast.promise(
        approveGenericOpportunity({
          variables: {
            workspaceId: workspaceIdToUse,
            opportunityId: oppId,
          },
        }),
        {
          loading: 'Approving opportunity...',
          success: () => {
            refetch()
            return 'Opportunity approved!'
          },
          error: 'Error approving opportunity',
        }
      )
    },
    [approveGenericOpportunity, workspaceIdToUse]
  )

  const [declineDialogOpen, setDeclineDialogOpen] = useState(false)
  const [declineReason, setDeclineReason] = useState('')
  const [pendingDeclineOppId, setPendingDeclineOppId] = useState<string | null>(
    null
  )

  const declineDialogHandlers = useMemo(
    () => ({
      open: (oppId: string) => {
        setPendingDeclineOppId(oppId)
        setDeclineDialogOpen(true)
      },
      close: () => {
        setDeclineDialogOpen(false)
        setDeclineReason('')
        setPendingDeclineOppId(null)
      },
      setReason: (reason: string) => {
        setDeclineReason(reason)
      },
    }),
    []
  )

  const handleDeclineGenericOpportunity = useCallback(
    async (oppId: string) => {
      declineDialogHandlers.open(oppId)
    },
    [declineDialogHandlers]
  )

  const handleCancelDecline = useCallback(() => {
    declineDialogHandlers.close()
  }, [declineDialogHandlers])

  const handleConfirmDecline = useCallback(async () => {
    if (!pendingDeclineOppId || !declineReason.trim()) return

    toast.promise(
      declineGenericOpportunity({
        variables: {
          workspaceId: workspaceIdToUse,
          opportunityId: pendingDeclineOppId,
          reason: declineReason.trim(),
        },
      }),
      {
        loading: 'Declining opportunity...',
        success: () => {
          refetch()
          return 'Opportunity declined!'
        },
        error: 'Error declining opportunity',
      }
    )

    // Reset state
    setDeclineDialogOpen(false)
    setDeclineReason('')
    setPendingDeclineOppId(null)
  }, [
    declineGenericOpportunity,
    workspaceIdToUse,
    pendingDeclineOppId,
    declineReason,
  ])

  const previousRequiresSetupRef = useRef<boolean | null>(null)

  const requiresSetup = useMemo(() => {
    if (!pipelineData) return false
    if (forceSetup) return true

    const hasOpps = pipelineData?.pipeline?.stages?.some((stage) => {
      return stage.opportunities?.length > 0
    })
    if (hasOpps) return false

    const hasSetupSteps = pipelineData?.pipeline?.setupSteps?.length > 0
    if (!hasSetupSteps) return true
    const setupIsSufficient = pipelineData?.pipeline?.setupSteps?.every(
      (step) => {
        return step.sufficient
      }
    )

    return !setupIsSufficient
  }, [pipelineData, forceSetup])

  useEffect(() => {
    // Check if value changed from true to false
    if (
      pipelineData &&
      previousRequiresSetupRef.current === true &&
      requiresSetup === false
    ) {
      logger.dev('Pipeline setup completed, refreshing data...')
      refetch()
    }

    // Update the previous value
    previousRequiresSetupRef.current = requiresSetup
  }, [requiresSetup, refetch])

  const pipelineActions = useMemo(() => {
    const hydratedActions = []
    for (const action of actionsData?.actionsForPipeline || []) {
      if (
        ['UNREAD', 'READ', 'SNOOZED'].includes(action.status.id) &&
        action.type !== 'FEATURE_REQUEST' &&
        !actionsHidden.includes(action.id)
      ) {
        hydratedActions.push(action)
      }
    }
    return hydratedActions
  }, [actionsData, actionsHidden])

  useEffect(() => {
    if (id && workspaceIdToUse) {
      setStoredPipelineId(workspaceIdToUse, id)
    }
  }, [id, workspaceIdToUse])

  const handleActionAction = (actionActionArgs) => {
    const actionId = actionActionArgs.id
    if (['COMPLETED', 'SNOOZED', 'DELETED'].includes(actionActionArgs.status)) {
      setActionsHidden((prev) => [...prev, actionId])
    }
    setTimeout(() => {
      refetchPipelineActions()
    }, 3000)
  }

  const [updatePipeline, { loading: updatePipelineLoading }] = useMutation(
    UPDATE_PIPELINE_TITLE,
    {
      onCompleted: (data) => {
        if (data.pipeline.updatedAt > updatedAt) {
          setUpdatedAt(data.pipeline.updatedAt)
        }
      },
    }
  )

  const debouncedUpdatePipeline = useMemo(
    () => debounce(updatePipeline, 700),
    [updatePipeline]
  )

  const [updateOpportunity] = useMutation(UPDATE_OPPORTUNITY_FROM_PIPELINE, {
    onCompleted: (data) => {
      logger.dev({ data })
    },
  })

  const handleUpdateOpportunity = (opportunity) => {
    setUpdatedAt(new Date())
    updateOpportunity({
      variables: {
        input: {
          ...opportunity,
          pipelineId: id,
          workspaceId: workspaceIdToUse,
        },
      },
    })
  }

  const handleChangeTitle = useCallback(
    (title: string) => {
      debouncedUpdatePipeline({
        variables: {
          id: pipelineData.pipeline.id,
          input: {
            title,
            workspaceId: pipelineData.pipeline.workspaceId,
            id: pipelineData.pipeline.id,
          },
        },
      })
    },
    [
      pipelineData?.pipeline.id,
      pipelineData?.pipeline.workspaceId,
      debouncedUpdatePipeline,
    ]
  )

  useEffect(() => {
    if (title && pipelineData?.pipeline.title != title) handleChangeTitle(title)
  }, [title, pipelineData?.pipeline.title, handleChangeTitle])

  const handleChangeOwnerFilter = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        owner: event.target.value,
      }
    })
  }

  const handleChangeTypeFilter = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        oppType: event.target.value,
      }
    })
  }

  const handleTimePeriodChange = (event) => {
    setFilters((prev) => {
      return {
        ...prev,
        forecastTimePeriod: event.target.value,
      }
    })
  }

  const handleTableFiltersChange = (tableFilters) => {
    setFilters((prev) => ({
      ...prev,
      table: {
        ...prev?.table,
        pinnedColumns:
          'pinnedColumns' in tableFilters
            ? tableFilters.pinnedColumns
            : prev?.table?.pinnedColumns,
        columns: {
          visibility:
            tableFilters?.columns &&
            'visibility' in (tableFilters?.columns ?? {})
              ? tableFilters.columns.visibility
              : prev?.table?.columns?.visibility,
          orderedFields:
            tableFilters?.columns &&
            'orderedFields' in (tableFilters?.columns ?? {})
              ? tableFilters.columns.orderedFields
              : prev?.table?.columns?.orderedFields,
        },
        sorting:
          'sorting' in tableFilters
            ? tableFilters.sorting
            : prev?.table?.sorting,
        filters:
          'filters' in tableFilters
            ? tableFilters.filters
            : prev?.table?.filters,
      },
    }))
  }

  const handleViewChange = (
    _: React.MouseEvent<HTMLElement>,
    newView: PipelineViewType
  ) => {
    if (newView) {
      setView(newView)
      const storageValue = JSON.parse(
        localStorage.getItem(pipelineViewStorageKey) || '{}'
      )

      localStorage.setItem(
        pipelineViewStorageKey,
        JSON.stringify({
          ...storageValue,
          view: newView,
        })
      )
    }
  }

  useEffect(() => {
    if (oppOpenFromParams) {
      setOppOpenFromParams(null)
      handleViewChange(null, 'table')
      setSidebarObject({
        objectType: NativeObjectTypes.Opportunity,
        objectId: oppOpenFromParams,
        properties: {
          id: oppOpenFromParams,
        },
      })
    }
  }, [oppOpenFromParams, setSidebarObject])

  useEffect(() => {
    const storageValue = JSON.parse(
      localStorage.getItem(pipelineViewStorageKey) || '{}'
    )

    if (id) {
      if (filters) {
        localStorage.setItem(
          pipelineViewStorageKey,
          JSON.stringify({
            ...storageValue,
            filters: filters,
          })
        )
      } else {
        if (storageValue.filters) {
          setFilters({
            ...storageValue.filters,
          })
        }
      }

      // Set view based on storage or default to 'board'
      if (storageValue.view) {
        setView(storageValue.view)
      } else if (storageValue?.filters?.view) {
        setView(storageValue.filters.view)
      } else if (storageValue.filters) {
        // New condition: if there are filters but no view, set to 'board'
        setView('board')
      }
    }
  }, [filters, id, pipelineViewStorageKey, opportunityId, view])

  const actionsById = useMemo(() => {
    const INCLUDE_RELATED = false
    const workingActionsById = {
      related: {},
      direct: {},
    }

    for (const action of pipelineActions) {
      if (!action || actionsHidden.includes(action.id)) {
        continue
      }
      if (INCLUDE_RELATED && action.opportunityIds?.length > 0) {
        for (const oppId of action.opportunityIds) {
          if (!workingActionsById.related[oppId]) {
            workingActionsById.related[oppId] = []
          }
          workingActionsById.related[oppId].push(action)
        }
      }
      const directOppId = action.opportunity?.id
      if (directOppId) {
        if (!workingActionsById.direct[directOppId]) {
          workingActionsById.direct[directOppId] = []
        }
        workingActionsById.direct[directOppId].push(action)
      }
    }

    return workingActionsById
  }, [pipelineActions, actionsHidden])

  const oppsById = useMemo(() => {
    const opps = {}
    for (const stage of pipelineData?.pipeline?.stages || []) {
      for (const opportunity of stage.opportunities || []) {
        const organization = opportunity.domain
          ? orgsByDomain[opportunity.domain]
          : null

        const currentStatus =
          opportunity.status?.length > 0
            ? opportunity.status.join(' ')
            : organization?.relationship?.oneSentenceSummary ||
              opportunity.primaryPerson?.relationship?.oneSentenceSummary ||
              '(Click for more details)'

        const opp = {
          id: opportunity.id,
          workspaceId: pipelineData.pipeline.workspaceId,
          pipelineId: pipelineData.pipeline.id,
          hasRevenue: pipelineData.pipeline.hasRevenue,
          type: opportunity.type,
          title: opportunity.title,
          ownerId: opportunity.ownerId,
          ownerEmail: opportunity.ownerEmail,
          expectedCloseDate: opportunity.expectedCloseDate,
          primaryPerson: opportunity.primaryPerson,
          status: opportunity.status,
          daysInStage: opportunity.daysInStage,
          currentStatus,
          expectedRevenue: opportunity.expectedRevenue,
          domain: opportunity.domain,
          organization, //opportunity.position,
          createdAt: opportunity.createdAt,
          updatedAt: opportunity.updatedAt,
          stageId: stage.id,
          stageTitle: stage.title,
          stagePosition: stage.position,
          stages: pipelineData.pipeline.stages,
          isSuggested: opportunity.isSuggested,
          stage: stage,
          notes: opportunity.notes,
          actions: [
            ...(actionsById.related?.[opportunity?.id] || []),
            ...(actionsById.direct?.[opportunity?.id] || []),
          ]?.filter(Boolean),
          roles: opportunity.roles,
        }
        opps[opportunity.id] = opp
      }
    }
    return opps
  }, [pipelineData?.pipeline, orgsByDomain, actionsById])

  const opportunities = useMemo(() => {
    return Object.values(oppsById)
  }, [oppsById])

  useEffect(() => {
    if (!title && !titleFocused && pipelineData?.pipeline?.title) {
      setTitle(pipelineData.pipeline.title)
    }
  }, [title, titleFocused, pipelineData?.pipeline?.title])

  useEffect(() => {
    if (
      pipelineData?.pipeline &&
      dayjs(pipelineData.pipeline.updatedAt).isAfter(updatedAt)
    ) {
      if (view === 'board') {
        logger.dev('Rendering board data')
        const boardData = {
          columns: pipelineData.pipeline?.stages
            ?.map((stage, index) => {
              return {
                id: stage.id,
                title: stage.title,
                type: stage.type,
                likelihoodToClose: stage.likelihoodToClose,
                position: index, //stage.position,
                cards:
                  [...(stage?.opportunities || [])].map(
                    (opportunity, index) => {
                      return {
                        ...oppsById[opportunity.id],
                        position: index,
                      }
                    }
                  ) || [],
              }
            })
            .sort((a, b) => a.position - b.position),
        }

        setBoard(boardData)
      }
    }
  }, [view, pipelineData?.pipeline, oppsById, updatedAt])

  const opportunityTypes = board?.columns
    .flatMap((column) => column?.cards?.map((opportunity) => opportunity.type))
    .filter((type, index, self) => self.indexOf(type) === index)

  useEffect(() => {
    if (pipelineData?.pipeline?.ownerEmails?.length <= 1) {
      setFilters((prev) => {
        const newFilters = { ...prev }

        if (newFilters.owner !== 'allOwners') {
          newFilters.owner = 'allOwners'
        }

        return newFilters
      })
    }
  }, [pipelineData?.pipeline?.ownerEmails?.length])

  return !requiresSetup ? (
    <Box
      key={`pipeline_${pipelineData?.pipeline?.id}`}
      sx={{ height: '100%', width: '100%', overflow: 'visible' }}
    >
      <Metadata
        title={title}
        description={`${title} Opportunities Pipeline | Day.ai`}
      />
      <Row
        sx={{
          justifyContent: 'space-between',
          p: 3,
          height: '80px',
        }}
      >
        <Row sx={{ width: '100%', alignItems: 'center' }}>
          <TextField
            variant="standard"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value)
            }}
            onFocus={() => setTitleFocused(true)}
            onBlur={() => setTitleFocused(false)}
            sx={{
              width: '100%',
              '& .MuiTypography-root, input': {
                fontWeight: 600,
                fontSize: '30px',
                letterSpacing: '-1.5px',
              },
            }}
            inputProps={{
              padding: '0px',
            }}
            InputProps={{
              disableUnderline: false,
              sx: {
                '&:before': {
                  borderBottom: '1px solid rgba(0, 0, 0, 0)',
                },
                '&:hover:before': {
                  borderBottom: '2px solid rgba(0, 0, 0, 0.42)',
                },
              },
              endAdornment: titleFocused ? (
                <RiPencilLine
                  style={{
                    width: '24px',
                    height: '24px',
                    marginRight: '8px',
                  }}
                />
              ) : (
                <></>
              ),
            }}
          />
        </Row>
        <Row
          className={'pipelineControls'}
          sx={{ justifyContent: 'right', mb: '4px' }}
        >
          <Tooltip
            title="Edit pipeline"
            placement="bottom"
            arrow={true}
          >
            <IconButton
              onClick={() => {
                setEditing('pipeline')
              }}
              sx={{ borderRadius: '3px', mr: 1 }}
            >
              <RiSettingsLine size={24} />
            </IconButton>
          </Tooltip>
          {actions}
          {/*<PipelineWarmthFilter
            onChange={handleWarmthChange}
            filters={filters}
          />*/}
          {false && view === 'table' && (
            <PipelineTimePeriodFilter
              onChange={handleTimePeriodChange}
              filters={filters}
            />
          )}
          <PipelineOppTypeFilter
            oppTypeList={opportunityTypes || []}
            onChange={handleChangeTypeFilter}
            filters={filters}
          />
          {pipelineData?.pipeline?.ownerEmails?.length > 1 && (
            <PipelineOwnerFilter
              ownerList={pipelineData?.pipeline?.ownerEmails || []}
              onChange={handleChangeOwnerFilter}
              filters={filters}
            />
          )}
          <ToggleButtonGroup
            value={view}
            exclusive={true}
            onChange={handleViewChange}
            aria-label="pipeline view type"
            sx={{
              height: '32px',
              '& .MuiToggleButton-root': {
                height: '32px',
                py: '4px',
                fontSize: '0.8rem',
                textTransform: 'none',
                width: {
                  xs: '36px',
                  sm: '36px',
                  md: '48px',
                  lg: '56px',
                  xl: '64px',
                },
                color: (theme) => theme.palette.text.secondary,

                '&.MuiToggleButtonGroup-middleButton, &.MuiToggleButtonGroup-lastButton':
                  {
                    borderLeft: '1px solid',
                    borderRight: '1px solid',
                    borderColor: (theme) => theme.palette.divider,
                  },
                '& .MuiSvgIcon-root': {
                  fontSize: '1.0rem',
                  mr: 1,
                },
                '&.Mui-selected': {
                  background: (theme) => theme.palette.action.selected,
                  color: (theme) => theme.palette.text.primary,
                  fontWeight: 600,
                  '&:hover': {
                    background: (theme) =>
                      darken(theme.palette.background.default, 0.1),
                  },

                  '& .MuiSvgIcon-root': {
                    color: (theme) => theme.palette.text.primary,
                  },
                },
              },
            }}
          >
            <Tooltip
              title="Board view"
              placement="bottom"
              arrow={true}
            >
              <ToggleButton
                value="board"
                aria-label="board view"
              >
                <RiKanbanView2 style={toggleIconStyle} />
              </ToggleButton>
            </Tooltip>

            <Tooltip
              title="View as table"
              placement="bottom"
              arrow={true}
            >
              <ToggleButton
                value="table"
                aria-label="table view"
              >
                <RiTableLine style={toggleIconStyle} />
              </ToggleButton>
            </Tooltip>
            <Tooltip
              title="View pending actions for pipeline"
              placement="bottom"
              arrow={true}
            >
              <ToggleButton
                value="actions"
                aria-label="actions view"
              >
                <RiFlashlightLine style={toggleIconStyle} />
              </ToggleButton>
            </Tooltip>

            {/*<Tooltip
                title="Activity feed"
                placement="bottom"
                arrow={true}
              >
                <ToggleButton
                  value="list"
                  aria-label="activity feed"
                >
                  <RiFileList2Line style={toggleIconStyle} />
                </ToggleButton>
            </Tooltip>
              <Tooltip
                title="Opportunity forecast"
                placement="bottom"
                arrow={true}
              >
                <ToggleButton
                  value="forecast"
                  aria-label="forecast view"
                >
                  <RiBarChartBoxLine style={toggleIconStyle} />
                </ToggleButton>
            </Tooltip>*/}
          </ToggleButtonGroup>
          <Button
            onClick={() => {
              setStageForCreate('auto')
            }}
            startIcon={<RiAddLine size={16} />}
            variant="contained"
            disableElevation={true}
            color="secondary"
            size="small"
            sx={{ ml: 1 }}
          >
            Opportunity
          </Button>
        </Row>
      </Row>
      <Box
        id="pipeline-container"
        sx={{ height: 'calc(100vh - 80px)' }}
      >
        {view === 'board' && board && (
          <PipelineBoard
            pipeline={pipelineData?.pipeline}
            boardData={board}
            actionsById={{}} // actionsById
            refetch={refetch}
            filters={filters}
            pipelineLoading={updatePipelineLoading}
            onUpdate={() => {
              setUpdatedAt(new Date())
            }}
            onEdit={(columnId) => {
              setEditing(columnId)
            }}
            onApproveGenericOpportunity={handleApproveGenericOpportunity}
            onDeclineGenericOpportunity={handleDeclineGenericOpportunity}
          />
        )}
        {view === 'table' && (
          <>
            <PipelineTable
              key={`pipelineTable_${pipelineData?.pipeline?.id}`}
              opportunities={opportunities}
              owners={pipelineData?.pipeline?.ownerCoreContacts || []}
              filters={filters}
              setTableFilters={handleTableFiltersChange}
              onUpdate={handleUpdateOpportunity}
              pipeline={pipelineData?.pipeline}
              refetch={refetch}
              onApproveGenericOpportunity={handleApproveGenericOpportunity}
              onDeclineGenericOpportunity={handleDeclineGenericOpportunity}
            />
          </>
        )}
        {view === 'actions' && (
          <PipelineActions
            actions={pipelineActions}
            opportunities={opportunities}
            pipeline={pipelineData?.pipeline}
            onUpdate={handleActionAction}
          />
        )}
        {view === 'list' && <PipelineList opportunities={opportunities} />}
        {view === 'forecast' && (
          <PipelineForecast
            pipeline={pipelineData?.pipeline}
            filters={filters}
          />
        )}
      </Box>
      {genericOpportunities?.length > 0 && (
        <Dialog
          open={true}
          onClose={() => {}}
          aria-labelledby="generic-opportunities-title"
          aria-describedby="generic-opportunities-description"
          maxWidth="md"
          fullWidth={true}
        >
          <DialogContent>
            <Box sx={{ p: 3, pt: 1 }}>
              <Row sx={{ justifyContent: 'space-between' }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '18px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  {`Auto-adding ${genericOpportunities?.length} opportunities to this pipeline`}
                </Typography>
                <Row>
                  <AvatarGroup max={6}>
                    {genericOpportunities.map((opportunity, index) => {
                      const avatar =
                        orgsByDomain?.[opportunity.domain]?.photos?.square ||
                        opportunity.primaryPerson?.avatar
                      if (!avatar) {
                        return null
                      }
                      return (
                        <Avatar
                          key={`${opportunity.id}_${index}`}
                          src={avatar}
                        />
                      )
                    })}
                  </AvatarGroup>
                </Row>
              </Row>
              <LinearProgress
                variant="indeterminate"
                sx={{ width: '100%', mt: 2, borderRadius: '4px' }}
                color="secondary"
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {stageForCreate && (
        <OpportunityCreateDialog
          pipelineId={pipelineData?.pipeline?.id}
          stageId={stageForCreate}
          refetch={refetch}
          onClose={() => {
            setStageForCreate(null)
          }}
        />
      )}
      <Dialog
        open={!!editing}
        onClose={() => {
          setEditing(null)
        }}
        aria-labelledby="edit-pipeline-title"
        aria-describedby="edit-pipeline-description"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogContent sx={{ height: 'calc(100vh - 64px)' }}>
          <PipelineEdit
            pipelineId={pipelineData?.pipeline?.id}
            workspaceId={workspaceIdToUse}
            refetch={refetch}
            stageId={editing}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={declineDialogOpen}
        onClose={handleCancelDecline}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Decline Opportunity</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Reason for declining"
            fullWidth
            multiline
            rows={3}
            value={declineReason}
            onChange={(e) => declineDialogHandlers.setReason(e.target.value)}
            placeholder="Please provide a reason for declining this opportunity..."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDecline}>Cancel</Button>
          <Button
            onClick={handleConfirmDecline}
            variant="contained"
            color="primary"
            disabled={!declineReason.trim()}
          >
            Confirm Decline
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  ) : pipelineData?.pipeline?.id ? (
    <PipelineCreateInteractive
      id={pipelineData.pipeline.id}
      workspaceId={pipelineData.pipeline.workspaceId}
      refetch={refetch}
    />
  ) : pipelineLoading ? (
    <Box sx={{ p: 3 }}>
      <CircularProgress />
    </Box>
  ) : null
}

export default Pipeline
