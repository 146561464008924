import { useCallback, useContext, useMemo, useState, useEffect } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {
  IconArrowMergeLeft,
  IconCheck,
  IconClock,
  IconDotsVertical,
  IconStar,
  IconStarFilled,
  IconThumbDown,
  IconTransfer,
  IconLabelFilled,
  IconLabel,
} from '@tabler/icons-react'
import type { Dayjs } from 'dayjs'
import toast from 'react-hot-toast'
import type { Action, WorkspaceMember } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'

import ContactTile from '../../ContactTile/ContactTile'
import Row from '../../Row/Row'
import WorkspaceMemberSelect from '../../WorkspaceMemberSelect/WorkspaceMemberSelect'

const DELETE_ACTION = gql`
  mutation DeleteAction($id: String!, $workspaceId: String!) {
    deleteAction(id: $id, workspaceId: $workspaceId)
  }
`

const COMPLETE_ACTION = gql`
  mutation CompleteAction($id: String!, $workspaceId: String!) {
    completeAction(id: $id, workspaceId: $workspaceId)
  }
`

const REASSIGN_ACTION = gql`
  mutation ReassignAction(
    $id: String!
    $workspaceId: String!
    $reassigneeEmail: String!
    $reassigneeId: String!
  ) {
    reassignAction(
      id: $id
      workspaceId: $workspaceId
      reassigneeEmail: $reassigneeEmail
      reassigneeId: $reassigneeId
    )
  }
`

const SNOOZE_ACTION = gql`
  mutation SnoozeAction(
    $id: String!
    $workspaceId: String!
    $snoozeUntil: DateTime!
  ) {
    snoozeAction(id: $id, workspaceId: $workspaceId, snoozeUntil: $snoozeUntil)
  }
`

const DEDUPLICATE_ACTION = gql`
  mutation DeduplicateAction($id: String!, $workspaceId: String!) {
    deduplicateActionAsync(workspaceId: $workspaceId, actionId: $id)
  }
`

const MARK_ACTION_UNREAD = gql`
  mutation MarkActionUnread($id: String!, $workspaceId: String!) {
    markActionUnread(id: $id, workspaceId: $workspaceId)
  }
`
const MARK_ACTION_READ_MUTATION = gql`
  mutation MarkActionReadMutation($input: UpdateActionInput!) {
    updateActionByUser(input: $input)
  }
`

const PIN_ACTION = gql`
  mutation PinAction($id: String!, $workspaceId: String!) {
    pinAction(id: $id, workspaceId: $workspaceId)
  }
`

const UNPIN_ACTION = gql`
  mutation UnpinAction($id: String!, $workspaceId: String!) {
    unpinAction(id: $id, workspaceId: $workspaceId)
  }
`

interface ActionActionsProps {
  action: Action
  onUpdate: (args?: {
    id?: string
    status?: string
    owner?: { id: string; email: string }
    pinnedFor?: string[]
  }) => void
  onHide?: (actionId: string) => void
  showButtons?: boolean
  size?: number
  buttons?: string[]
}

const ActionActions = ({
  action,
  onUpdate,
  onHide = () => {},
  showButtons = false,
  size = 18,
  buttons = [],
}: ActionActionsProps) => {
  const { selectedWorkspace: workspaceId } = useContext(DayContext)
  const { currentUser } = useAuth()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const [reassignOpen, setReassignOpen] = useState(false)
  const [snoozeOpen, setSnoozeOpen] = useState(false)
  const [snoozeValue, setSnoozeValue] = useState<Dayjs | null>(null)
  const [reassignee, setReassignee] = useState<WorkspaceMember | null>(null)
  const [localPinned, setLocalPinned] = useState(
    action.pinnedFor?.includes(currentUser?.id) ?? false
  )
  const [localUnread, setLocalUnread] = useState(
    action?.status?.id === 'UNREAD'
  )
  const [deleteAction] = useMutation(DELETE_ACTION)
  const [completeAction] = useMutation(COMPLETE_ACTION)
  const [reassignAction] = useMutation(REASSIGN_ACTION)
  const [snoozeAction] = useMutation(SNOOZE_ACTION)
  const [deduplicateAction] = useMutation(DEDUPLICATE_ACTION)
  const [pinAction] = useMutation(PIN_ACTION)
  const [unpinAction] = useMutation(UNPIN_ACTION)
  const [markUnread] = useMutation(MARK_ACTION_UNREAD)
  const [markRead] = useMutation(MARK_ACTION_READ_MUTATION)
  const buttonsToShow = useMemo(() => {
    if (buttons.length === 0)
      return ['PIN', 'REASSIGN', 'DELETE', 'COMPLETE', 'UNREAD']
    return buttons
  }, [buttons])

  useEffect(() => {
    setLocalPinned(action.pinnedFor?.includes(currentUser?.id) ?? false)
  }, [action.pinnedFor, currentUser?.id])

  useEffect(() => {
    setLocalUnread(action?.status?.id === 'UNREAD')
  }, [action?.status?.id])

  const handleTogglePin = useCallback(async () => {
    try {
      setLocalPinned(!localPinned)

      onUpdate({
        id: action.id,
        pinnedFor: localPinned ? [currentUser?.id] : [],
      })

      if (localPinned) {
        await unpinAction({
          variables: {
            id: action.id,
            workspaceId: action.workspaceId,
          },
        })
      } else {
        await pinAction({
          variables: {
            id: action.id,
            workspaceId: action.workspaceId,
          },
        })
      }
    } catch (error) {
      setLocalPinned(!localPinned)
      logger.error('Failed to toggle pin status:', error)
    }
  }, [
    action.id,
    action.workspaceId,
    localPinned,
    pinAction,
    unpinAction,
    onUpdate,
    currentUser?.id,
  ])

  const handleComplete = useCallback(async () => {
    try {
      onHide(action.id)
      await completeAction({
        variables: { id: action.id, workspaceId },
      })
      onUpdate({ id: action.id, status: 'COMPLETED' })
    } catch (error) {
      toast.error('Error completing action')
      logger.error('Error completing action:', error)
    }
  }, [action, workspaceId, completeAction, onHide, onUpdate])

  const handleDelete = useCallback(async () => {
    try {
      onHide(action.id)
      onUpdate({ id: action.id, status: 'DISMISSED' })
      await deleteAction({
        variables: { id: action.id, workspaceId },
      })
    } catch (error) {
      toast.error('Error dismissing action')
      logger.error('Error deleting action:', error)
    }
  }, [action, workspaceId, deleteAction, onHide, onUpdate])

  const handleReassign = useCallback(async () => {
    try {
      onUpdate({
        id: action.id,
        owner: { id: reassignee.id, email: reassignee.email },
      })
      onHide(action.id)
      setReassignOpen(false)
      setReassignee(null)
      await reassignAction({
        variables: {
          id: action.id,
          workspaceId,
          reassigneeEmail: reassignee.email,
          reassigneeId: reassignee.id,
        },
      })
      toast.success('Action reassigned')
    } catch (error) {
      toast.error('Error reassigning action')
      logger.error('Error reassigning action:', error)
    }
  }, [action, workspaceId, reassignAction, onHide, onUpdate, reassignee])

  const handleSnooze = useCallback(async () => {
    try {
      onUpdate({ id: action.id, status: 'SNOOZED' })
      await snoozeAction({
        variables: {
          id: action.id,
          workspaceId,
          snoozeUntil: snoozeValue?.toDate(),
        },
      })
      setSnoozeOpen(false)
      setSnoozeValue(null)
    } catch (error) {
      toast.error('Error snoozing action')
      logger.error('Error snoozing action:', error)
    }
  }, [action, workspaceId, snoozeAction, onUpdate, snoozeValue])

  const handleDeduplicate = useCallback(async () => {
    try {
      await deduplicateAction({
        variables: { id: action.id, workspaceId },
      })
    } catch (error) {
      toast.error('Error deduplicating action')
      logger.error('Error deduplicating action:', error)
    }
  }, [action, workspaceId, deduplicateAction])

  const handleMarkRead = useCallback(async () => {
    try {
      onUpdate({ id: action.id, status: 'READ' })
      onHide(action.id)
      await markRead({
        variables: { id: action.id, workspaceId },
      })
    } catch (error) {
      toast.error('Error marking action as read')
      logger.error('Error marking action as read:', error)
    }
  }, [action, workspaceId, markRead, onHide, onUpdate])

  const handleMarkUnread = useCallback(async () => {
    try {
      onUpdate({ id: action.id, status: 'UNREAD' })
      onHide(action.id)
      await markUnread({
        variables: { id: action.id, workspaceId },
      })
    } catch (error) {
      toast.error('Error marking action as unread')
      logger.error('Error marking action as unread:', error)
    }
  }, [action, workspaceId, markUnread, onHide, onUpdate])

  const handleToggleRead = useCallback(async () => {
    setLocalUnread((unread) => {
      if (unread) {
        handleMarkRead()
      } else {
        handleMarkUnread()
      }
      return !unread
    })
  }, [handleMarkRead, handleMarkUnread])

  const menuItems = useMemo(
    () => [
      {
        key: 'COMPLETE',
        label: (
          <Tooltip
            title="Complete action"
            arrow={true}
          >
            <IconButton>
              <IconCheck size={size} />
            </IconButton>
          </Tooltip>
        ),
      },
      {
        key: 'REASSIGN',
        label: (
          <Tooltip
            title="Reassign action"
            arrow={true}
          >
            <IconButton>
              <IconTransfer size={size} />
            </IconButton>
          </Tooltip>
        ),
      },
      {
        key: 'SNOOZE',
        label: (
          <Tooltip
            title="Snooze action"
            arrow={true}
          >
            <IconButton>
              <IconClock size={size} />
            </IconButton>
          </Tooltip>
        ),
      },
      {
        key: 'DELETE',
        label: (
          <Tooltip
            title="Dismiss action"
            arrow={true}
          >
            <IconButton>
              <IconThumbDown size={size} />
            </IconButton>
          </Tooltip>
        ),
      },
      {
        key: 'DEDUPLICATE',
        label: (
          <Tooltip
            title="Ask AI to deduplicate"
            arrow={true}
          >
            <IconButton>
              <IconArrowMergeLeft size={size} />
            </IconButton>
          </Tooltip>
        ),
      },
      {
        key: 'UNREAD',
        label: (
          <Tooltip
            title={localUnread ? 'Mark as read' : 'Mark as unread'}
            arrow={true}
          >
            <IconButton>
              {localUnread ? (
                <IconLabelFilled size={size} />
              ) : (
                <IconLabel size={size} />
              )}
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [size, localUnread]
  )

  const menuItemsToShow = useMemo(() => {
    if (buttonsToShow.length === 0) return menuItems
    return menuItems.filter((item) => buttonsToShow.includes(item.key))
  }, [buttonsToShow, menuItems])

  if (!action?.id || !currentUser?.id) {
    return null
  }

  const ungatedForPins = process.env.HOST.includes('localhost')

  return (
    <Box>
      {showButtons ? (
        <Row>
          {buttonsToShow.includes('PIN') && ungatedForPins && (
            <Tooltip
              title={localPinned ? 'Remove star' : 'Star'}
              arrow={true}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  handleTogglePin()
                }}
              >
                {localPinned ? (
                  <IconStarFilled
                    size={size}
                    style={{ color: '#efbf04' }}
                  />
                ) : (
                  <IconStar size={size} />
                )}
              </IconButton>
            </Tooltip>
          )}
          {menuItemsToShow.map((item) => (
            <span key={item.key}>
              {React.cloneElement(item.label, {
                onClick: (e) => {
                  e.stopPropagation()
                  if (item.key === 'COMPLETE') {
                    handleComplete()
                  } else if (item.key === 'REASSIGN') {
                    setReassignOpen(true)
                  } else if (item.key === 'SNOOZE') {
                    setSnoozeOpen(true)
                  } else if (item.key === 'DELETE') {
                    handleDelete()
                  } else if (item.key === 'DEDUPLICATE') {
                    handleDeduplicate()
                  } else if (item.key === 'UNREAD') {
                    handleMarkUnread()
                  }
                },
              })}
            </span>
          ))}
        </Row>
      ) : (
        <Row sx={{ height: `${size}px` }}>
          <Tooltip title="More actions">
            <IconButton
              onClick={(e) => {
                e.stopPropagation()
                setAnchorEl(e.currentTarget)
              }}
            >
              <IconDotsVertical size={size} />
            </IconButton>
          </Tooltip>
          <Menu
            open={menuOpen}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            sx={{
              '& .MuiMenuItem-root': {
                p: 0.5,
                minWidth: 'auto',
                borderRadius: '4px',
              },
            }}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation()
                handleTogglePin()
                setAnchorEl(null)
              }}
            >
              <Tooltip title={localPinned ? 'Remove star' : 'Star'}>
                <IconButton>
                  {localPinned ? (
                    <IconStarFilled
                      size={size}
                      className="text-blue-500"
                    />
                  ) : (
                    <IconStar size={size} />
                  )}
                </IconButton>
              </Tooltip>
            </MenuItem>
            {menuItemsToShow.map((item) => (
              <MenuItem
                key={item.key}
                onClick={(e) => {
                  e.stopPropagation()
                  if (item.key === 'COMPLETE') {
                    handleComplete()
                  } else if (item.key === 'REASSIGN') {
                    setReassignOpen(true)
                  } else if (item.key === 'SNOOZE') {
                    setSnoozeOpen(true)
                  } else if (item.key === 'DELETE') {
                    handleDelete()
                  } else if (item.key === 'DEDUPLICATE') {
                    handleDeduplicate()
                  } else if (item.key === 'UNREAD') {
                    handleToggleRead()
                  }
                  setAnchorEl(null)
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Row>
      )}
      <Dialog
        open={reassignOpen}
        onClose={() => {
          setReassignee(null)
          setReassignOpen(false)
        }}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>Reassign Action</DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              p: 2,
              border: (theme) => `1px solid ${theme.palette.divider}`,
              borderRadius: 10,
              fontWeight: 500,
              letterSpacing: '-0.3px',
              mb: 3,
            }}
          >
            {`"${action.title}"`}
          </Typography>
          <Box sx={{ pt: 1 }}>
            {reassignee ? (
              <ContactTile
                email={reassignee.email}
                showSidebar={false}
              />
            ) : (
              <WorkspaceMemberSelect
                onSelect={(members) => {
                  setReassignee(members?.[0])
                }}
                value={[]}
                exclude={[action.owner?.id, action.owner?.email]}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!reassignee || reassignee?.id === action.owner?.id}
            onClick={handleReassign}
            size="large"
            variant={reassignee ? 'contained' : 'outlined'}
            disableElevation={true}
            fullWidth={true}
          >
            {reassignee?.id === action.owner?.id
              ? 'Already assigned'
              : 'Reassign'}
          </Button>
        </DialogActions>
      </Dialog>
      {snoozeOpen && (
        <Dialog
          open={snoozeOpen}
          onClose={() => setSnoozeOpen(false)}
        >
          <DialogTitle>Snooze until ...</DialogTitle>
          <DialogContent sx={{ height: '324px', overflow: 'hidden' }}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              dateLibInstance={dayjs}
            >
              <DateCalendar
                value={snoozeValue}
                onChange={(newValue) => setSnoozeValue(newValue)}
                timezone="system"
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions sx={{ pt: 0 }}>
            <Button
              variant="outlined"
              fullWidth={true}
              onClick={handleSnooze}
            >
              Snooze
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  )
}

export default ActionActions
