import { useContext, useEffect, useState } from 'react'

import { Box, lighten, Switch, Tooltip } from '@mui/material'
import {
  IconCircleFilled,
  IconInfoCircle,
  IconLogout,
  IconMicrophoneFilled,
  IconPlayerPlay,
} from '@tabler/icons-react'

import { navigate } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import MetadataChip from 'src/components/Chips/MetadataChip/MetadataChip'
import Row from 'src/components/Row/Row'
import SidebarButton from 'src/components/Sidebar/SidebarButton/SidebarButton'
import { DayContext } from 'src/lib/dayContext'
import {
  computeFinalMetaState,
  recordingLink,
  statusCategories,
  statusMetadata,
} from 'src/lib/meetingRecordingFormatting'

import {
  canRecordEvent,
  isPastEndTime,
  isStartingOrStarted,
} from '../eventRecordingStates'

const UPDATE_CALENDAR_EVENT_RECORDING_SETTINGS = gql`
  mutation UpdateCalendarEventRecordingSettings(
    $id: String!
    $enabled: Boolean!
  ) {
    updateCalendarEventRecordingSettings(
      input: { id: $id, enabled: $enabled }
    ) {
      enabled
    }
  }
`

const RECORD_EVENT_IMMEDIATELY = gql`
  mutation RecordEventImmediately($id: String!) {
    recordCalendarEventImmediately(id: $id)
  }
`

const REMOVE_BOT_FROM_EVENT = gql`
  mutation RemoveBotFromEvent($id: String!) {
    removeBotFromCalendarEventRecording(id: $id)
  }
`

const DaySwitch = ({
  checked,
  onChange,
  disabled,
}: {
  checked: boolean
  onChange: (checked: boolean) => void
  disabled: boolean
}) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    checked={checked}
    onChange={(event) => onChange(event.target.checked)}
    disabled={disabled}
    sx={(theme) => ({
      width: 37,
      height: 24,
      padding: 0,
      '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: '2px',
        transitionDuration: '200ms',
        '&.Mui-checked': {
          transform: 'translateX(13px)',
          color: '#fff',
          '& + .MuiSwitch-track': {
            backgroundColor: lighten(theme.palette.secondary.main, 0.4),
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.2,
            backgroundColor: theme.palette.text.disabled,
            border: 'none',
          },
          '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.background.paper,
            border: 'none',
          },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
          color: '#fff',
          border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: 'transparent',
          boxShadow: 'none',
          border: `1px solid ${theme.palette.divider}`,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: 'transparent',
          border: `1px solid ${theme.palette.divider}`,
        },
      },
      '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 20,
        height: 20,
      },
      '& .MuiSwitch-track': {
        borderRadius: 24 / 2,
        backgroundColor: theme.palette.divider,
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
          duration: 250,
        }),
      },
    })}
  />
)

const TodayMeetingRecordingAction = ({
  event,
  refetch,
  workspacesWithOtherMembers,
}: {
  event: any
  refetch: () => Promise<any>
  workspacesWithOtherMembers: any[]
  sx?: any
}) => {
  const { selectedWorkspace, workAccountWorkspaceConnections } =
    useContext(DayContext)
  const serverEnabledState =
    event.CalendarEvent[0]?.recordingSettings?.enabled || false

  const [checked, setChecked] = useState(serverEnabledState)
  const [saving, setSaving] = useState(false)
  const [eventIsStartingOrStarted, setEventIsStartingOrStarted] = useState(
    isStartingOrStarted(event)
  )

  useEffect(() => {
    if (serverEnabledState !== checked) {
      setChecked(serverEnabledState)
    }
  }, [serverEnabledState, checked])

  useEffect(() => {
    if (eventIsStartingOrStarted !== isStartingOrStarted(event)) {
      setEventIsStartingOrStarted(isStartingOrStarted(event))
    }

    const interval = setInterval(() => {
      if (eventIsStartingOrStarted !== isStartingOrStarted(event)) {
        setEventIsStartingOrStarted(isStartingOrStarted(event))
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [event, eventIsStartingOrStarted])

  const [toggleEventRecording] = useMutation(
    UPDATE_CALENDAR_EVENT_RECORDING_SETTINGS
  )

  const [recordEventImmediately] = useMutation(RECORD_EVENT_IMMEDIATELY)
  const [removeBotFromEvent] = useMutation(REMOVE_BOT_FROM_EVENT)

  const immediateRecordingStatus =
    event?.CalendarEvent[0]?.recordingSettings?.immediateRecordingStatus
  const disabled = canRecordEvent(event) === false || saving

  const handleToggle = async (newValue) => {
    setChecked(newValue)
    await toggleEventRecording({
      variables: { id: event.CalendarEvent[0].id, enabled: newValue },
    })
    refetch()
  }

  const handleRecordImmediately = async () => {
    setSaving(true)
    await recordEventImmediately({
      variables: { id: event.CalendarEvent[0].id },
    })
    await refetch()
    setSaving(false)
  }

  const handleRemoveBotFromEvent = async () => {
    setSaving(true)
    await removeBotFromEvent({
      variables: { id: event.CalendarEvent[0].id },
    })
    await refetch()
    setSaving(false)
  }

  const meetingRecording = event?.CalendarEvent[0]?.meetingRecordings?.[0]

  const startingOrStartedMessage = eventIsStartingOrStarted
    ? `This meeting ${
        isStartingOrStarted(event) === 'STARTED'
          ? 'has already started'
          : 'is about to start'
      }`
    : null

  const currentStatus =
    meetingRecording?.statusHistory?.length > 0
      ? computeFinalMetaState({ meetingRecording })
      : null

  const meetingState = currentStatus ? statusMetadata[currentStatus] : null

  const isRecordingInProgress =
    meetingRecording &&
    !meetingRecording.statusHistory?.some((status) =>
      [
        'CALL_ENDED_WITHOUT_RECORDING',
        'PREPARING_RECORDING',
        'RECORDING_ERRORED',
        'READY',
      ].includes(status.status)
    )

  const containerSx = {
    display: 'flex',
    flexDirection: 'column',
  }

  return (
    <Box sx={containerSx}>
      {meetingRecording && (
        <>
          {meetingState.category === statusCategories.READY ? (
            <SidebarButton
              icon={<IconPlayerPlay size={16} />}
              onClick={(e) => {
                e.stopPropagation()
                navigate(
                  recordingLink({
                    workspaceId: workAccountWorkspaceConnections?.some(
                      ({ connectedWorkspaces }) =>
                        connectedWorkspaces.length > 0
                    )
                      ? selectedWorkspace
                      : null,
                    meetingRecording:
                      event?.CalendarEvent[0]?.meetingRecordings?.[0],
                  })
                )
              }}
              label="Watch"
            />
          ) : (
            <Tooltip
              title={meetingState.explanation}
              arrow={true}
              placement="top"
            >
              <SidebarButton
                icon={<IconInfoCircle size={16} />}
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(
                    recordingLink({
                      meetingRecording: meetingRecording,
                      workspaceId: selectedWorkspace,
                    })
                  )
                }}
                label={meetingState.shortLabel || meetingState.label}
              />
            </Tooltip>
          )}
        </>
      )}
      {isRecordingInProgress ? (
        <MetadataChip
          disabled={disabled}
          onClick={handleRemoveBotFromEvent}
          icon={<IconLogout />}
          state={{
            label: 'Remove',
            value: 'REMOVE',
            color: 'warning',
          }}
        />
      ) : immediateRecordingStatus === 'NONE' ? (
        <Row>
          {!eventIsStartingOrStarted ? (
            <Tooltip
              arrow={true}
              title={
                (startingOrStartedMessage ||
                  event?.CalendarEvent[0]?.recordingSettings?.reason ||
                  (disabled
                    ? 'Unable to change recording setting for this meeting'
                    : checked
                      ? `Don't record this meeting`
                      : 'Record this meeting')) +
                `. ${
                  event?.CalendarEvent[0]?.recordingSettings?.enabled &&
                  workspacesWithOtherMembers?.length > 0
                    ? `This meeting will be available in the ${workspacesWithOtherMembers?.map(
                        ({ name }) => name
                      )} shared workspace${
                        workspacesWithOtherMembers?.length > 1 ? 's' : ''
                      }.`
                    : ''
                }`
              }
            >
              <div>
                <DaySwitch
                  checked={checked}
                  onChange={handleToggle}
                  disabled={disabled}
                />
              </div>
            </Tooltip>
          ) : null}
          {!isPastEndTime(event) && (
            <Box sx={{ ml: eventIsStartingOrStarted ? 0 : 1, mr: 'auto' }}>
              <Tooltip
                arrow={true}
                title={'Start recording now'}
              >
                <div>
                  <SidebarButton
                    label=""
                    disabled={disabled}
                    onClick={handleRecordImmediately}
                    icon={
                      <IconMicrophoneFilled
                        size={16}
                        style={{ marginLeft: '10px' }}
                      />
                    }
                  />
                </div>
              </Tooltip>
            </Box>
          )}
        </Row>
      ) : (
        <MetadataChip
          state={{
            label: 'Starting ...',
            value: 'STARTING',
            color: 'success',
          }}
          icon={<IconCircleFilled />}
        />
      )}
    </Box>
  )
}

export default TodayMeetingRecordingAction
