import ContextDebug from 'src/components/ContextDebug/ContextDebug'

import { useThreads } from '../ThreadContext/ThreadContext'

const ThreadContextDebug = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const {
    contextString,
    tokenCount,
    tokenLimit,
    handleGetThreadContext,
    thread,
  } = useThreads()

  return (
    <ContextDebug
      label="Thread Context"
      contextString={contextString}
      tokenCount={tokenCount}
      onRefetch={() => handleGetThreadContext(thread.id)}
      tokenLimit={tokenLimit}
      open={open}
      setOpen={setOpen}
    />
  )
}

export default ThreadContextDebug
