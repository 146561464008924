import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import type { Page, Thread, ThreadMessage } from 'types/graphql'

import { navigate, routes, useLocation, useParams } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import type { contextStatusMetadata } from 'src/components/Threads/threads'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

const GET_THREADS_QUERY = gql`
  query GetThreads($workspaceId: String!) {
    threadsForUser(workspaceId: $workspaceId) {
      id
      workspaceId
      title
      avatarId
      avatarType
    }
  }
`

const GET_THREAD_QUERY = gql`
  query GetThread($id: String!, $workspaceId: String!) {
    thread(id: $id, workspaceId: $workspaceId) {
      id
      workspaceId
      title
      avatarId
      avatarType
      messages {
        id
        content
        type
        userId
        createdAt
        pages {
          id
          title
        }
        links {
          href
          text
        }
      }
      contextObjects {
        objectId
        objectType
      }
    }
  }
`

const GET_CONTEXT_FOR_THREAD = gql`
  query ContextForThread($threadId: String!, $workspaceId: String!) {
    contextForThread(threadId: $threadId, workspaceId: $workspaceId) {
      contextString
      tokenCount
    }
  }
`

const CREATE_THREAD = gql`
  mutation CreateThread(
    $workspaceId: String!
    $contextObjects: [CRMObjectInput!]!
    $title: String
  ) {
    createThread(
      workspaceId: $workspaceId
      contextObjects: $contextObjects
      title: $title
    ) {
      id
      workspaceId
      title
      avatarId
      avatarType
      contextObjects {
        objectId
        objectType
      }
      messages {
        id
        content
        type
        userId
        createdAt
        pages {
          id
          title
        }
        links {
          href
          text
        }
      }
    }
  }
`

const ADD_MESSAGE_TO_THREAD = gql`
  mutation AddMessageToThread(
    $threadId: String!
    $workspaceId: String!
    $content: JSON!
    $userId: String
    $type: String!
  ) {
    addMessageToThread(
      threadId: $threadId
      workspaceId: $workspaceId
      content: $content
      userId: $userId
      type: $type
    ) {
      id
    }
  }
`

const UPDATE_CONTEXT_OBJECTS = gql`
  mutation UpdateContextObjects(
    $threadId: String!
    $workspaceId: String!
    $contextObjects: [CRMObjectInput!]!
  ) {
    updateContextObjects(
      threadId: $threadId
      workspaceId: $workspaceId
      contextObjects: $contextObjects
    ) {
      id
    }
  }
`

const GET_TIPTAP_JWT = gql`
  query GetTiptapJwt($timestamp: Int!) {
    getTiptapJwt(timestamp: $timestamp)
  }
`

const GET_ORGANIZATION_COLOR = gql`
  query GetOrganizationColor($domain: String!, $workspaceId: String!) {
    workspaceOrganization(domain: $domain, workspaceId: $workspaceId) {
      colors {
        colorVibrant
      }
    }
  }
`

interface ThreadContextType {
  workspaceId: string
  threadId: string | null
  thread: Thread | null
  threads: Thread[] | null
  threadsNavOpen: boolean
  contextStatus: keyof typeof contextStatusMetadata | null
  contextString: string | null
  tokenCount: number | null
  tokenLimit: number | null
  pages: Partial<Page>[]
  currentQuery: string | null
  token: string | null
  showPages: boolean
  color: string | null
  setShowPages: (showPages: boolean) => void
  setCurrentQuery: (query: string) => void
  setThreadsNavOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleCreateThread: (contextObjects?: any[], title?: string) => Promise<void>
  handleSelectThread: (threadId: string) => Promise<void>
  handleAddMessageToThread: (message: Partial<ThreadMessage>) => Promise<void>
  handleOpenPage: (params: { pageId: string; title: string }) => void
  handleSetContextObjects: (contextObjects: any[]) => void
  handleGetThreadContext: (threadId: string) => Promise<void>
  handleAiResponse: (message: Partial<ThreadMessage>) => Promise<void>
}

const ThreadContext = createContext<ThreadContextType | undefined>(undefined)

export const useThreads = () => {
  const context = useContext(ThreadContext)
  if (!context) {
    throw new Error('useThreads must be used within a ThreadsProvider')
  }
  return context
}

const defaultTokenLimit = 100000

export const ThreadsProvider = ({
  children,
  workspaceId,
  threadId = null,
}: {
  children: React.ReactNode
  workspaceId: string
  threadId?: string | null
}) => {
  const { q } = useParams()
  const { setSidebarObject } = useContext(DayContext)
  const { currentUser } = useAuth()
  const pathname = useLocation().pathname
  const [threadsNavOpen, setThreadsNavOpen] = useState<boolean>(false)
  const [thread, setThread] = useState<Thread | null>(null)
  const [contextStatus, setContextStatus] = useState<
    keyof typeof contextStatusMetadata | null
  >(null)
  const [contextString, setContextString] = useState<string | null>(null)
  const [tokenCount, setTokenCount] = useState<number | null>(0)
  const [currentQuery, setCurrentQuery] = useState<string | null>(null)
  const [showPages, setShowPages] = useState<boolean>(false)

  const autoOpenedPages = useRef<string[]>([])

  const { data: threadsData, refetch: refetchThreads } = useQuery(
    GET_THREADS_QUERY,
    {
      variables: {
        workspaceId,
      },
      skip: !workspaceId,
    }
  )

  const threads = threadsData?.threadsForUser

  const { data: tokenData } = useQuery(GET_TIPTAP_JWT, {
    variables: {
      timestamp: Math.floor(Date.now() / (3600 * 1000)),
    },
    fetchPolicy: 'cache-first',
  })

  const token = useMemo(
    () => tokenData?.getTiptapJwt,
    [tokenData?.getTiptapJwt]
  )

  const resetThread = useCallback(() => {
    setThread(null)
    setContextStatus(null)
    setContextString(null)
    setShowPages(false)
    autoOpenedPages.current = []
  }, [setThread, setContextStatus, setContextString])

  useEffect(() => {
    if (thread && pathname === routes.threads()) {
      //resetThread()
    }
  }, [pathname, thread, resetThread])

  const [getThread] = useLazyQuery(GET_THREAD_QUERY, {
    variables: {
      id: thread?.id,
      workspaceId,
    },
    onCompleted: ({ thread }) => {
      setThread(thread)
    },
  })

  const orgId = useMemo(() => {
    return thread?.contextObjects?.find(
      (object) => object.objectType === NativeObjectTypes.Organization
    )?.objectId
  }, [thread?.contextObjects])

  const { data: orgColorData } = useQuery(GET_ORGANIZATION_COLOR, {
    variables: {
      domain: orgId,
      workspaceId,
    },
    skip: !orgId || !workspaceId,
  })
  const color = orgColorData?.workspaceOrganization?.colors?.colorVibrant

  const [getThreadContext] = useLazyQuery(GET_CONTEXT_FOR_THREAD)

  const [createThread] = useMutation(CREATE_THREAD, {
    refetchQueries: [
      {
        query: GET_THREADS_QUERY,
        variables: {
          workspaceId,
        },
      },
    ],
  })

  const [addMessageToThread] = useMutation(ADD_MESSAGE_TO_THREAD)

  const [updateContextObjects] = useMutation(UPDATE_CONTEXT_OBJECTS)

  const handleAddMessageToThread = useCallback(
    async (message: Partial<ThreadMessage>) => {
      const id = threadId
      if (!id) {
        logger.warn('Thread ID is required to add a message')
        return
      }
      const newMessage = {
        workspaceId,
        threadId: id,
        content: message.content,
        userId: message.userId,
        type: message.type,
        pages: message.pages || [],
        links: message.links || [],
      }

      const isUserMessage = newMessage.userId

      if (isUserMessage) {
        logger.dev('Setting current query', {
          content: newMessage.content,
          userId: newMessage.userId,
          threadId: id,
        })
        // Only set current query if it's a user message
        setCurrentQuery((newMessage.content as any).text)
      }

      try {
        await addMessageToThread({
          variables: newMessage,
        })
        await getThread({
          variables: { id, workspaceId },
        })
        if (!isUserMessage) {
          setCurrentQuery('')
        }
      } catch (error) {
        logger.warn('Failed to add message to thread', { error, newMessage })
      }
    },
    [addMessageToThread, workspaceId, threadId, getThread, setCurrentQuery]
  )

  const messageFromParamAdded = useRef(false)
  useEffect(() => {
    const addInitialMessage = async () => {
      await handleAddMessageToThread({
        content: { text: q },
        type: 'text',
        userId: currentUser?.id,
      })
    }

    if (
      thread &&
      q &&
      !messageFromParamAdded.current &&
      contextStatus === 'loaded'
    ) {
      logger.dev('ADDING INITIAL MESSAGE', {
        q,
        thread,
        contextStatus,
        messageFromParamAdded: messageFromParamAdded.current,
      })
      messageFromParamAdded.current = true
      addInitialMessage()
    }
  }, [handleAddMessageToThread, currentUser?.id, q, thread, contextStatus])

  const handleCreateThread = useCallback(
    async (contextObjects = [], title = null) => {
      setContextStatus('loading')
      const response = await createThread({
        variables: {
          workspaceId,
          contextObjects,
          title,
        },
      })
      navigate(routes.thread({ id: response.data.createThread.id, q: title }))
    },
    [createThread, workspaceId]
  )

  const handleAiResponse = useCallback(
    async (result) => {
      logger.dev('Processing AI response', { result })
      const responseEditor = result.editor
      responseEditor.commands.selectAll()
      responseEditor.commands.unsetMark('aiMark')
      const html = responseEditor.getHTML()
      const json = responseEditor.getJSON()

      await handleAddMessageToThread({
        content: { html, json },
        type: 'html',
        userId: null,
      })
    },
    [handleAddMessageToThread]
  )

  const handleGetThreadContext = useCallback(
    async (threadId: string) => {
      if (!threadId || !workspaceId) return
      setContextStatus('loading')
      try {
        const fetchedContextString = await getThreadContext({
          variables: { threadId, workspaceId },
        })
        if (
          fetchedContextString?.data?.contextForThread?.contextString ===
          'Context too large'
        ) {
          setContextStatus('error_capacity')
        } else {
          setContextString(
            fetchedContextString?.data?.contextForThread?.contextString
          )
          setTokenCount(
            fetchedContextString?.data?.contextForThread?.tokenCount
          )
          setContextStatus('loaded')
        }
      } catch (e) {
        setContextStatus('error_unknown')
      }
    },
    [getThreadContext, workspaceId]
  )

  const handleSelectThread = useCallback(
    async (threadId: string) => {
      if (workspaceId && threadId) {
        resetThread()
        const fetchedThread = await getThread({
          variables: { id: threadId, workspaceId },
        })
        setThreadsNavOpen(false)
        setThread(fetchedThread.data.thread)
        refetchThreads()
      } else {
        logger.warn('Workspace ID is required to select a thread')
      }
    },
    [getThread, workspaceId, resetThread, refetchThreads]
  )

  const handleOpenPage = useCallback(
    ({ pageId, title }: { pageId: string; title: string }) => {
      setSidebarObject({
        objectId: pageId,
        objectType: NativeObjectTypes.Page,
        properties: { title },
      })
      setShowPages(true)
    },
    [setSidebarObject, setShowPages]
  )

  const handleSetContextObjects = useCallback(
    (contextObjects: any[]) => {
      if (Array.isArray(contextObjects) && thread?.id) {
        setThread((prev) => {
          const updatedThreadState = {
            ...prev,
            contextObjects,
          }
          updateContextObjects({
            variables: {
              threadId: thread?.id,
              contextObjects: contextObjects.map((object) => ({
                objectId: object.objectId,
                objectType: object.objectType,
              })),
              workspaceId,
            },
          })
          // TODO: refetch thread << what we have now may be too optimistic
          return updatedThreadState
        })
      }
    },
    [updateContextObjects, thread?.id, workspaceId]
  )

  useEffect(() => {
    if (thread?.id && workspaceId && thread.contextObjects?.length > 0) {
      handleGetThreadContext(thread?.id)
    }
  }, [handleGetThreadContext, thread?.id, workspaceId, thread?.contextObjects])

  useEffect(() => {
    if (threadId && workspaceId && threadId !== thread?.id) {
      handleSelectThread(threadId)
    }
  }, [threadId, handleSelectThread, thread?.id, workspaceId])

  const pages = useMemo(() => {
    return thread?.messages.flatMap((message) => message.pages)
  }, [thread?.messages])

  useEffect(() => {
    const mostRecentPage = pages?.[pages.length - 1]
    if (
      mostRecentPage &&
      !autoOpenedPages.current.includes(mostRecentPage.id)
    ) {
      autoOpenedPages.current.push(mostRecentPage.id)
      setShowPages(true)
    }
  }, [pages])

  const contextValue = useMemo(
    () => ({
      workspaceId,
      threadId,
      tokenCount,
      tokenLimit: defaultTokenLimit,
      color,
      showPages,
      setShowPages,
      thread,
      threads,
      threadsNavOpen,
      contextStatus,
      contextString,
      pages,
      currentQuery,
      setCurrentQuery,
      setThreadsNavOpen,
      handleCreateThread,
      handleSelectThread,
      handleAddMessageToThread,
      handleOpenPage,
      handleSetContextObjects,
      handleAiResponse,
      handleGetThreadContext,
      token,
    }),
    [
      workspaceId,
      threadId,
      color,
      showPages,
      setShowPages,
      thread,
      threads,
      threadsNavOpen,
      contextStatus,
      contextString,
      pages,
      currentQuery,
      setCurrentQuery,
      handleCreateThread,
      handleSelectThread,
      handleAddMessageToThread,
      handleOpenPage,
      handleSetContextObjects,
      handleAiResponse,
      handleGetThreadContext,
      token,
      tokenCount,
    ]
  )

  return (
    <ThreadContext.Provider value={contextValue}>
      {children}
    </ThreadContext.Provider>
  )
}

export default ThreadsProvider
